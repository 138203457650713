var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "container-fluid paper-footer-wrapper" }, [
      _c(
        "a",
        {
          staticStyle: { color: "#fff !important" },
          attrs: { href: "https://kp4cd.org/" },
        },
        [
          _vm._v(" Part of "),
          _c("span", [
            _c("img", {
              staticStyle: {
                width: "50px",
                "margin-left": "3px",
                "margin-right": "5px",
              },
              attrs: {
                src: "https://kp4cd.org/sites/default/files/kpn_small.svg",
              },
            }),
          ]),
          _c(
            "span",
            {
              staticStyle: {
                "font-weight": "500",
                "font-size": "1.2em",
                "vertical-align": "-0.1em",
              },
            },
            [_vm._v("the Knowledge Portal Network")]
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }