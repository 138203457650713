var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "mbm-plot-content row" }, [
    _c("div", { staticClass: "col-md-12 phewas-plot-wrapper" }, [
      _c(
        "div",
        {
          staticClass: "col-md-12",
          staticStyle: { display: "inline-block" },
          attrs: { id: _vm.canvasId + "pheWasPlotWrapper" },
        },
        [
          _c(
            "div",
            {
              staticClass: "phe-was-info-box hidden",
              attrs: { id: _vm.canvasId + "pheWasInfoBox" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "fixed-info-box-close",
                  attrs: { id: _vm.canvasId + "info_box_close" },
                  on: {
                    click: function ($event) {
                      return _vm.utils.uiUtils.removeOnMouseOut(
                        _vm.canvasId + "pheWasInfoBox",
                        100
                      )
                    },
                  },
                },
                [_c("b-icon", { attrs: { icon: "x-circle-fill" } })],
                1
              ),
              _c("span", {
                attrs: { id: _vm.canvasId + "pheWasInfoBoxContent" },
              }),
              _vm._l(_vm.hoverItems, function (ptValue, ptKey) {
                return _c(
                  "span",
                  { key: ptKey },
                  [
                    _c("strong", [_vm._v(_vm._s(ptKey))]),
                    _c("br"),
                    _vm._l(ptValue.data, function (dValue, dKey) {
                      return _c("span", [
                        _c("span", [_vm._v(_vm._s(dKey + ": "))]),
                        _c("span", [_vm._v(_vm._s(dValue))]),
                        _vm._v(" "),
                        _c("br"),
                      ])
                    }),
                    _vm.options != null &&
                    _vm.utils.uiUtils.isIdFixed(
                      "#" + _vm.canvasId + "pheWasInfoBox"
                    ) == true
                      ? [
                          !!_vm.options.includes("add phenotype")
                            ? _c(
                                "button",
                                {
                                  staticClass: "option-button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.addPhenotype(ptValue.id)
                                    },
                                  },
                                },
                                [_vm._v(" Add this phenotype below ")]
                              )
                            : _vm._e(),
                          !!_vm.options.includes("open phenotype page")
                            ? _c(
                                "button",
                                {
                                  staticClass: "option-button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.openPage("phenotype.html", {
                                        phenotype: ptValue.id,
                                      })
                                    },
                                  },
                                },
                                [_vm._v(" Go to phenotype page ")]
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm.options != null &&
                    _vm.utils.uiUtils.isIdFixed(
                      "#" + _vm.canvasId + "pheWasInfoBox"
                    ) == false
                      ? _c("span", [_vm._v("Click for options")])
                      : _vm._e(),
                    _c("br"),
                  ],
                  2
                )
              }),
            ],
            2
          ),
          _c("canvas", {
            attrs: {
              hidden: !_vm.showCanvas,
              id: _vm.canvasId + "pheWasPlot",
              width: "",
              height: "",
            },
            on: {
              mousemove: function ($event) {
                return _vm.checkPosition($event, "hover")
              },
              click: function ($event) {
                return _vm.checkPosition($event, "click")
              },
              mouseout: function ($event) {
                !_vm.utils.uiUtils.isIdFixed(
                  "#" + _vm.canvasId + "pheWasInfoBox"
                )
                  ? _vm.utils.uiUtils.removeOnMouseOut(
                      _vm.canvasId + "pheWasInfoBox",
                      1000
                    )
                  : ""
              },
            },
          }),
          _c("div", { staticClass: "download-images-setting" }, [
            _c(
              "span",
              { staticClass: "btn btn-default options-gear" },
              [
                _vm._v("Download "),
                _c("b-icon", { attrs: { icon: "download" } }),
              ],
              1
            ),
            _c("ul", { staticClass: "options" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function ($event) {
                        return _vm.downloadImage(
                          "vector_wrapper_" + _vm.canvasId,
                          _vm.canvasId + "_pheWasPlot",
                          "svg"
                        )
                      },
                    },
                  },
                  [_vm._v("Download SVG")]
                ),
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function ($event) {
                        return _vm.downloadImage(
                          _vm.canvasId + "pheWasPlot",
                          _vm.canvasId + "_pheWasPlot",
                          "png"
                        )
                      },
                    },
                  },
                  [_vm._v("Download PNG")]
                ),
              ]),
            ]),
          ]),
          !!_vm.renderData
            ? _c("research-phewas-plot-vector", {
                ref: _vm.canvasId + "_pheWasPlot",
                attrs: {
                  renderData: _vm.groupData(_vm.renderData),
                  renderConfig: _vm.renderConfig,
                  phenotypeMap: _vm.phenotypeMap,
                  colors: _vm.colors,
                  margin: _vm.adjPlotMargin,
                  sectionId: _vm.canvasId,
                  utils: _vm.utils,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }