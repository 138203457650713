var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", {
      class: "vector-wrapper-" + _vm.sectionId,
      attrs: { id: "vector_wrapper_" + _vm.sectionId },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }