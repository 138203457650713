var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "heatmap-wrapper" }, [
    _c(
      "div",
      {
        staticClass: "clicked-cell-value hidden",
        attrs: { id: "clicked_cell_value" + _vm.sectionId },
      },
      [
        _c("div", {
          attrs: { id: "clicked_cell_value_content" + _vm.sectionId },
        }),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "heatmap-content",
        attrs: { id: "heatmapContent" + _vm.sectionId },
      },
      [
        _c("div", {
          staticClass: "heatmap-scale-legend",
          attrs: { id: "heatmap_scale_legend" + _vm.sectionId },
        }),
        _c(
          "div",
          {
            staticClass: "heatmap-canvas-wrapper",
            attrs: { id: "heatmapPlotWrapper" + _vm.sectionId },
          },
          [
            _c("div", {
              staticClass: "heatmap-columns-wrapper",
              attrs: { id: "heatmapColumnsWrapper" + _vm.sectionId },
            }),
            _c("div", {
              staticClass: "heatmap-rows-wrapper",
              attrs: { id: "heatmapRowsWrapper" + _vm.sectionId },
            }),
            _c(
              "div",
              {
                staticClass: "heatmap-canvas-wrapper",
                attrs: { id: "heatmapCanvasWrapper" + _vm.sectionId },
              },
              [
                !!_vm.renderConfig
                  ? _c("canvas", {
                      attrs: {
                        id: "heatmap" + _vm.sectionId,
                        width: "",
                        height: "",
                      },
                      on: {
                        mouseleave: _vm.hidePanel,
                        mousemove: _vm.checkPosition,
                      },
                    })
                  : _vm._e(),
                _c("div", { staticClass: "download-images-setting" }, [
                  _c(
                    "span",
                    { staticClass: "btn btn-default options-gear" },
                    [
                      _vm._v("Download "),
                      _c("b-icon", { attrs: { icon: "download" } }),
                    ],
                    1
                  ),
                  _c("ul", { staticClass: "options" }, [
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:;" },
                          on: {
                            click: function ($event) {
                              return _vm.downloadImage(
                                "vector_wrapper_" + _vm.sectionId,
                                _vm.sectionId + "_heatmap",
                                "svg"
                              )
                            },
                          },
                        },
                        [_vm._v("Download SVG")]
                      ),
                    ]),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:;" },
                          on: {
                            click: function ($event) {
                              return _vm.downloadImage(
                                "heatmap" + _vm.sectionId,
                                _vm.sectionId + "_heatmap",
                                "png"
                              )
                            },
                          },
                        },
                        [_vm._v("Download PNG")]
                      ),
                    ]),
                  ]),
                ]),
                !!_vm.renderData
                  ? _c("research-heatmap-vector", {
                      ref: _vm.sectionId + "_heatmap",
                      attrs: {
                        renderData: _vm.renderData,
                        renderConfig: _vm.renderConfig,
                        sectionId: _vm.sectionId,
                        utils: _vm.utils,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }