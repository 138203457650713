var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "research-data-table-features-wrapper" },
    _vm._l(_vm.featuresFormat["features"], function (value, index) {
      return _c(
        "div",
        { key: index, staticClass: "research-data-table-features" },
        [
          !!_vm.featuresData &&
          !!_vm.featuresData[value] &&
          !!_vm.featuresFormat[value]
            ? _c(
                "table",
                { staticClass: "table table-sm feature-table" },
                [
                  _c(
                    "tr",
                    [
                      _c(
                        "th",
                        {
                          class: "feature-title-th feature-th-" + index,
                          attrs: {
                            rowspan: _vm.featuresData[value].length + 1,
                          },
                        },
                        [
                          _c("span", { staticClass: "feature-title" }, [
                            _vm._v(_vm._s(value)),
                          ]),
                        ]
                      ),
                      _vm._l(
                        _vm.featuresFormat[value],
                        function (headerValue, headerIndex) {
                          return _c("th", {
                            key: headerIndex,
                            class: "feature-th-" + index,
                            style: !!headerValue.includes(":array")
                              ? "display:none"
                              : "",
                            domProps: {
                              innerHTML: _vm._s(
                                !!headerValue.includes(":array")
                                  ? headerValue.replace(":array", "")
                                  : headerValue
                              ),
                            },
                          })
                        }
                      ),
                    ],
                    2
                  ),
                  _vm._l(
                    _vm.featuresData[value],
                    function (featureValue, featureIndex) {
                      return _c(
                        "tr",
                        { key: featureIndex },
                        _vm._l(
                          _vm.featuresFormat[value],
                          function (headerValue, headerIndex) {
                            return _c("td", {
                              key: headerIndex,
                              style: !!headerValue.includes(":array")
                                ? "padding: 0"
                                : "",
                              domProps: {
                                innerHTML: _vm._s(
                                  !!headerValue.includes(":array")
                                    ? _vm.getFeatureContent(
                                        featureValue[headerValue],
                                        headerIndex
                                      )
                                    : _vm.formatValue(
                                        featureValue[headerValue],
                                        headerValue
                                      )
                                ),
                              },
                            })
                          }
                        ),
                        0
                      )
                    }
                  ),
                ],
                2
              )
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }