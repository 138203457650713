var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "chart-wrapper" }, [
    _c("div", { attrs: { id: "multi-chart" } }, [
      _vm.selectableGenes.length > 0
        ? _c("h4", [_vm._v("Make a selection to view the violin plot.")])
        : _c("h4", [_vm._v("Pending!")]),
    ]),
    _vm.selectableGenes.length > 0
      ? _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedGene,
                expression: "selectedGene",
              },
            ],
            attrs: { id: "select-gene" },
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.selectedGene = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
            },
          },
          [
            _c("option", { attrs: { value: "" } }, [_vm._v("Select gene")]),
            _vm._l(_vm.selectableGenes, function (gene) {
              return _c("option", { domProps: { value: gene } }, [
                _vm._v(_vm._s(gene)),
              ])
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }