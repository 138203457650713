var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.bioindex_dev
        ? _c("img", {
            staticClass: "dev-flag",
            attrs: { src: "/images/dev_flag.svg" },
          })
        : _vm._e(),
      _c("google-analytics"),
      _c("alert"),
      _vm.diseaseGroup
        ? _c("div", { staticClass: "container-fluid" }, [
            _vm.diseaseGroup.default
              ? _c("div", { staticClass: "row amp-banner-2021" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.nih.gov/research-training/accelerating-medicines-partnership-amp/common-metabolic-diseases",
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          width: "500px",
                          "margin-left": "15px",
                          "margin-top": "15px",
                        },
                        attrs: {
                          src: "//kp4cd.org/sites/default/files/vueportal/amp_text.svg",
                        },
                      }),
                    ]
                  ),
                ])
              : _vm._e(),
            !!_vm.diseaseGroup.portalGroup &&
            _vm.diseaseGroup.portalGroup != _vm.diseaseGroup.name
              ? _c(
                  "div",
                  {
                    staticClass: "row amp-banner-2021",
                    staticStyle: { height: "50px", display: "block" },
                  },
                  [
                    _c("a", { attrs: { href: _vm.url2Md } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "5px",
                            "text-align": "center",
                            height: "50px",
                          },
                        },
                        [
                          _c("img", {
                            class: "portals-2-mdkp-logo",
                            attrs: {
                              src: "//kp4cd.org/sites/default/files/vueportal/portals2mdkp_banner.svg",
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _c("div", { class: "row " + _vm.diseaseGroup.name + "kp-header" }, [
              _c(
                "div",
                { class: _vm.diseaseGroup.name + "kp-logo-wrapper col-md-4" },
                [
                  _c("a", { attrs: { href: "/" } }, [
                    _vm.frontContents.field_banner_logo
                      ? _c("img", {
                          class: _vm.diseaseGroup.name + "kp-logo",
                          attrs: {
                            src:
                              "//kp4cd.org/sites/default/files/vueportal/" +
                              _vm.frontContents.field_banner_logo,
                          },
                        })
                      : _c("img", {
                          staticClass: "mdkp-logo",
                          attrs: {
                            src: "//kp4cd.org/sites/default/files/vueportal/mdkp_header_logo.svg",
                          },
                        }),
                  ]),
                  _vm.diseaseGroup.name == "a2f"
                    ? [
                        _vm.page == "front"
                          ? _c("span", { staticClass: "disease-in-session" }, [
                              _vm._v(_vm._s(_vm.$store.state.diseaseInSession)),
                            ])
                          : _vm._e(),
                        _vm.page != "front" &&
                        _vm.$store.state.bioPortal.diseaseSystems.length > 0
                          ? _c("disease-systems", {
                              attrs: {
                                page: _vm.page,
                                diseases:
                                  _vm.$store.state.bioPortal.diseaseSystems,
                                "disease-groups":
                                  _vm.$store.state.bioPortal.diseaseGroups,
                                phenotypes: _vm.rawPhenotypes,
                                "disease-in-session":
                                  _vm.$store.state.diseaseInSession,
                                "phenotype-correlation":
                                  _vm.$store.state.phenotypeCorrelation,
                              },
                            })
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "div",
                { class: "kp-menu-wrapper col-md-8" },
                [
                  !!_vm.diseaseGroup.name
                    ? _c("menu-item", {
                        attrs: {
                          group: _vm.diseaseGroup.name,
                          name: "header.menu",
                          contentMap: _vm.$store.state.bioPortal.documentations,
                        },
                      })
                    : _vm._e(),
                  _c("div", { staticClass: "login-menu-wrapper" }, [
                    _c("ul", [
                      !!_vm.user
                        ? _c("li", [
                            _c(
                              "a",
                              {
                                class: _vm.diseaseGroup.name + "kp-login",
                                attrs: { href: "/logout", title: _vm.user },
                              },
                              [_vm._v("Logout")]
                            ),
                          ])
                        : _c("li", [
                            _c(
                              "a",
                              {
                                class: _vm.diseaseGroup.name + "kp-login",
                                attrs: { href: "/login" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.loginUser.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v("Login")]
                            ),
                          ]),
                    ]),
                  ]),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }