var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { attrs: { id: _vm.pkgID } }),
    _c(
      "div",
      { attrs: { id: "viewers_collection" } },
      [
        _vm.pkgConfig.viewers.includes("region plot") == true
          ? _c("research-region-plot", {
              attrs: {
                plotData: _vm.$store.state.filteredData,
                renderConfig: _vm.pkgConfig["region viewer"],
                dataComparisonConfig: _vm.dataComparisonConfig,
                region: _vm.$store.state.searchingRegion,
                plotMargin: _vm.plotMargin,
                compareGroupColors: _vm.colors.moderate,
                regionZoom: _vm.regionZoom,
                regionViewArea: _vm.regionViewArea,
                sharedPlotXpos: _vm.sharedPlotXpos,
                pkgData: _vm.pkgData,
                pkgDataSelected: _vm.pkgDataSelected,
                utils: _vm.utils,
              },
            })
          : _vm._e(),
        _vm.pkgConfig.viewers.includes("genes plot") == true &&
        _vm.$store.state.codingGenesData != null
          ? _c("research-genes-track", {
              attrs: {
                region: _vm.$store.state.searchingRegion,
                genesData: _vm.$store.state.codingGenesData,
                plotConfig: _vm.pkgConfig["region viewer"],
                plotType: "region plot",
                plotMargin: _vm.plotMargin,
                regionZoom: _vm.regionZoom,
                regionViewArea: _vm.regionViewArea,
                utils: _vm.utils,
              },
            })
          : _vm._e(),
        _vm.pkgConfig.viewers.includes("credible sets plot") == true
          ? _c(
              "div",
              {
                staticClass: "section-opener",
                attrs: { id: "credibleSetSectionOpener" },
                on: {
                  click: function ($event) {
                    return _vm.showHideSection(
                      $event,
                      "credibleSetSection",
                      "Show credible sets filter",
                      "credibleSetSectionOpener",
                      "Hide filter"
                    )
                  },
                },
              },
              [_vm._v(" Hide filter ")]
            )
          : _vm._e(),
        _vm.pkgConfig.viewers.includes("credible sets plot") == true
          ? _c("research-credible-sets-plot", {
              staticClass: "svg-wrapper",
              attrs: {
                id: "credibleSetSection",
                region: _vm.$store.state.searchingRegion,
                phenotype: _vm.$store.state.searchingPhenotype,
                plotMargin: _vm.plotMargin,
                compareGroupColors: _vm.colors.bold,
                renderConfig: _vm.pkgConfig["credible sets viewer"],
                dataComparison: this.$store.state.dataComparison,
                pkgData: _vm.$store.state.pkgData,
                pkgDataSelected: _vm.pkgDataSelected,
                regionZoom: _vm.regionZoom,
                regionViewArea: _vm.regionViewArea,
                utils: _vm.utils,
              },
            })
          : _vm._e(),
        _vm.pkgConfig.viewers.includes("annotations plot") == true ||
        _vm.pkgConfig.viewers.includes("annotations plot v2") == true
          ? _c(
              "div",
              {
                staticClass: "section-opener",
                attrs: { id: "annotationSectionOpener" },
                on: {
                  click: function ($event) {
                    return _vm.showHideSection(
                      $event,
                      "annotationSection",
                      "Show annotations filter",
                      "annotationSectionOpener",
                      "Hide filter"
                    )
                  },
                },
              },
              [_vm._v(" Hide filter ")]
            )
          : _vm._e(),
        _vm.pkgConfig.viewers.includes("annotations plot") == true
          ? _c("research-annotations-plot", {
              ref: "annotationsRef",
              staticClass: "svg-wrapper",
              attrs: {
                id: "annotationSection",
                region: _vm.$store.state.searchingRegion,
                phenotype: _vm.$store.state.searchingPhenotype,
                renderConfig: _vm.pkgConfig["annotations viewer"],
                plotMargin: _vm.plotMargin,
                compareGroupColors: _vm.colors.bold,
                dataComparison: this.$store.state.dataComparison,
                plotData: _vm.$store.state.filteredData,
                pkgData: _vm.pkgData,
                pkgDataSelected: _vm.pkgDataSelected,
                regionZoom: _vm.regionZoom,
                regionViewArea: _vm.regionViewArea,
                sharedPlotXpos: _vm.sharedPlotXpos,
                utils: _vm.utils,
              },
            })
          : _vm._e(),
        _vm.pkgConfig.viewers.includes("annotations plot v2") == true
          ? _c("research-annotations-plot-v2", {
              ref: "annotationsRef",
              staticClass: "svg-wrapper",
              attrs: {
                id: "annotationSection",
                region: _vm.$store.state.searchingRegion,
                phenotype: _vm.$store.state.searchingPhenotype,
                ancestry: _vm.$store.state.searchingAncestry,
                renderConfig: _vm.pkgConfig["annotations viewer"],
                plotMargin: _vm.plotMargin,
                compareGroupColors: _vm.colors.bold,
                dataComparison: this.$store.state.dataComparison,
                plotData: _vm.$store.state.filteredData,
                pkgData: _vm.pkgData,
                pkgDataSelected: _vm.pkgDataSelected,
                regionZoom: _vm.regionZoom,
                regionViewArea: _vm.regionViewArea,
                sharedPlotXpos: _vm.sharedPlotXpos,
                searchParameters: this.$store.state.searchParameters,
                searchParametersArr: this.$store.state.searchParametersArr,
                utils: _vm.utils,
              },
            })
          : _vm._e(),
        _vm.pkgConfig.viewers.includes("biosamples plot") == true &&
        this.$store.state.pkgDataSelected.filter((s) => s.type == "Annotation")
          .length > 0 &&
        this.$store.state.pkgDataSelected.filter((s) => s.type == "Tissue")
          .length > 0
          ? _c(
              "div",
              {
                staticClass: "section-opener",
                attrs: { id: "biosamplesSectionOpener" },
                on: {
                  click: function ($event) {
                    return _vm.showHideSection(
                      $event,
                      "biosamplesSection",
                      "Show biosamples filter",
                      "biosamplesSectionOpener",
                      "Hide filter"
                    )
                  },
                },
              },
              [_vm._v(" Hide filter ")]
            )
          : _vm._e(),
        _vm.pkgConfig.viewers.includes("biosamples plot") == true
          ? _c("research-biosamples-plot", {
              ref: "biosamplesRef",
              staticClass: "svg-wrapper",
              attrs: {
                id: "biosamplesSection",
                region: _vm.$store.state.searchingRegion,
                phenotype: _vm.$store.state.searchingPhenotype,
                renderConfig: _vm.pkgConfig["biosamples viewer"],
                plotMargin: _vm.plotMargin,
                compareGroupColors: _vm.colors.bold,
                searchType: this.$store.state.dataComparison,
                plotData: _vm.$store.state.filteredData,
                pkgData: _vm.pkgData,
                pkgDataSelected: this.$store.state.pkgDataSelected,
                regionZoom: _vm.regionZoom,
                regionViewArea: _vm.regionViewArea,
                sharedPlotXpos: _vm.sharedPlotXpos,
                utils: _vm.utils,
              },
            })
          : _vm._e(),
        _vm.pkgConfig.viewers.includes("gene-links plot") == true
          ? _c(
              "div",
              {
                staticClass: "section-opener",
                attrs: { id: "geneLinksSectionOpener" },
                on: {
                  click: function ($event) {
                    return _vm.showHideSection(
                      $event,
                      "geneLinksSection",
                      "Show gene-links filter",
                      "geneLinksSectionOpener",
                      "Hide filter"
                    )
                  },
                },
              },
              [_vm._v(" Hide filter ")]
            )
          : _vm._e(),
        _vm.pkgConfig.viewers.includes("gene-links plot") == true
          ? _c("research-gene-links-plot", {
              staticClass: "svg-wrapper",
              attrs: {
                id: "geneLinksSection",
                region: _vm.$store.state.searchingRegion,
                phenotype: _vm.$store.state.searchingPhenotype,
                plotMargin: _vm.plotMargin,
                methodsColors: _vm.colors.bold,
                renderConfig: _vm.pkgConfig["gene-links viewer"],
                dataComparison: this.$store.state.dataComparison,
                plotData: _vm.$store.state.filteredData,
                pkgData: _vm.pkgData,
                pkgDataSelected: _vm.pkgDataSelected,
                regionZoom: _vm.regionZoom,
                regionViewArea: _vm.regionViewArea,
                utils: _vm.utils,
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }