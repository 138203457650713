var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "mbm-plot-content row" }, [
    _vm._m(0),
    _c(
      "div",
      {
        staticClass: "dot-value-full-list hidden",
        attrs: { id: "dot_value_full_list" },
      },
      [
        _c(
          "div",
          {
            staticClass: "clicked-dot-value-close",
            on: {
              click: function ($event) {
                return _vm.hidePanel("dot_value_full_list")
              },
            },
          },
          [_c("b-icon", { attrs: { icon: "x-circle-fill" } })],
          1
        ),
        _c("div", { attrs: { id: "dot_value_full_list_content" } }),
      ]
    ),
    !!_vm.renderConfig.legend
      ? _c("div", {
          staticClass: "mbm-plot-legend",
          domProps: { innerHTML: _vm._s(_vm.renderConfig.legend) },
        })
      : _vm._e(),
    _c("div", { staticClass: "col-md-12" }, [
      _vm.dataComparisonConfig != null
        ? _c(
            "div",
            { staticClass: "score-plot-bubbles" },
            [
              _c(
                "span",
                {
                  staticClass: "plot-item-bubble reference",
                  staticStyle: { "background-color": "#00000030" },
                },
                [_vm._v("Avarage")]
              ),
              _vm._l(
                _vm.searchParameters[
                  _vm.dataComparisonConfig.fieldsGroupDataKey
                ].search,
                function (item, itemIndex) {
                  return _c("span", {
                    key: item,
                    class: "plot-item-bubble reference bg-color-" + itemIndex,
                    domProps: { innerHTML: _vm._s(item) },
                  })
                }
              ),
            ],
            2
          )
        : _vm._e(),
      _c("div", { staticClass: "plot-score-options-ui" }, [
        _vm.dataComparisonConfig != null
          ? _c("label", [
              _vm._v("Render plot by: "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.plotRenderBy,
                      expression: "plotRenderBy",
                    },
                  ],
                  staticClass: "score-plot-render-by",
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.plotRenderBy = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function ($event) {
                        return _vm.renderPlot()
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "avarage" } }, [
                    _vm._v("Avarage Score"),
                  ]),
                  _c("option", { attrs: { value: "high" } }, [
                    _vm._v("Highest Score"),
                  ]),
                  _c("option", { attrs: { value: "all" } }, [_vm._v("All")]),
                ]
              ),
            ])
          : _vm._e(),
        _vm.renderConfig["recalculate score"] == true
          ? _c(
              "label",
              [
                _vm._v("Score by: "),
                _vm._l(
                  _vm.renderConfig["score by"],
                  function (option, opIndex) {
                    return _c(
                      "div",
                      { key: opIndex, staticClass: "plot-score-option" },
                      [
                        _c("input", {
                          attrs: {
                            type: "checkbox",
                            id: "score_chkbox_" + opIndex,
                            name: "score_chkbox_" + opIndex,
                            checked: "",
                          },
                          on: { click: _vm.calculateScore },
                        }),
                        _c("label", {
                          attrs: { for: "score_chkbox_" + opIndex },
                          domProps: { innerHTML: _vm._s(option.field) },
                        }),
                      ]
                    )
                  }
                ),
              ],
              2
            )
          : _vm._e(),
      ]),
    ]),
    _c("div", { staticClass: "col-md-12", attrs: { id: "scorePlotWrapper" } }, [
      !!_vm.renderConfig
        ? _c("canvas", {
            attrs: { id: "scorePlot", width: "", height: "" },
            on: {
              mouseleave: _vm.hidePanel,
              mousemove: _vm.checkPosition,
              resize: _vm.onResize,
              click: _vm.getFullList,
            },
          })
        : _vm._e(),
    ]),
    !!_vm.renderConfig.label
      ? _c("div", {
          staticClass: "mbm-plot-label",
          domProps: { innerHTML: _vm._s(_vm.renderConfig.label) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass: "clicked-dot-value hidden",
        attrs: { id: "clicked_dot_value" },
      },
      [_c("div", { attrs: { id: "clicked_dot_value_content" } })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }