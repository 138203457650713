var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mbm-plot-content row" },
    [
      _c("div", { staticClass: "col-md-12" }, [
        !!_vm.localGeneTypes
          ? _c("div", { staticClass: "genes-track-setting" }, [
              _c(
                "span",
                { staticClass: "btn btn-default options-gear" },
                [
                  _vm._v("Genes "),
                  _c("b-icon", { attrs: { icon: "gear-fill" } }),
                ],
                1
              ),
              _c(
                "ul",
                { staticClass: "options" },
                [
                  _c("li", [
                    _c("input", {
                      staticClass: "chkbox",
                      attrs: { type: "checkbox" },
                      on: {
                        click: function ($event) {
                          return _vm.utils.uiUtils.showHideElement(
                            "genesTrackWrapper" + _vm.sectionId
                          )
                        },
                      },
                    }),
                    _c("label", [_vm._v(" Hide track")]),
                  ]),
                  _c("li", [
                    _c("input", {
                      staticClass: "chkbox",
                      attrs: {
                        type: "checkbox",
                        id: _vm.sectionId + "GenesTrackAll",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.checkAll()
                        },
                      },
                    }),
                    _c("label", [_vm._v(" Show all biotypes")]),
                  ]),
                  _vm._l(_vm.localGeneTypes, function (geneType) {
                    return _c("li", { key: geneType }, [
                      geneType == "protein_coding"
                        ? _c("input", {
                            staticClass: "chkbox",
                            attrs: {
                              type: "checkbox",
                              id: _vm.sectionId + geneType,
                              checked: "",
                            },
                            domProps: { value: geneType },
                            on: {
                              click: function ($event) {
                                return _vm.renderTrack(_vm.localGenesData)
                              },
                            },
                          })
                        : _c("input", {
                            staticClass: "chkbox",
                            attrs: {
                              type: "checkbox",
                              id: _vm.sectionId + geneType,
                            },
                            domProps: { value: geneType },
                            on: {
                              click: function ($event) {
                                return _vm.renderTrack(_vm.localGenesData)
                              },
                            },
                          }),
                      _c("label", { attrs: { for: geneType } }, [
                        _vm._v(_vm._s(geneType.replaceAll("_", " "))),
                      ]),
                    ])
                  }),
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function ($event) {
                            return _vm.downloadImage(
                              "vector_wrapper_" + _vm.sectionId,
                              _vm.sectionId + "_genesTrack",
                              "svg"
                            )
                          },
                        },
                      },
                      [_vm._v("Download SVG")]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function ($event) {
                            return _vm.downloadImage(
                              "genesTrack" + _vm.sectionId,
                              _vm.sectionId + "_genesTrack",
                              "png"
                            )
                          },
                        },
                      },
                      [_vm._v("Download PNG")]
                    ),
                  ]),
                ],
                2
              ),
            ])
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "genes-plot-wrapper",
            attrs: { id: "genesTrackWrapper" + _vm.sectionId },
          },
          [
            _c("canvas", {
              attrs: {
                id: "genesTrack" + _vm.sectionId,
                width: "",
                height: "",
              },
              on: { resize: _vm.onResize },
            }),
          ]
        ),
      ]),
      _vm.renderingGenes.length > 0
        ? _c("research-genes-track-vector", {
            ref: _vm.sectionId + "_genesTrack",
            attrs: {
              genesData: _vm.renderingGenes,
              renderConfig: _vm.plotConfig,
              margin: _vm.adjPlotMargin,
              region: _vm.viewingRegion,
              sectionId: _vm.sectionId,
              utils: _vm.utils,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }