var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "mbm-plot-content row" }, [
    _vm.searchingRegion != null
      ? _c("div", { staticClass: "col-md-12 biosamples-plot-wrapper" }, [
          _c("div", { staticClass: "col-md-12" }, [
            !_vm.renderConfig["with annotations viewer"] ||
            _vm.renderConfig["with annotations viewer"] == "false"
              ? _c("div", { attrs: { id: "biosamplesUIWrapper" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "filtering-ui-wrapper add-content",
                      staticStyle: {
                        width: "100%",
                        padding: "0 10px",
                        "text-align": "left",
                      },
                    },
                    [
                      _c("div", { staticClass: "filtering-ui-content" }, [
                        _c(
                          "div",
                          {
                            staticClass: "col",
                            staticStyle: { padding: "2px" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "label",
                                staticStyle: {
                                  display: "inline-block",
                                  "margin-right": "10px",
                                },
                              },
                              [_vm._v(" Select Annotation ")]
                            ),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.annotationOnFocus,
                                    expression: "annotationOnFocus",
                                  },
                                ],
                                staticClass: "custom-select",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.annotationOnFocus = $event.target
                                      .multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "null" } }, [
                                  _vm._v(
                                    " " + _vm._s("Select annotation") + " "
                                  ),
                                ]),
                                _vm._l(
                                  _vm.annoData,
                                  function (annoValue, annoKey) {
                                    return _c("option", {
                                      key: annoKey,
                                      domProps: {
                                        value: annoKey,
                                        innerHTML: _vm._s(annoKey),
                                      },
                                    })
                                  }
                                ),
                              ],
                              2
                            ),
                            _vm.annotationOnFocus != "null"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "label",
                                    staticStyle: {
                                      display: "inline-block",
                                      margin: "0 10px",
                                    },
                                  },
                                  [_vm._v(" Select Tissue Category ")]
                                )
                              : _vm._e(),
                            _vm.annotationOnFocus != "null"
                              ? _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.tissueOnFocus,
                                        expression: "tissueOnFocus",
                                      },
                                    ],
                                    staticClass: "custom-select",
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.tissueOnFocus = $event.target
                                          .multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "null" } }, [
                                      _vm._v(
                                        " " + _vm._s("Select tissue") + " "
                                      ),
                                    ]),
                                    _vm._l(
                                      _vm.annoAssoTissues,
                                      function (tissue) {
                                        return _c("option", {
                                          key: tissue,
                                          domProps: {
                                            value: tissue,
                                            innerHTML: _vm._s(tissue),
                                          },
                                        })
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm.annotationOnFocus != "null" &&
                            _vm.tissueOnFocus != "null"
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "btn btn-primary btn-sm btn-biosamples",
                                    on: {
                                      click: function ($event) {
                                        return _vm.getBiosamples(
                                          _vm.annotationOnFocus,
                                          _vm.tissueOnFocus
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Get biosamples")]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                      _vm.pkgDataSelected.filter((s) => s.type == "Annotation")
                        .length > 0 &&
                      !_vm.renderConfig["no search key bubbles"]
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                position: "absolute",
                                right: "10px",
                                top: "7px",
                              },
                            },
                            [
                              _vm._l(
                                _vm.pkgDataSelected.filter(
                                  (s) => s.type == "Annotation"
                                ),
                                function (a) {
                                  return [
                                    _c("span", {
                                      key: a.id,
                                      class: "btn search-bubble ",
                                      style:
                                        "background-color:" +
                                        _vm.getColorIndex(a.id),
                                      domProps: {
                                        innerHTML: _vm._s(
                                          a.id +
                                            "&nbsp;<span class='remove'>X</span>"
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeAnno(a.id)
                                        },
                                      },
                                    }),
                                  ]
                                }
                              ),
                              _vm._l(
                                _vm.pkgDataSelected.filter(
                                  (s) => s.type == "Tissue"
                                ),
                                function (t) {
                                  return [
                                    _c("span", {
                                      key: t.id,
                                      class: "btn search-bubble ",
                                      style: "background-color:#999999",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          t.id +
                                            "&nbsp;<span class='remove'>X</span>"
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeTissue(t.id)
                                        },
                                      },
                                    }),
                                  ]
                                }
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]
                  ),
                ])
              : _vm._e(),
            !_vm.renderConfig["with annotations viewer"] ||
            _vm.renderConfig["with annotations viewer"] == "false"
              ? _c(
                  "div",
                  {
                    staticClass: "col-md-12 bio-plot-ui-wrapper",
                    staticStyle: { "border-bottom": "solid 1px #dddddd" },
                  },
                  [
                    _vm._m(0),
                    _c(
                      "div",
                      _vm._l(
                        _vm.annoData,
                        function (annoValue, annoKey, annoIndex) {
                          return _c(
                            "div",
                            { key: annoKey, staticClass: "bio-bubble-wrapper" },
                            [
                              _c("span", {
                                staticClass: "bio-bubble",
                                style:
                                  "background-color:" +
                                  _vm.compareGroupColors[annoIndex] +
                                  ";",
                                domProps: { innerHTML: _vm._s("&nbsp;") },
                              }),
                              _c("span", {
                                domProps: { innerHTML: _vm._s(annoKey) },
                              }),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                    _vm.searchingPhenotype != null
                      ? _c("div", { attrs: { id: "BSGEPlotWrapper" } }, [
                          _c("div", {
                            staticClass: "hidden",
                            attrs: { id: "BSGEInfoBox" },
                          }),
                          _c("canvas", {
                            staticStyle: { "background-color": "#ffffff" },
                            attrs: { id: "BSGEPlot", width: "", height: "" },
                            on: {
                              mousemove: function ($event) {
                                return _vm.checkGEPosition($event)
                              },
                              mouseout: function ($event) {
                                return _vm.onMouseOut("BSGEInfoBox")
                              },
                            },
                          }),
                        ])
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _vm.pkgDataSelected.filter((s) => s.type == "Annotation").length >
              0 &&
            _vm.pkgDataSelected.filter((s) => s.type == "Tissue").length > 0
              ? _c("span", [
                  _c("strong", [
                    _vm._v(
                      "Filter associated variants by location within regulatory regions annotated in specific tissue or cell types within the tissue categories selected above. "
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "col-md-9 bio-plot-wrapper" }, [
            _c(
              "div",
              {
                class:
                  _vm.pkgDataSelected.filter((s) => s.type == "Annotation")
                    .length == 0 ||
                  _vm.pkgDataSelected.filter((s) => s.type == "Tissue")
                    .length == 0
                    ? "height-1px"
                    : "height-auto",
                attrs: { id: "biosamplesPlotWrapper" },
              },
              [
                _c("div", {
                  staticClass: "hidden",
                  attrs: { id: "biosampleInfoBox" },
                }),
                _c("canvas", {
                  attrs: { id: "biosamplesPlot", width: "", height: "0" },
                  on: {
                    resize: _vm.onResize,
                    mousemove: function ($event) {
                      return _vm.checkPosition($event, "hover")
                    },
                    click: function ($event) {
                      return _vm.checkPosition($event, "click")
                    },
                    mouseout: function ($event) {
                      return _vm.onMouseOut("biosampleInfoBox")
                    },
                  },
                }),
              ]
            ),
          ]),
          _vm.getPropsArr("methods") != null &&
          _vm.getPropsArr("sources") != null
            ? _c(
                "div",
                {
                  staticClass: "col-md-3 reference-area",
                  staticStyle: {
                    display: "inline-block",
                    "vertical-align": "top",
                  },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-outline-secondary",
                      staticStyle: {
                        "margin-right": "5px",
                        "margin-bottom": "10px",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.checkUncheckAll("check")
                        },
                      },
                    },
                    [_vm._v(" Select all ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-outline-secondary",
                      staticStyle: { "margin-bottom": "10px" },
                      on: {
                        click: function ($event) {
                          return _vm.checkUncheckAll("uncheck")
                        },
                      },
                    },
                    [_vm._v(" Unselect all ")]
                  ),
                  _vm._m(1),
                  _vm._l(_vm.getPropsArr("methods"), function (g) {
                    return _c(
                      "div",
                      { key: g, staticStyle: { display: "inline-block" } },
                      [
                        _c(
                          "label",
                          { staticStyle: { "padding-right": "10px" } },
                          [
                            _c("input", {
                              attrs: { type: "checkbox" },
                              domProps: {
                                value: g,
                                checked: !_vm.pkgDataSelected
                                  .filter((s) => s.type == "BS-Method")
                                  .map((s) => s.id)
                                  .includes(g),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addRemoveParameter(g, "BS-Method")
                                },
                              },
                            }),
                            _vm._v(_vm._s(" " + g + " ") + " "),
                          ]
                        ),
                      ]
                    )
                  }),
                  _vm._m(2),
                  _vm._l(_vm.getPropsArr("sources"), function (g) {
                    return _c(
                      "div",
                      { key: g, staticStyle: { display: "inline-block" } },
                      [
                        _c(
                          "label",
                          { staticStyle: { "padding-right": "10px" } },
                          [
                            _c("input", {
                              attrs: { type: "checkbox" },
                              domProps: {
                                value: g,
                                checked: !_vm.pkgDataSelected
                                  .filter((s) => s.type == "BS-Source")
                                  .map((s) => s.id)
                                  .includes(g),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addRemoveParameter(g, "BS-Source")
                                },
                              },
                            }),
                            _vm._v(_vm._s(" " + g + " ") + " "),
                          ]
                        ),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h6", [_c("strong", [_vm._v("Global Enrichment")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h6", [_c("strong", [_vm._v("Methods")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h6", [_c("strong", [_vm._v("Sources")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }