var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.$parent.displayOnKP == true
        ? _c("page-header", {
            attrs: {
              "disease-group": _vm.$parent.diseaseGroup,
              "front-contents": _vm.$parent.frontContents,
              rawPhenotypes: _vm.$parent.rawPhenotypes,
            },
          })
        : _vm._e(),
      !!_vm.$parent.sectionConfigs
        ? _c("research-page-header", {
            class:
              _vm.$parent.displayOnKP == true
                ? "research-portal-header-compact"
                : "research-portal-header",
            attrs: {
              researchMenu: _vm.$parent.researchMenu,
              headerLogo:
                _vm.$parent.displayOnKP == true ||
                _vm.$parent.headerLogo == false
                  ? null
                  : _vm.$parent.headerLogo,
              sectionConfig: _vm.$parent.sectionConfigs["header menu"],
              phenotypes: _vm.$parent.phenotypesInSession,
              utils: _vm.$parent.utilsBox,
            },
          })
        : _vm._e(),
      !!_vm.$parent.sectionConfigs &&
      !!_vm.$parent.sectionConfigs["single search"]
        ? _c(
            "div",
            { staticClass: "single-search-wrapper" },
            [
              !_vm.$parent.sectionConfigs["single search"]["version"]
                ? _c("research-single-search", {
                    attrs: {
                      "single-search-config":
                        _vm.$parent.sectionConfigs["single search"],
                      phenotypes: _vm.$parent.phenotypesInSession,
                      utils: _vm.$parent.utilsBox,
                    },
                  })
                : _vm._e(),
              !!_vm.$parent.sectionConfigs["single search"]["version"] &&
              _vm.$parent.sectionConfigs["single search"]["version"] == "2.0"
                ? _c("research-single-search-v2", {
                    attrs: {
                      "single-search-config":
                        _vm.$parent.sectionConfigs["single search"],
                      phenotypes: _vm.$parent.phenotypesInSession,
                      utils: _vm.$parent.utilsBox,
                    },
                  })
                : _vm._e(),
              !!_vm.$parent.sectionConfigs["single search"]["version"] &&
              _vm.$parent.sectionConfigs["single search"]["version"] == "cfde"
                ? _c("research-single-search-cfde", {
                    attrs: {
                      "single-search-config":
                        _vm.$parent.sectionConfigs["single search"],
                      phenotypes: _vm.$parent.phenotypesInSession,
                      utils: _vm.$parent.utilsBox,
                    },
                  })
                : _vm._e(),
              !!_vm.$parent.sectionConfigs["single search"]["search examples"]
                ? _c("div", { staticClass: "fp-search-examples" }, [
                    _c("span", {
                      domProps: { innerHTML: _vm._s("examples: ") },
                    }),
                  ])
                : _vm._e(),
              !!_vm.$parent.sectionConfigs["context"]
                ? _c(
                    "div",
                    { staticClass: "context-btns-wrapper" },
                    [
                      _vm._l(
                        _vm.$parent.sectionConfigs["context"]["contexts"],
                        function (value, key, index) {
                          return _c(
                            "span",
                            {
                              staticClass: "context-btn btn btn-sm btn-primary",
                              class:
                                !!_vm.$parent.context &&
                                key.toLowerCase().replace(" ", "_") ==
                                  _vm.$parent.context
                                  ? " active"
                                  : "",
                              on: {
                                click: function ($event) {
                                  return _vm.$parent.setContext(key, value)
                                },
                              },
                            },
                            [_vm._v(_vm._s(key))]
                          )
                        }
                      ),
                      _c(
                        "span",
                        {
                          staticClass:
                            "context-btn-default btn btn-sm btn-warning",
                          on: {
                            click: function ($event) {
                              return _vm.$parent.setContext("remove", null)
                            },
                          },
                        },
                        [_vm._v("Remove context")]
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.$parent.researchMode == "no_set"
        ? _c("div", { staticClass: "container-fluid mdkp-body" }, [_vm._m(0)])
        : _vm._e(),
      _vm.$parent.researchMode == "dev" && _vm.$parent.researchPage == null
        ? _c("div", { staticClass: "container-fluid mdkp-body" }, [
            _c("div", { staticClass: "card mdkp-card dataset-page-header" }, [
              _c("div", { staticClass: "row card-body" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "filtering-ui-wrapper" }, [
                    _c("div", { staticClass: "filtering-ui-content row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("Reviewer ID"),
                        ]),
                        _c("div", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.$parent.devID,
                                expression: "$parent.devID",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: _vm.$parent.devID },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.$parent,
                                  "devID",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "col" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("Reviewer P/W"),
                        ]),
                        _c("div", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.$parent.devPW,
                                expression: "$parent.devPW",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "password" },
                            domProps: { value: _vm.$parent.devPW },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.$parent,
                                  "devPW",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "col" }, [
                        _c("div", { staticClass: "label" }, [_vm._v(" ")]),
                        _c("div", [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.$parent.fetchDevPage()
                                },
                              },
                            },
                            [_vm._v(" Load page ")]
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("label", { staticClass: "col" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$parent.devCK,
                              expression: "$parent.devCK",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.$parent.devCK)
                              ? _vm._i(_vm.$parent.devCK, null) > -1
                              : _vm.$parent.devCK,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.$parent.devCK,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.$parent,
                                      "devCK",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.$parent,
                                      "devCK",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.$parent, "devCK", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" Remember me for 2 weeks. "),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.$parent.researchPage !== null &&
      _vm.$parent.sectionConfigs["is front page"]
        ? _c("research-front-page", {
            attrs: {
              sectionConfigs: _vm.$parent.sectionConfigs,
              pageDescription: _vm.$parent.pageDescription,
              utilsBox: _vm.$parent.utilsBox,
              phenotypeMap: _vm.$parent.phenotypeMap,
              phenotypesInUse: _vm.$parent.phenotypesInSession,
            },
          })
        : _vm._e(),
      _vm.$parent.researchPage !== null
        ? _c(
            "div",
            {
              staticClass: "container-fluid mdkp-body",
              class: !!_vm.$parent.sectionConfigs["is multi section"]
                ? "flex-body"
                : "",
            },
            [
              !_vm.$parent.sectionConfigs["is front page"]
                ? _c(
                    "div",
                    { staticClass: "card mdkp-card dataset-page-header" },
                    [
                      _c("div", { staticClass: "row card-body" }, [
                        _c("div", { staticClass: "col-md-8" }, [
                          _c("h3", {
                            domProps: {
                              innerHTML: _vm._s(_vm.$parent.pageTitle),
                            },
                          }),
                          !!_vm.$parent.apiParameters &&
                          !!_vm.$parent.apiParameters[
                            "parameters in sub header"
                          ]
                            ? _c("div", {
                                staticClass: "rp-sub-header",
                                attrs: { id: "rpSubHeader" },
                              })
                            : _vm._e(),
                        ]),
                        !!_vm.$parent.sectionConfigs &&
                        !!_vm.$parent.sectionConfigs["is multi section"]
                          ? _c("div", { staticClass: "col-md-4 text-right" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-sm btn-primary",
                                  attrs: {
                                    title: "Show / hide captured data list",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$parent.utilsBox.uiUtils.showHideElement(
                                        "captured_data_panel"
                                      )
                                    },
                                  },
                                },
                                [_c("b-icon", { attrs: { icon: "images" } })],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "card mdkp-card hidden",
                  attrs: { id: "captured_data_panel" },
                },
                [
                  _c("div", { staticClass: "row card-body" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("h4", [_vm._v("Captured data")]),
                      _c(
                        "table",
                        { staticClass: "table table-sm research-data-table" },
                        [
                          _vm._m(1),
                          _c(
                            "tbody",
                            _vm._l(
                              _vm.$store.state.capturedData,
                              function (data) {
                                return _c("tr", [
                                  _c("td", {
                                    domProps: { innerHTML: _vm._s(data.title) },
                                  }),
                                  _c("td", [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-primary save-remove-section-data",
                                        on: {
                                          click: function ($event) {
                                            return _vm.$parent.saveCapturedData(
                                              "csv",
                                              data.title
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Save CSV")]
                                    ),
                                  ]),
                                  _c("td", [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-primary save-remove-section-data",
                                        on: {
                                          click: function ($event) {
                                            return _vm.$parent.saveCapturedData(
                                              "json",
                                              data.title
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Save JSON")]
                                    ),
                                  ]),
                                  _c("td", [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-sm btn-warning",
                                        on: {
                                          click: function ($event) {
                                            return _vm.$store.dispatch(
                                              "capturedData",
                                              {
                                                action: "remove",
                                                title: data.title,
                                              }
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Remove")]
                                    ),
                                  ]),
                                ])
                              }
                            ),
                            0
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "col-md-12 text-center" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            on: {
                              click: function ($event) {
                                return _vm.$parent.utilsBox.uiUtils.saveJson(
                                  _vm.$store.state.capturedData,
                                  _vm.$parent.pageTitle + " sections data"
                                )
                              },
                            },
                          },
                          [_vm._v("Save all (JSON)")]
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _vm.$parent.pageDescription != null &&
              !_vm.$parent.sectionConfigs["is front page"]
                ? _c("div", { staticClass: "card mdkp-card" }, [
                    _c("div", { staticClass: "row card-body" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c("research-page-description", {
                            attrs: {
                              content: _vm.$parent.pageDescription,
                              utils: _vm.$parent.utilsBox,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.$parent.researchMethodID != null
                ? _c("div", { staticClass: "kp-tabs-wrapper" }, [
                    _c(
                      "ul",
                      { staticClass: "kp-tabs", attrs: { id: "rp_tabs" } },
                      [
                        _c(
                          "li",
                          {
                            staticClass: "kp-tab active",
                            attrs: { id: "view_data" },
                            on: {
                              click: function ($event) {
                                return _vm.$parent.showTabContent(
                                  "view_data",
                                  "view_data_content",
                                  "rp_tabs",
                                  "rp_tabs_contents"
                                )
                              },
                            },
                          },
                          [_c("a", [_vm._v("View Data")])]
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "kp-tab",
                            attrs: { id: "research_method" },
                            on: {
                              click: function ($event) {
                                return _vm.$parent.showTabContent(
                                  "research_method",
                                  "research_method_content",
                                  "rp_tabs",
                                  "rp_tabs_contents"
                                )
                              },
                            },
                          },
                          [_c("a", [_vm._v("Research Method")])]
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  class: !_vm.$parent.sectionConfigs["is front page"]
                    ? "kp-tabs-contents"
                    : "kp-tabs-contents not-active",
                  attrs: { id: "rp_tabs_contents" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "kp-tab-content active",
                      attrs: { id: "view_data_content" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          !!_vm.$store.state.bioPortal.phenotypes &&
                          _vm.$store.state.bioPortal.phenotypes.length > 0 &&
                          _vm.$parent.dataFilesLabels != null
                            ? [
                                (_vm.$parent.dataFilters != null &&
                                  _vm.$parent.researchData != null) ||
                                _vm.$parent.dataFiles.length > 1 ||
                                _vm.$parent.apiParameters != null
                                  ? _c(
                                      "div",
                                      { staticClass: "col-md-12" },
                                      [
                                        _c("research-page-filters", {
                                          attrs: {
                                            dataFiles: _vm.$parent.dataFiles,
                                            filesListLabels:
                                              _vm.$parent.dataFilesLabels,
                                            apiParameters:
                                              _vm.$parent.apiParameters,
                                            dataComparisonConfig:
                                              _vm.$parent.dataComparisonConfig,
                                            dataType: _vm.$parent.dataType,
                                            isAPI: _vm.$parent.isAPI,
                                            uid: _vm.$parent.uid,
                                            filters: _vm.$parent.dataFilters,
                                            filterWidth:
                                              _vm.$parent.filterWidth,
                                            dataset:
                                              _vm.$store.state.filteredData,
                                            unfilteredDataset:
                                              _vm.$store.state.unfilteredData,
                                            utils: _vm.$parent.utilsBox,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          !!_vm.$parent.plotConfig &&
                          !!_vm.$parent.plotConfig.zoom &&
                          _vm.$parent.plotConfig.zoom == "true"
                            ? _c(
                                "div",
                                { staticClass: "col-md-12 zoom-ui-wrapper" },
                                [
                                  _c("span", [_vm._v("Zoom plots")]),
                                  _c(
                                    "form",
                                    { staticClass: "zoom-radio-wrapper" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "zoom-radio-number",
                                          on: {
                                            click: function ($event) {
                                              _vm.$parent.regionZoom -=
                                                _vm.$parent.regionZoom != 0
                                                  ? 10
                                                  : 0
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: { icon: "zoom-out" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._l(
                                        [0, 10, 20, 30, 40, 50, 60, 70, 80, 90],
                                        function (value) {
                                          return _c("input", {
                                            key: value,
                                            class:
                                              _vm.$parent.regionZoom == value
                                                ? "zoom-radio checked"
                                                : "zoom-radio",
                                            attrs: {
                                              type: "radio",
                                              name: "regionZoom",
                                            },
                                            domProps: { value: value },
                                            on: {
                                              click: function ($event) {
                                                _vm.$parent.regionZoom = value
                                              },
                                            },
                                          })
                                        }
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "zoom-radio-number",
                                          on: {
                                            click: function ($event) {
                                              _vm.$parent.regionZoom +=
                                                _vm.$parent.regionZoom != 90
                                                  ? 10
                                                  : 0
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: { icon: "zoom-in" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                  _c("span", [_vm._v("Move viewing area")]),
                                  _c(
                                    "form",
                                    { staticClass: "zoom-radio-wrapper" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "zoom-radio-number",
                                          on: {
                                            click: function ($event) {
                                              _vm.$parent.regionViewArea -=
                                                _vm.$parent.regionViewArea !=
                                                  -100 &&
                                                _vm.$parent.regionZoom != 0
                                                  ? 20
                                                  : 0
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "arrow-left-circle",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._l(
                                        [
                                          -100, -80, -60, -40, -20, 0, 20, 40,
                                          60, 80, 100,
                                        ],
                                        function (value) {
                                          return _c("input", {
                                            key: value,
                                            class:
                                              _vm.$parent.regionViewArea ==
                                              value
                                                ? "zoom-radio checked"
                                                : value == 0
                                                ? "zoom-radio center"
                                                : "zoom-radio",
                                            attrs: {
                                              type: "radio",
                                              name: "regionViewArea",
                                            },
                                            domProps: { value: value },
                                            on: {
                                              click: function ($event) {
                                                _vm.$parent.regionZoom != 0
                                                  ? (_vm.$parent.regionViewArea =
                                                      value)
                                                  : ""
                                              },
                                            },
                                          })
                                        }
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "zoom-radio-number",
                                          on: {
                                            click: function ($event) {
                                              _vm.$parent.regionViewArea +=
                                                _vm.$parent.regionViewArea !=
                                                  100 &&
                                                _vm.$parent.regionZoom != 0
                                                  ? 20
                                                  : 0
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "arrow-right-circle",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.$store.state.filteredData != ""
                            ? _c(
                                "div",
                                { class: "col-md-12 " + _vm.$parent.plotClass },
                                [
                                  _c("div", {
                                    staticClass: "plot-legend",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.$parent.plotLegend),
                                    },
                                  }),
                                  _vm.$parent.plotConfig != null &&
                                  _vm.$parent.plotConfig["type"] ==
                                    "manhattan plot"
                                    ? _c("research-m-plot", {
                                        attrs: {
                                          plotData:
                                            _vm.$store.state.filteredData,
                                          renderConfig: _vm.$parent.plotConfig,
                                          utils: _vm.$parent.utilsBox,
                                          sectionId: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.$parent.plotConfig != null &&
                                  _vm.$parent.plotConfig["type"] ==
                                    "manhattan bitmap plot"
                                    ? _c("research-m-bitmap-plot", {
                                        attrs: {
                                          plotData:
                                            _vm.$store.state.filteredData,
                                          renderConfig: _vm.$parent.plotConfig,
                                          dataComparisonConfig:
                                            _vm.$parent.dataComparisonConfig,
                                          compareGroupColors:
                                            _vm.$parent.colors.moderate,
                                          utils: _vm.$parent.utilsBox,
                                          sectionId: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.$parent.plotConfig != null &&
                                  _vm.$parent.plotConfig["type"] ==
                                    "manhattan qq plot"
                                    ? _c("research-m-qq-plot", {
                                        attrs: {
                                          plotData:
                                            _vm.$store.state.filteredData,
                                          renderConfig: _vm.$parent.plotConfig,
                                          dataComparisonConfig:
                                            _vm.$parent.dataComparisonConfig,
                                          compareGroupColors:
                                            _vm.$parent.colors.moderate,
                                          utils: _vm.$parent.utilsBox,
                                          sectionId: "",
                                        },
                                      })
                                    : _vm._e(),
                                  !!_vm.$parent.plotConfig &&
                                  _vm.$parent.plotConfig["type"] ==
                                    "region plot"
                                    ? _c("research-region-plot", {
                                        attrs: {
                                          plotData:
                                            _vm.$store.state.filteredData,
                                          renderConfig: _vm.$parent.plotConfig,
                                          searchParameters:
                                            _vm.$store.state.searchParameters,
                                          dataComparisonConfig:
                                            _vm.$parent.dataComparisonConfig,
                                          region:
                                            _vm.$store.state.searchingRegion,
                                          plotMargin: _vm.$parent.plotMargin,
                                          compareGroupColors:
                                            _vm.$parent.colors.moderate,
                                          regionZoom: _vm.$parent.regionZoom,
                                          regionViewArea:
                                            _vm.$parent.regionViewArea,
                                          pkgData: _vm.$store.state.pkgData,
                                          pkgDataSelected:
                                            _vm.$store.state.pkgDataSelected,
                                          utils: _vm.$parent.utilsBox,
                                          sectionId: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.$parent.plotConfig != null &&
                                  _vm.$parent.plotConfig["type"] == "score plot"
                                    ? _c("research-score-plot", {
                                        attrs: {
                                          plotData: _vm.$parent.filteredData,
                                          renderConfig: _vm.$parent.plotConfig,
                                          dataComparisonConfig:
                                            _vm.$parent.dataComparisonConfig,
                                          region:
                                            _vm.$store.state.searchingRegion,
                                          searchParameters:
                                            _vm.$store.state.searchParameters,
                                          utils: _vm.$parent.utilsBox,
                                          sectionId: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.$parent.plotConfig != null &&
                                  _vm.$parent.plotConfig["type"] !=
                                    "gem package" &&
                                  !!_vm.$parent.plotConfig["genes track"] &&
                                  _vm.$store.state.codingGenesData != null
                                    ? _c("research-genes-track", {
                                        attrs: {
                                          region:
                                            _vm.$store.state.searchingRegion,
                                          genesData:
                                            _vm.$store.state.codingGenesData,
                                          plotConfig: _vm.$parent.plotConfig,
                                          plotType:
                                            _vm.$parent.plotConfig["type"],
                                          plotMargin: _vm.$parent.plotMargin,
                                          regionZoom: _vm.$parent.regionZoom,
                                          regionViewArea:
                                            _vm.$parent.regionViewArea,
                                          utils: _vm.$parent.utilsBox,
                                          sectionId: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.$parent.plotConfig != null &&
                                  _vm.$parent.plotConfig["type"] ==
                                    "volcano plot"
                                    ? _c("research-volcano-plot", {
                                        attrs: {
                                          plotData:
                                            _vm.$store.state.filteredData,
                                          renderConfig: _vm.$parent.plotConfig,
                                          utils: _vm.$parent.utilsBox,
                                          sectionId: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.$parent.plotConfig != null &&
                                  _vm.$parent.plotConfig["type"] == "heat map"
                                    ? _c("research-heatmap", {
                                        attrs: {
                                          heatmapData:
                                            _vm.$store.state.filteredData,
                                          renderConfig: _vm.$parent.plotConfig,
                                          utils: _vm.$parent.utilsBox,
                                          sectionId: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.$parent.plotConfig != null &&
                                  _vm.$parent.plotConfig["type"] ==
                                    "phewas plot"
                                    ? _c("research-phewas-plot", {
                                        attrs: {
                                          phenotypesData:
                                            _vm.$store.state.filteredData,
                                          phenotypeMap:
                                            _vm.$store.state.bioPortal
                                              .phenotypeMap,
                                          colors: _vm.$parent.colors.extraBold,
                                          plotMargin: _vm.$parent.plotMargin,
                                          renderConfig: _vm.$parent.plotConfig,
                                          pkgData: null,
                                          pkgDataSelected: null,
                                          utils: _vm.$parent.utilsBox,
                                          canvasId: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.$parent.plotConfig != null &&
                                  _vm.$parent.plotConfig["type"] ==
                                    "gem package"
                                    ? _c("kp-gem-pkg", {
                                        attrs: {
                                          pkgConfig: _vm.$parent.plotConfig,
                                          pkgData: _vm.$store.state.pkgData,
                                          pkgDataSelected:
                                            _vm.$store.state.pkgDataSelected,
                                          sharedPlotXpos:
                                            _vm.$store.state.sharedPlotXpos,
                                          plotMargin: _vm.$parent.plotMargin,
                                          dataComparisonConfig:
                                            _vm.$parent.dataComparisonConfig,
                                          colors: _vm.$parent.colors,
                                          regionZoom: _vm.$parent.regionZoom,
                                          regionViewArea:
                                            _vm.$parent.regionViewArea,
                                          utils: _vm.$parent.utilsBox,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$store.state.filteredData != "" &&
                          _vm.$parent.dataTableFormat != null
                            ? _c(
                                "div",
                                { staticClass: "col-md-12" },
                                [
                                  !_vm.$parent.dataTableFormat[
                                    "custom table"
                                  ] && !!_vm.$store.state.bioPortal.phenotypeMap
                                    ? _c("research-data-table", {
                                        attrs: {
                                          pageID: _vm.$parent.pageID,
                                          dataset: _vm.$parent.filteredData,
                                          tableFormat:
                                            _vm.$parent.dataTableFormat,
                                          initPerPageNumber:
                                            _vm.$parent.tablePerPageNumber,
                                          tableLegend: _vm.$parent.tableLegend,
                                          dataComparisonConfig:
                                            _vm.$parent.dataComparisonConfig,
                                          searchParameters:
                                            _vm.$store.state.searchParameters,
                                          pkgData: _vm.$store.state.pkgData,
                                          pkgDataSelected:
                                            _vm.$store.state.pkgDataSelected,
                                          phenotypeMap:
                                            _vm.$store.state.bioPortal
                                              .phenotypeMap,
                                          utils: _vm.$parent.utilsBox,
                                        },
                                      })
                                    : _vm._e(),
                                  !!_vm.$parent.dataTableFormat[
                                    "custom table"
                                  ] &&
                                  _vm.$parent.dataTableFormat["custom table"]
                                    .name == "gem package"
                                    ? _c("research-gem-data-table", {
                                        attrs: {
                                          pageID: _vm.$parent.pageID,
                                          dataset: _vm.$parent.filteredData,
                                          tableFormat:
                                            _vm.$parent.dataTableFormat,
                                          initPerPageNumber:
                                            _vm.$parent.tablePerPageNumber,
                                          tableLegend: _vm.$parent.tableLegend,
                                          dataComparisonConfig:
                                            _vm.$parent.dataComparisonConfig,
                                          searchParameters:
                                            _vm.$store.state.searchParameters,
                                          pkgData: _vm.$store.state.pkgData,
                                          pkgDataSelected:
                                            _vm.$store.state.pkgDataSelected,
                                          region:
                                            _vm.$store.state.searchingRegion,
                                          regionZoom: _vm.$parent.regionZoom,
                                          regionViewArea:
                                            _vm.$parent.regionViewArea,
                                          utils: _vm.$parent.utilsBox,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          !!_vm.$parent.sectionConfigs &&
                          !!_vm.$parent.sectionConfigs["is multi section"]
                            ? _c(
                                "div",
                                { staticClass: "col-md-12" },
                                [
                                  !!_vm.$parent.multiSectionsSearchParameters
                                    ? _c("research-multi-sections-search", {
                                        attrs: {
                                          searchParameters:
                                            _vm.$parent
                                              .multiSectionsSearchParameters,
                                          phenotypesInUse:
                                            _vm.$parent.phenotypesInSession,
                                          sections:
                                            _vm.$parent.sectionConfigs.sections,
                                          utils: _vm.$parent.utilsBox,
                                          searchVisible: !!_vm.$parent
                                            .sectionConfigs["search parameters"]
                                            ? true
                                            : false,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._l(
                                    _vm.$parent.getTabGroups(
                                      _vm.$parent.sectionConfigs["tab groups"]
                                    ),
                                    function (group, groupIndex) {
                                      return !!_vm.$parent.sectionConfigs[
                                        "tab groups"
                                      ]
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                class: [
                                                  group.type &&
                                                  group.type === "fixed bottom"
                                                    ? "tabgroup-fixed-bottom"
                                                    : "tabgroup",
                                                ],
                                                staticStyle: {
                                                  position: "relative",
                                                },
                                              },
                                              [
                                                !!group.label &&
                                                group.type &&
                                                group.type !== "fixed bottom"
                                                  ? _c("h4", [
                                                      _vm._v(
                                                        _vm._s(group.label)
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                !group.type
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-sm show-tabs-btn show-hide-section",
                                                        attrs: {
                                                          id:
                                                            "tabsOpener" +
                                                            groupIndex,
                                                          targetId:
                                                            "tabUiGroup" +
                                                            groupIndex,
                                                          title:
                                                            "Show / hide section",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.$parent.utilsBox.uiUtils.showHideSvg(
                                                              "tabUiGroup" +
                                                                groupIndex
                                                            )
                                                            _vm.$parent.utilsBox.uiUtils.showHideSvg(
                                                              "tabContentGroup" +
                                                                groupIndex
                                                            )
                                                            _vm.$parent.utilsBox.uiUtils.addRemoveClass(
                                                              "tabsOpener" +
                                                                groupIndex,
                                                              "closed"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              id:
                                                                "groupLabel" +
                                                                +groupIndex,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                "Show / hide section"
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                group.type &&
                                                group.type === "fixed bottom"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "fixed-group-toggle-wrapper",
                                                      },
                                                      [
                                                        group.type &&
                                                        group.type ===
                                                          "fixed bottom"
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "fixed-group-toggle",
                                                                attrs: {
                                                                  id: "fixed_group_toggle",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$parent.utilsBox.uiUtils.toggleFixedSummarySection(
                                                                        "tabUiGroup" +
                                                                          groupIndex
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "gg-chevron-double-up",
                                                                }),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "fixed-group-toggle-label",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Open " +
                                                                        _vm._s(
                                                                          group.label
                                                                        ) +
                                                                        " Section"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tab-ui-wrapper",
                                                    attrs: {
                                                      id:
                                                        "tabUiGroup" +
                                                        groupIndex,
                                                    },
                                                  },
                                                  _vm._l(
                                                    group.sections,
                                                    function (tab, tabIndex) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "tab-ui-tab",
                                                          class:
                                                            tabIndex == 0
                                                              ? "active"
                                                              : "",
                                                          attrs: {
                                                            id:
                                                              "tabUi" +
                                                              tab.section,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.$parent.utilsBox.uiUtils.setTabActive(
                                                                "tabUi" +
                                                                  tab.section,
                                                                "tabUiGroup" +
                                                                  groupIndex,
                                                                "tabContent" +
                                                                  tab.section,
                                                                "tabContentGroup" +
                                                                  groupIndex
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                tab.label
                                                              ) +
                                                              " "
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "flag",
                                                            },
                                                            [
                                                              _c("b-icon", {
                                                                attrs: {
                                                                  icon: "circle-fill",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tab-content-group",
                                                    attrs: {
                                                      id:
                                                        "tabContentGroup" +
                                                        groupIndex,
                                                    },
                                                  },
                                                  [
                                                    _vm._l(
                                                      group.sections,
                                                      function (tab, tabIndex) {
                                                        return _vm._l(
                                                          _vm.$parent
                                                            .sectionConfigs
                                                            .sections,
                                                          function (
                                                            config,
                                                            index
                                                          ) {
                                                            return config[
                                                              "section id"
                                                            ] == tab.section
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "tab-content-wrapper",
                                                                    class:
                                                                      tabIndex ==
                                                                      0
                                                                        ? ""
                                                                        : "hidden-content",
                                                                    attrs: {
                                                                      id:
                                                                        "tabContent" +
                                                                        tab.section,
                                                                    },
                                                                  },
                                                                  [
                                                                    !config[
                                                                      "is summary section"
                                                                    ]
                                                                      ? _c(
                                                                          "research-section",
                                                                          {
                                                                            key: index,
                                                                            attrs:
                                                                              {
                                                                                sectionIndex:
                                                                                  "section-" +
                                                                                  index,
                                                                                uId: _vm
                                                                                  .$parent
                                                                                  .uid,
                                                                                sectionConfig:
                                                                                  config,
                                                                                description:
                                                                                  !!_vm
                                                                                    .$parent
                                                                                    .sectionDescriptions
                                                                                    ? _vm
                                                                                        .$parent
                                                                                        .sectionDescriptions[
                                                                                        config[
                                                                                          "section id"
                                                                                        ]
                                                                                      ]
                                                                                    : _vm
                                                                                        .$parent
                                                                                        .initialDescriptions[
                                                                                        config[
                                                                                          "section id"
                                                                                        ]
                                                                                      ],
                                                                                phenotypeMap:
                                                                                  _vm
                                                                                    .$parent
                                                                                    .phenotypeMap,
                                                                                phenotypesInUse:
                                                                                  _vm
                                                                                    .$parent
                                                                                    .phenotypesInSession,
                                                                                colors:
                                                                                  _vm
                                                                                    .$parent
                                                                                    .colors,
                                                                                plotMargin:
                                                                                  _vm
                                                                                    .$parent
                                                                                    .plotMargin,
                                                                                plotLegend:
                                                                                  _vm
                                                                                    .$parent
                                                                                    .multiPlotLegends,
                                                                                tableLegend:
                                                                                  _vm
                                                                                    .$parent
                                                                                    .multiTableLegends,
                                                                                utils:
                                                                                  _vm
                                                                                    .$parent
                                                                                    .utilsBox,
                                                                                starItems:
                                                                                  _vm
                                                                                    .$parent
                                                                                    .starItems,
                                                                                regionZoom:
                                                                                  _vm
                                                                                    .$parent
                                                                                    .regionZoom,
                                                                                regionViewArea:
                                                                                  _vm
                                                                                    .$parent
                                                                                    .regionViewArea,
                                                                                isInTab: true,
                                                                                pageParams:
                                                                                  _vm
                                                                                    .$parent
                                                                                    .pageParams,
                                                                              },
                                                                            on: {
                                                                              "on-star":
                                                                                _vm
                                                                                  .$parent
                                                                                  .starColumn,
                                                                              "on-sectionData":
                                                                                _vm
                                                                                  .$parent
                                                                                  .onSectionsData,
                                                                              "on-zoom":
                                                                                _vm
                                                                                  .$parent
                                                                                  .setZoom,
                                                                            },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                    !!config[
                                                                      "is summary section"
                                                                    ]
                                                                      ? _c(
                                                                          "research-sections-summary",
                                                                          {
                                                                            key: index,
                                                                            attrs:
                                                                              {
                                                                                sectionIndex:
                                                                                  "section-" +
                                                                                  index,
                                                                                uId: _vm
                                                                                  .$parent
                                                                                  .uid,
                                                                                sectionsConfig:
                                                                                  config,
                                                                                description:
                                                                                  !!_vm
                                                                                    .$parent
                                                                                    .sectionDescriptions
                                                                                    ? _vm
                                                                                        .$parent
                                                                                        .sectionDescriptions[
                                                                                        config[
                                                                                          "section id"
                                                                                        ]
                                                                                      ]
                                                                                    : _vm
                                                                                        .$parent
                                                                                        .initialDescriptions[
                                                                                        config[
                                                                                          "section id"
                                                                                        ]
                                                                                      ],
                                                                                sectionsData:
                                                                                  _vm
                                                                                    .$parent
                                                                                    .sectionsData,
                                                                                utils:
                                                                                  _vm
                                                                                    .$parent
                                                                                    .utilsBox,
                                                                                starItems:
                                                                                  _vm
                                                                                    .$parent
                                                                                    .starItems,
                                                                                regionZoom:
                                                                                  _vm
                                                                                    .$parent
                                                                                    .regionZoom,
                                                                                regionViewArea:
                                                                                  _vm
                                                                                    .$parent
                                                                                    .regionViewArea,
                                                                                isInTab: true,
                                                                              },
                                                                            on: {
                                                                              "on-star":
                                                                                _vm
                                                                                  .$parent
                                                                                  .starColumn,
                                                                              "on-zoom":
                                                                                _vm
                                                                                  .$parent
                                                                                  .setZoom,
                                                                            },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e()
                                                          }
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ]
                                        : _vm._e()
                                    }
                                  ),
                                  _vm._l(
                                    _vm.$parent.getSections(
                                      _vm.$parent.sectionConfigs.sections
                                    ),
                                    function (config, index) {
                                      return [
                                        _vm.$parent.isInTabGroups(
                                          config["section id"]
                                        ) == false &&
                                        !config["is summary section"]
                                          ? _c("research-section", {
                                              key: index,
                                              attrs: {
                                                sectionIndex:
                                                  "section-" + index,
                                                uId: _vm.$parent.uid,
                                                sectionConfig: config,
                                                description: !!_vm.$parent
                                                  .sectionDescriptions
                                                  ? _vm.$parent
                                                      .sectionDescriptions[
                                                      config["section id"]
                                                    ]
                                                  : _vm.$parent
                                                      .initialDescriptions[
                                                      config["section id"]
                                                    ],
                                                phenotypeMap:
                                                  _vm.$parent.phenotypeMap,
                                                phenotypesInUse:
                                                  _vm.$parent
                                                    .phenotypesInSession,
                                                colors: _vm.$parent.colors,
                                                plotMargin:
                                                  _vm.$parent.plotMargin,
                                                plotLegend:
                                                  _vm.$parent.plotLegend,
                                                tableLegend:
                                                  _vm.$parent.tableLegend,
                                                utils: _vm.$parent.utilsBox,
                                                starItems:
                                                  _vm.$parent.starItems,
                                                regionZoom:
                                                  _vm.$parent.regionZoom,
                                                regionViewArea:
                                                  _vm.$parent.regionViewArea,
                                                pageParams:
                                                  _vm.$parent.pageParams,
                                              },
                                              on: {
                                                "on-star":
                                                  _vm.$parent.starColumn,
                                                "on-sectionData":
                                                  _vm.$parent.onSectionsData,
                                                "on-zoom": _vm.$parent.setZoom,
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.$parent.isInTabGroups(
                                          config["section id"]
                                        ) == false &&
                                        !!config["is summary section"]
                                          ? _c("research-sections-summary", {
                                              key: index,
                                              attrs: {
                                                sectionIndex:
                                                  "section-" + index,
                                                uId: _vm.$parent.uid,
                                                sectionsConfig: config,
                                                description: !!_vm.$parent
                                                  .sectionDescriptions
                                                  ? _vm.$parent
                                                      .sectionDescriptions[
                                                      config["section id"]
                                                    ]
                                                  : _vm.$parent
                                                      .initialDescriptions[
                                                      config["section id"]
                                                    ],
                                                sectionsData:
                                                  _vm.$parent.sectionsData,
                                                utils: _vm.$parent.utilsBox,
                                                starItems:
                                                  _vm.$parent.starItems,
                                                regionZoom:
                                                  _vm.$parent.regionZoom,
                                                regionViewArea:
                                                  _vm.$parent.regionViewArea,
                                              },
                                              on: {
                                                "on-star":
                                                  _vm.$parent.starColumn,
                                                "on-zoom": _vm.$parent.setZoom,
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  ),
                  _vm.$parent.isLandingPage != true &&
                  _vm.$parent.researchMethodID != null
                    ? _c(
                        "div",
                        {
                          staticClass: "kp-tab-content",
                          attrs: { id: "research_method_content" },
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", {
                              staticClass: "col-md-12",
                              domProps: {
                                innerHTML: _vm._s(_vm.$parent.researchMethod),
                              },
                            }),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.$parent.dataPoints != false &&
                  _vm.$parent.dataType != "direct_csv" &&
                  _vm.$parent.dataType != "direct_json"
                    ? _c("div", {
                        staticClass: "data-loading-indicator",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$store.state.initialSearch == 1 &&
                              _vm.$parent.isAPI == true
                              ? "Start search"
                              : "Loading data..."
                          ),
                        },
                      })
                    : _vm._e(),
                ]
              ),
            ]
          )
        : _vm._e(),
      !!_vm.$parent.researchDataEmpty && _vm.$parent.researchDataEmpty == true
        ? _c(
            "div",
            { staticClass: "no-data-warning", attrs: { id: "noDataWarning" } },
            [
              _vm._v(
                " No data is available for the last search. Please try a new search. "
              ),
            ]
          )
        : _vm._e(),
      _c("div", {
        staticStyle: { display: "none" },
        attrs: { id: "plotLegend" },
        domProps: { innerHTML: _vm._s(_vm.$parent.plotLegend) },
      }),
      _c("div", {
        staticStyle: { display: "none" },
        attrs: { id: "tableLegend" },
        domProps: { innerHTML: _vm._s(_vm.$parent.tableLegend) },
      }),
      _vm.$parent.displayOnKP == null ? _c("research-page-footer") : _vm._e(),
      _vm.$parent.displayOnKP == true
        ? _c("page-footer", {
            attrs: { "disease-group": _vm.$parent.diseaseGroup },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card mdkp-card dataset-page-header" }, [
      _c("div", { staticClass: "row card-body" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("h3", [
            _vm._v(
              " Sorry, this page is not published yet. Please come back later. "
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Section / Parameters")]),
        _c("th", [_vm._v("Save section data in CSV")]),
        _c("th", [_vm._v("Save section data in JSON")]),
        _c("th", [_vm._v("Remove section data")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }