var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "byor-single-search-wrapper" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.singleSearchParam,
          expression: "singleSearchParam",
        },
      ],
      staticClass: "form-control byor-single-search",
      attrs: {
        type: "text",
        id: "byor_single_search",
        placeholder:
          !!_vm.singleSearchConfig &&
          !!_vm.singleSearchConfig["search instruction"]
            ? _vm.singleSearchConfig["search instruction"]
            : "Search gene, variant, region, phenotype, or tissue",
      },
      domProps: { value: _vm.singleSearchParam },
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          return _vm.onSearch.apply(null, arguments)
        },
        input: function ($event) {
          if ($event.target.composing) return
          _vm.singleSearchParam = $event.target.value
        },
      },
    }),
    !!_vm.singleSearchParam
      ? _c(
          "span",
          {
            staticClass: "btn btn-default reset-search",
            on: {
              click: function ($event) {
                return _vm.resetSearch()
              },
            },
          },
          [_c("b-icon", { attrs: { icon: "x-circle-fill" } })],
          1
        )
      : _vm._e(),
    !!_vm.singleSearchConfig
      ? _c("div", { staticClass: "byor-single-search-results-wrapper" }, [
          _vm.anyResults() > 0
            ? _c(
                "div",
                {
                  staticClass: "byor-single-search-results",
                  attrs: { id: "byor_single_search_results" },
                },
                [
                  _vm._l(_vm.singleSearchResult.genes, function (gene) {
                    return _c(
                      "div",
                      { key: gene, staticClass: "single-search-option" },
                      [
                        _vm._v(" " + _vm._s(gene)),
                        _c("span", { staticClass: "search-word-group" }, [
                          !!_vm.isParameterActive("kp genes").active
                            ? _c(
                                "a",
                                {
                                  staticClass: "search-gene-link",
                                  attrs: { href: "javascript:;" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.searchGene(gene)
                                    },
                                  },
                                },
                                [
                                  _vm._v(_vm._s("Search gene")),
                                  _c("span", { staticClass: "gene-link-tip" }, [
                                    _vm._v(
                                      "Alias names are converted to gene symbols"
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          !!_vm.isParameterActive("kp region").active
                            ? _c("span", [_vm._v("|")])
                            : _vm._e(),
                          !!_vm.isParameterActive("kp region").active
                            ? _c(
                                "a",
                                {
                                  attrs: { href: "javascript:;" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.searchRegion(gene)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s("Search region"))]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    )
                  }),
                  !!_vm.isParameterActive("kp phenotypes").active
                    ? _vm._l(
                        _vm.singleSearchResult.phenotypes,
                        function (phenotype) {
                          return _c(
                            "div",
                            {
                              key: phenotype.name,
                              staticClass: "single-search-option",
                              attrs: { value: phenotype.name },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      _vm.isParameterActive("kp phenotypes")
                                        .url + phenotype.name,
                                  },
                                },
                                [_vm._v(_vm._s(phenotype.description))]
                              ),
                              _c("span", { staticClass: "search-word-group" }, [
                                _vm._v(_vm._s(phenotype.group)),
                              ]),
                            ]
                          )
                        }
                      )
                    : _vm._e(),
                  _vm._l(
                    _vm.singleSearchConfig["search parameters"],
                    function (param) {
                      return [
                        !param.values ||
                        (!!param.values &&
                          param.values != "kp genes" &&
                          param.values != "kp phenotypes")
                          ? [
                              !!_vm.isParameterActive(param["parameter"]).active
                                ? _vm._l(
                                    _vm.singleSearchResult[param["parameter"]],
                                    function (item) {
                                      return _c(
                                        "div",
                                        {
                                          key: item.value,
                                          staticClass: "single-search-option",
                                          attrs: { value: item.value },
                                        },
                                        [
                                          _vm._v(_vm._s(item.label) + " "),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "search-word-group",
                                              attrs: {
                                                href:
                                                  _vm.isParameterActive(
                                                    param["parameter"]
                                                  ).url + item.value,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  "Search " + param["parameter"]
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ]
                    }
                  ),
                ],
                2
              )
            : _vm._e(),
        ])
      : _vm._e(),
    !_vm.singleSearchConfig
      ? _c("div", { staticClass: "byor-single-search-results-wrapper" }, [
          _vm.singleSearchResult.genes.length > 0 ||
          _vm.singleSearchResult.phenotypes.length > 0 ||
          _vm.singleSearchResult.tissues.length > 0
            ? _c(
                "div",
                {
                  staticClass: "byor-single-search-results",
                  attrs: { id: "byor_single_search_results" },
                },
                [
                  _vm._l(_vm.singleSearchResult.genes, function (gene) {
                    return _c(
                      "div",
                      { key: gene, staticClass: "single-search-option" },
                      [
                        _vm._v(" " + _vm._s(gene)),
                        _c("span", { staticClass: "search-word-group" }, [
                          _c(
                            "a",
                            {
                              staticClass: "search-gene-link",
                              attrs: { href: "javascript:;" },
                              on: {
                                click: function ($event) {
                                  return _vm.searchGene(gene)
                                },
                              },
                            },
                            [
                              _vm._v(_vm._s("Search gene")),
                              _c("span", { staticClass: "gene-link-tip" }, [
                                _vm._v(
                                  "Alias names are converted to gene symbols"
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" | "),
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:;" },
                              on: {
                                click: function ($event) {
                                  return _vm.searchRegion(gene)
                                },
                              },
                            },
                            [_vm._v(_vm._s("Search region"))]
                          ),
                        ]),
                      ]
                    )
                  }),
                  _vm._l(_vm.singleSearchResult.tissues, function (tissue) {
                    return _c(
                      "div",
                      {
                        key: tissue.value,
                        staticClass: "single-search-option",
                        attrs: { value: tissue.value },
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "/tissue.html?tissue=" + tissue.value,
                            },
                          },
                          [_vm._v(_vm._s(tissue.label))]
                        ),
                        _c("span", { staticClass: "search-word-group" }, [
                          _vm._v(_vm._s("Tissue")),
                        ]),
                      ]
                    )
                  }),
                  _vm._l(
                    _vm.singleSearchResult.phenotypes,
                    function (phenotype) {
                      return _c(
                        "div",
                        {
                          key: phenotype.name,
                          staticClass: "single-search-option",
                          attrs: { value: phenotype.name },
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/phenotype.html?phenotype=" + phenotype.name,
                              },
                            },
                            [_vm._v(_vm._s(phenotype.description))]
                          ),
                          _c("span", { staticClass: "search-word-group" }, [
                            _vm._v(_vm._s(phenotype.group)),
                          ]),
                        ]
                      )
                    }
                  ),
                ],
                2
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }