var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.page != "front"
      ? _c("div", { staticClass: "disease-systems-sub-pages" }, [
          _c("div", { staticClass: "select-disease-wrapper" }, [
            !!_vm.diseaseInSession && _vm.diseaseInSession != ""
              ? _c("label", [_vm._v(_vm._s(_vm.diseaseInSession))])
              : _vm._e(),
            !_vm.diseaseInSession || _vm.diseaseInSession == ""
              ? _c("label", [_vm._v("Set focus")])
              : _vm._e(),
            _c("div", { staticClass: "select-disease" }, [
              _c("span", { staticClass: "menu-arrow" }, [_vm._v(">")]),
              _c("div", { staticClass: "options-wrapper" }, [
                _c(
                  "div",
                  {
                    staticClass: "option",
                    on: {
                      click: function ($event) {
                        return _vm.openPhenotypesBuilder("system")
                      },
                    },
                  },
                  [_vm._v(" By organ system ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "option",
                    on: {
                      click: function ($event) {
                        return _vm.openPhenotypesBuilder("disease")
                      },
                    },
                  },
                  [_vm._v(" By disease ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "option",
                    on: {
                      click: function ($event) {
                        return _vm.openPhenotypesBuilder("group")
                      },
                    },
                  },
                  [_vm._v(" By Phenotype groups ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "option",
                    on: {
                      click: function ($event) {
                        return _vm.openPhenotypesBuilder("correlation")
                      },
                    },
                  },
                  [_vm._v(" By Phenotype correlation ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "option reset",
                    on: {
                      click: function ($event) {
                        return _vm.resetCustomPhenotypes()
                      },
                    },
                  },
                  [_vm._v(" Remove focus ")]
                ),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm.page == "front"
      ? _c("div", { staticClass: "row disease-systems-front" }, [
          _c(
            "div",
            { staticClass: "disease-systems-tree col-md-12" },
            [
              _vm._l(_vm.diseaseSystems, function (system) {
                return [
                  _c(
                    "div",
                    { key: system, staticClass: "disease-system col-md-2" },
                    [
                      _c("img", {
                        staticClass: "disease-systems-icon",
                        attrs: {
                          src:
                            "https://kp4cd.org/sites/default/files/images/disease_systems/" +
                            system.toLowerCase().split(" ")[0] +
                            ".svg",
                        },
                      }),
                      _c("div", [_vm._v(_vm._s(system))]),
                      _c(
                        "div",
                        {
                          staticClass: "disease-system-options",
                          attrs: { id: system.split(" ")[0] + "_options" },
                        },
                        [
                          _c("h6", [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:;" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openPhenotypesBuilder("system", {
                                      system: system,
                                    })
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    "Select " + system.toLowerCase() + " system"
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _c("h6", [_vm._v("Or select by disease")]),
                          _vm._l(
                            _vm.diseaseOptions(system),
                            function (disease) {
                              return _c(
                                "div",
                                { key: disease, staticClass: "disease-name" },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:;" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openPhenotypesBuilder(
                                            "disease",
                                            {
                                              system: system,
                                              disease: disease,
                                            }
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(disease))]
                                  ),
                                ]
                              )
                            }
                          ),
                          _c("p"),
                          _vm.kpDiseasePair(system).length > 0
                            ? _c("h6", [
                                _vm._v(" Or visit a community portal "),
                              ])
                            : _vm._e(),
                          _vm._l(_vm.kpDiseasePair(system), function (kp) {
                            return _c(
                              "div",
                              { key: kp.name, staticClass: "community-portal" },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.communityPortalLink(kp.name),
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          "https://kp4cd.org/sites/default/files/images/disease_systems/" +
                                          kp.name +
                                          "kp.svg",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
                ]
              }),
              _c("div", { staticClass: "disease-system col-md-2" }, [
                _c("img", {
                  staticClass: "disease-systems-icon",
                  attrs: {
                    src: "https://kp4cd.org/sites/default/files/images/disease_systems/correlation.svg",
                  },
                }),
                _c("div", { staticClass: "disease-system-options" }, [
                  _c("h6", [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function ($event) {
                            return _vm.openPhenotypesBuilder("group")
                          },
                        },
                      },
                      [_vm._v("By Phenotype group ")]
                    ),
                  ]),
                  _c("h6", [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function ($event) {
                            return _vm.openPhenotypesBuilder("correlation")
                          },
                        },
                      },
                      [_vm._v("By Phenotype Correlation ")]
                    ),
                  ]),
                ]),
                _c("span", [_vm._v("By Phenotype")]),
              ]),
              _c(
                "button",
                {
                  class: !this.$store.state.diseaseInSession
                    ? "hidden"
                    : "btn btn-sm btn-warning reset-button",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.resetCustomPhenotypes()
                    },
                  },
                },
                [_vm._v(" Remove focus ")]
              ),
            ],
            2
          ),
        ])
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "custom-phenotypes-list-builder hidden",
        attrs: { id: "pheno_list_builder" },
      },
      [
        _vm.focusBy == "system"
          ? _c(
              "div",
              {
                staticClass: "ph-builder-filters-wrapper filtering-ui-wrapper",
              },
              [
                _c("div", { staticClass: "filtering-ui-content" }, [
                  _c("div", { staticClass: "col filter-col-md" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v("Select disease system"),
                    ]),
                    _c(
                      "select",
                      {
                        staticClass:
                          "select-disease form-control form-control-sm",
                        on: {
                          change: function ($event) {
                            return _vm.openPhenotypesBuilder(
                              "system",
                              null,
                              $event
                            )
                          },
                        },
                      },
                      [
                        !_vm.diseaseSystems.includes(_vm.selectedDisease)
                          ? _c(
                              "option",
                              {
                                staticClass: "disease-name",
                                attrs: { value: "" },
                              },
                              [_vm._v(" Select one ")]
                            )
                          : _vm._e(),
                        _vm._l(_vm.diseaseSystems, function (system) {
                          return [
                            _c(
                              "option",
                              {
                                staticClass: "disease-name",
                                domProps: {
                                  value: system,
                                  selected:
                                    system == _vm.selectedDisease
                                      ? true
                                      : false,
                                },
                              },
                              [_vm._v(" " + _vm._s(system) + " ")]
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
        _vm.focusBy == "disease"
          ? _c(
              "div",
              {
                staticClass: "ph-builder-filters-wrapper filtering-ui-wrapper",
              },
              [
                _c("div", { staticClass: "filtering-ui-content" }, [
                  _c("div", { staticClass: "col filter-col-md" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v("Select disease system"),
                    ]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.PBuilderDSystem,
                            expression: "PBuilderDSystem",
                          },
                        ],
                        staticClass:
                          "select-disease form-control form-control-sm",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.PBuilderDSystem = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          { staticClass: "disease-name", attrs: { value: "" } },
                          [_vm._v(" Select one ")]
                        ),
                        _vm._l(_vm.diseaseSystems, function (system) {
                          return [
                            _c(
                              "option",
                              {
                                staticClass: "disease-name",
                                domProps: { value: system },
                              },
                              [_vm._v(" " + _vm._s(system) + " ")]
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c("div", { staticClass: "col filter-col-md" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v("Select disease"),
                    ]),
                    _c(
                      "select",
                      {
                        staticClass:
                          "select-disease form-control form-control-sm",
                        on: {
                          change: function ($event) {
                            return _vm.openPhenotypesBuilder(
                              "disease",
                              null,
                              $event
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          { staticClass: "disease-name", attrs: { value: "" } },
                          [_vm._v(" Select one ")]
                        ),
                        _vm._l(
                          _vm.diseaseOptions(_vm.PBuilderDSystem),
                          function (disease) {
                            return _c(
                              "option",
                              {
                                key: disease,
                                staticClass: "disease-name",
                                domProps: {
                                  value: disease,
                                  selected:
                                    disease == _vm.selectedDisease
                                      ? true
                                      : false,
                                },
                              },
                              [_vm._v(" " + _vm._s(disease) + " ")]
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
        _vm.focusBy == "group"
          ? _c(
              "div",
              {
                staticClass: "ph-builder-filters-wrapper filtering-ui-wrapper",
              },
              [
                _vm._m(0),
                _vm._l(_vm.phenotypGroups, function (group) {
                  return _c(
                    "div",
                    { key: group, staticClass: "ph-group-option" },
                    [
                      _c("input", {
                        attrs: { type: "checkbox" },
                        domProps: {
                          value: group,
                          checked: !!_vm.selectedGroups.includes(group),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.addRemovePhenotypeGroup(group)
                          },
                        },
                      }),
                      _vm._v(" " + _vm._s(group) + " "),
                    ]
                  )
                }),
              ],
              2
            )
          : _vm._e(),
        _vm.focusBy == "system" ||
        _vm.focusBy == "disease" ||
        _vm.focusBy == "group"
          ? [
              _c("strong", { staticClass: "number-of-phenotypes" }, [
                _vm._v(
                  "Number of phenotypes: " + _vm._s(_vm.getPhenotypes().length)
                ),
              ]),
              _c("div", { staticClass: "table-wrapper", class: _vm.focusBy }, [
                _c("table", { staticClass: "table table-striped table-sm" }, [
                  _vm._m(1),
                  _c(
                    "tbody",
                    _vm._l(_vm.getPhenotypes(), function (phenotype) {
                      return _c("tr", [
                        _c("td", [
                          _c("input", {
                            staticClass: "phenotype-chkbox",
                            attrs: { type: "checkbox", checked: "" },
                            domProps: { value: phenotype.name },
                          }),
                        ]),
                        _c("td", { staticClass: "phenotype-name" }, [
                          _vm._v(" " + _vm._s(phenotype.description) + " "),
                        ]),
                        _c("td", { staticClass: "phenotype-group" }, [
                          _vm._v(" " + _vm._s(phenotype.group) + " "),
                        ]),
                        _c("td", { staticClass: "phenotype-dichotomous" }, [
                          _vm._v(" " + _vm._s(phenotype.dichotomous) + " "),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
              _c("div", { staticClass: "session-info" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-sm",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.saveCustomPhenotypes(_vm.focusBy, "set")
                      },
                    },
                  },
                  [_vm._v(" Set focus ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning btn-sm",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.closePhenotypesBuilder()
                      },
                    },
                  },
                  [_vm._v(" Cancel ")]
                ),
              ]),
            ]
          : _vm._e(),
        _vm.focusBy == "correlation"
          ? _c(
              "div",
              {
                staticClass: "ph-builder-filters-wrapper filtering-ui-wrapper",
              },
              [
                _c("div", { staticClass: "filtering-ui-content" }, [
                  _c(
                    "div",
                    { staticClass: "col filter-col-md" },
                    [
                      _c("div", { staticClass: "label" }, [
                        _vm._v("Select phenotype"),
                      ]),
                      !!_vm.phenotypes
                        ? _c("phenotype-selectpicker", {
                            attrs: {
                              phenotypes: _vm.phenotypes,
                              clearOnSelected: true,
                              useInLocal: true,
                              localPlace: "diseaseSystems",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "col filter-col-sm" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v("P-Value(<=)"),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.pCorPValue,
                          expression: "pCorPValue",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.pCorPValue },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.pCorPValue = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "col filter-col-sm" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v("Correlation(>=)"),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.pCorCorrelation,
                          expression: "pCorCorrelation",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.pCorCorrelation },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.pCorCorrelation = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "col filter-col-sm" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("Sort by")]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.pCorDirection,
                            expression: "pCorDirection",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.pCorDirection = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "pValue" } }, [
                          _vm._v("P-Value"),
                        ]),
                        _c("option", { attrs: { value: "rg" } }, [
                          _vm._v("Correlation"),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
        _vm.focusBy == "correlation" && !!_vm.correlatedPhenotypes
          ? _c("strong", { staticClass: "number-of-phenotypes" }, [
              _vm._v(
                "Number of phenotypes: " +
                  _vm._s(_vm.correlatedPhenotypes.length)
              ),
            ])
          : _vm._e(),
        _vm.focusBy == "correlation" && !!_vm.correlatedPhenotypes
          ? _c("div", { staticClass: "table-wrapper" }, [
              _c("table", { staticClass: "table table-striped table-sm" }, [
                _vm._m(2),
                _c(
                  "tbody",
                  _vm._l(_vm.correlatedPhenotypes, function (phenotype) {
                    return _c(
                      "tr",
                      [
                        !!_vm.phenotypeNames[phenotype.other_phenotype]
                          ? [
                              _c("td", [
                                _c("input", {
                                  staticClass: "phenotype-chkbox",
                                  attrs: { type: "checkbox", checked: "" },
                                  domProps: {
                                    value: phenotype.other_phenotype,
                                  },
                                }),
                              ]),
                              _c("td", { staticClass: "phenotype-name" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.phenotypeNames[
                                        phenotype.other_phenotype
                                      ]
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("td", { staticClass: "phenotype-name" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatValue(
                                        "pValueFormatter",
                                        phenotype.pValue
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("td", { staticClass: "phenotype-name" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatValue(
                                        "pValueFormatter",
                                        phenotype.rg
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("td", { staticClass: "phenotype-name" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatValue(
                                        "pValueFormatter",
                                        phenotype.stdErr
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]),
            ])
          : _vm._e(),
        _vm.focusBy == "correlation"
          ? _c("div", { staticClass: "session-info" }, [
              !!_vm.correlatedPhenotypes
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-sm",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.saveCustomPhenotypes("correlation", "set")
                        },
                      },
                    },
                    [_vm._v(" Set focus ")]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "btn btn-warning btn-sm",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.closePhenotypesBuilder()
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
            ])
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [_c("strong", [_vm._v("Select phenotype groups")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _c("th", [_vm._v("Phenotype")]),
        _c("th", [_vm._v("Group")]),
        _c("th", [_vm._v("Dichotomous")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _c("th", [_vm._v("Phenotype")]),
        _c("th", [_vm._v("P-Value")]),
        _c("th", [_vm._v("Correlation")]),
        _c("th", [_vm._v("Standard error")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }