var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(_vm.currentComponent, {
        tag: "component",
        attrs: {
          phenotypesInUse: _vm.phenotypesInUse,
          utilsBox: _vm.utilsBox,
          sectionConfigs: _vm.sectionConfigs,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }