var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", {
    staticClass: "col-md-12 page-description-content",
    domProps: { innerHTML: _vm._s(_vm.pageContent) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }