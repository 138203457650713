var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticStyle: {
        display: "flex",
        "flex-direction": "column",
        width: "min-content",
        position: "relative",
      },
    },
    [
      _c("strong", [_vm._v(_vm._s(_vm.title))]),
      _vm.points
        ? _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "justify-content": "flex-end",
                gap: "5px",
                position: "absolute",
                right: "5px",
                top: "5px",
                "z-index": "1",
              },
            },
            [
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showLabels = !_vm.showLabels
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      staticStyle: { width: "20px" },
                      attrs: {
                        viewBox: "0 0 50 50",
                        xmlns: "http://www.w3.org/2000/svg",
                        "xml:space": "preserve",
                        transform: "rotate(270)",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M24.896 9.463a.997.997 0 0 0-.707-.293l-12.957-.001a1 1 0 0 0-1 .996l-.046 13.005a.998.998 0 0 0 .293.711l16.995 16.995a.997.997 0 0 0 1.414 0l13.004-13.004a.999.999 0 0 0 0-1.414L24.896 9.463zm3.285 29.292L12.188 22.761l.041-11.592 11.547.001 15.995 15.995-11.59 11.59z",
                        },
                      }),
                      _c("circle", {
                        attrs: { cx: "20.362", cy: "19.346", r: "2.61" },
                      }),
                    ]
                  ),
                ]
              ),
              _c("button", { on: { click: _vm.resetPanZoom } }, [
                _c(
                  "svg",
                  {
                    staticStyle: { width: "18px" },
                    attrs: {
                      viewBox: "0 0 32 32",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M6 12H4V4h8v2H6v6zM28 12h-2V6h-6V4h8v8zM12 28H4v-8h2v6h6v2zM28 28h-8v-2h6v-6h2v8zM15 10h2v4h-2zM10 15h4v2h-4zM18 15h4v2h-4zM15 18h2v4h-2z",
                      },
                    }),
                    _c("path", { attrs: { fill: "none", d: "M0 0h32v32H0z" } }),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "umap-wrap", style: `min-width:${_vm.width}px;` },
        [
          !_vm.points || _vm.isLoading
            ? _c("div", { staticClass: "umap-overlay" }, [
                _vm._v(
                  " " + _vm._s(_vm.isLoading ? "Loading" : "No data") + " "
                ),
              ])
            : _vm._e(),
          _c("canvas", { ref: "umapCanvas", staticClass: "umap" }),
          _c("canvas", {
            ref: "umapCanvasLabels",
            staticClass: "umap",
            on: {
              wheel: _vm.handleWheel,
              mousedown: _vm.startPan,
              mousemove: _vm.handleMouseMove,
              mouseup: _vm.endPan,
              mouseout: _vm.umapUnHover,
              mouseleave: _vm.endPan,
            },
          }),
          _c("div", { ref: "umapTooltip", staticClass: "tooltip" }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }