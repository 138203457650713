var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "research-data-table-wrapper" },
    [
      _c("div", {
        staticClass: "data-table-legend",
        domProps: { innerHTML: _vm._s(_vm.tableLegend) },
      }),
      !!_vm.dataset
        ? _c("div", {
            staticClass: "table-total-rows",
            domProps: { innerHTML: _vm._s("Total rows: " + this.rows) },
          })
        : _vm._e(),
      !!_vm.searchParameters &&
      _vm.dataComparisonConfig != null &&
      _vm.compareGroups.length > 1
        ? _c(
            "div",
            { staticClass: "table-total-rows" },
            _vm._l(_vm.compareGroups, function (item, itemIndex) {
              return _c("span", {
                key: item + itemIndex,
                class: "group-item-bubble reference bg-color-" + itemIndex,
                domProps: { innerHTML: _vm._s(item) },
              })
            }),
            0
          )
        : _vm._e(),
      _c("div", { staticClass: "table-ui-wrapper" }, [
        _c("label", [
          _vm._v("Compare annotated regions: "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filterTissueType,
                  expression: "filterTissueType",
                },
              ],
              staticClass: "number-per-page",
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.filterTissueType = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            [
              _c("option", { attrs: { value: "or" } }, [_vm._v("Or")]),
              _c("option", { attrs: { value: "and" } }, [_vm._v("And")]),
            ]
          ),
        ]),
        _c("label", [
          _vm._v("Rows per page: "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.perPageNumber,
                  expression: "perPageNumber",
                },
              ],
              staticClass: "number-per-page",
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.perPageNumber = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            [
              _c("option", { attrs: { value: "10" } }, [_vm._v("10")]),
              _c("option", { attrs: { value: "20" } }, [_vm._v("20")]),
              _c("option", { attrs: { value: "40" } }, [_vm._v("40")]),
              _c("option", { attrs: { value: "100" } }, [_vm._v("100")]),
              _vm._m(0),
            ]
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "convert-2-csv btn-sm",
            on: {
              click: function ($event) {
                return _vm.convertJson2Csv(
                  _vm.rawData,
                  _vm.pageID + "_filtered"
                )
              },
            },
          },
          [_vm._v(" Save as CSV ")]
        ),
        _c(
          "div",
          {
            staticClass: "convert-2-csv btn-sm",
            on: {
              click: function ($event) {
                return _vm.saveJson(_vm.rawData, _vm.pageID + "_filtered")
              },
            },
          },
          [_vm._v(" Save as JSON ")]
        ),
        _c(
          "div",
          {
            staticClass: "convert-2-csv btn-sm",
            on: {
              click: function ($event) {
                return _vm.showHidePanel("#showHideColumnsBox")
              },
            },
          },
          [_vm._v(" show/hide columns ")]
        ),
        !!_vm.newTableFormat
          ? _c(
              "div",
              { staticClass: "hidden", attrs: { id: "showHideColumnsBox" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "show-hide-columns-box-close",
                    on: {
                      click: function ($event) {
                        return _vm.showHidePanel("#showHideColumnsBox")
                      },
                    },
                  },
                  [_c("b-icon", { attrs: { icon: "x-circle-fill" } })],
                  1
                ),
                _c("h4", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("Show/hide columns"),
                ]),
                _c("p"),
                _c("div", { staticClass: "table-wrapper" }, [
                  _c("table", { staticClass: "table table-sm" }, [
                    _vm._m(1),
                    _c(
                      "tbody",
                      _vm._l(_vm.newTableFormat["top rows"], function (column) {
                        return _c("tr", { key: column }, [
                          _c("td", [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                name: "visible_top_rows",
                                id: _vm.getColumnId(column),
                                checked: "",
                              },
                              domProps: { value: column },
                              on: {
                                click: function ($event) {
                                  return _vm.addRemoveColumn($event)
                                },
                              },
                            }),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  column == "Credible Set"
                                    ? " PPA"
                                    : " " + column
                                ),
                              },
                            }),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
      ]),
      !!_vm.dataset && !!_vm.newTableFormat
        ? _c(
            "table",
            {
              class: "table table-sm research-data-table " + _vm.pageID,
              attrs: { cellpadding: "0", cellspacing: "0" },
            },
            [
              _c("thead", {}, [
                _c(
                  "tr",
                  [
                    !!_vm.newTableFormat["star column"]
                      ? _c(
                          "th",
                          [
                            _c("b-icon", {
                              staticStyle: {
                                color: "#ffcc00",
                                cursor: "pointer",
                              },
                              attrs: {
                                icon: !!_vm.stared ? "star-fill" : "star",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.showHideStared()
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._l(_vm.topRows, function (value, index) {
                      return [
                        _vm.getIfChecked(value) == true
                          ? _c(
                              "th",
                              {
                                key: index,
                                staticClass: "byor-tooltip",
                                class:
                                  !!_vm.tableFormat["top rows"].includes(
                                    value
                                  ) || value == "Credible Set"
                                    ? "sortable-th " +
                                      value +
                                      " " +
                                      _vm.getColumnId(value)
                                    : "" + _vm.getColumnId(value),
                                on: {
                                  click: function ($event) {
                                    !!_vm.tableFormat["top rows"].includes(
                                      value
                                    ) || value == "Credible Set"
                                      ? _vm.applySorting(value)
                                      : ""
                                  },
                                },
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      value == "Credible Set" ? "PPA" : value
                                    ),
                                  },
                                }),
                                !!_vm.tableFormat["tool tips"] &&
                                !!_vm.tableFormat["tool tips"][value]
                                  ? _c("span", {
                                      staticClass: "tooltiptext",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.tableFormat["tool tips"][value]
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }),
                    _vm.newTableFormat["features"] != undefined
                      ? _c("th", { staticClass: "th-evidence" }, [
                          _vm._v(" Evidence "),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
              _vm._l(_vm.pagedData, function (value, index) {
                return _c("tbody", { key: index }, [
                  _c(
                    "tr",
                    [
                      !!_vm.newTableFormat["star column"]
                        ? _c("td", [
                            _vm.checkStared(value) == false
                              ? _c(
                                  "span",
                                  [
                                    _c("b-icon", {
                                      staticStyle: {
                                        color: "#aaaaaa",
                                        cursor: "pointer",
                                      },
                                      attrs: { icon: "star" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addStar(value)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.checkStared(value) == true
                              ? _c(
                                  "span",
                                  [
                                    _c("b-icon", {
                                      staticStyle: {
                                        color: "#ffcc00",
                                        cursor: "pointer",
                                      },
                                      attrs: { icon: "star-fill" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeStar(value)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._l(value, function (tdValue, tdKey) {
                        return _vm.topRows.includes(tdKey)
                          ? [
                              _vm.ifDataObject(tdValue) == false &&
                              _vm.getIfChecked(tdKey) == true
                                ? _c("td", {
                                    key: tdKey,
                                    class: _vm.getColumnId(tdKey),
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.formatValue(tdValue, tdKey)
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              _vm.ifDataObject(tdValue) == true &&
                              _vm.getIfChecked(tdKey) == true
                                ? _c(
                                    "td",
                                    {
                                      key: tdKey,
                                      staticClass: "multi-value-td",
                                      class: _vm.getColumnId(tdKey),
                                    },
                                    _vm._l(
                                      tdValue,
                                      function (sValue, sKey, sIndex) {
                                        return _c("span", {
                                          key: sKey,
                                          class:
                                            sKey +
                                            " reference bg-color-" +
                                            _vm.getColorIndex(sKey),
                                          style:
                                            "height:" +
                                            100 / Object.keys(tdValue).length +
                                            "%;",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.formatValue(sValue, tdKey)
                                            ),
                                          },
                                        })
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e()
                      }),
                      _vm.newTableFormat["features"] != undefined
                        ? _c("td", [
                            _c(
                              "span",
                              {
                                staticClass: "show-evidence-btn btn",
                                attrs: { href: "javascript:;" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showHideFeature(
                                      "feature_" + index
                                    )
                                  },
                                },
                              },
                              [_vm._v("View")]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm.newTableFormat["features"] != undefined
                    ? _c(
                        "tr",
                        { class: "hidden", attrs: { id: "feature_" + index } },
                        [
                          _c(
                            "td",
                            {
                              staticClass: "features-td",
                              attrs: { colspan: _vm.topRowNumber },
                            },
                            [
                              _c("research-gem-table-features", {
                                attrs: {
                                  featuresData: value.features,
                                  featuresFormat: _vm.newTableFormat,
                                  utils: _vm.utils,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ])
              }),
            ],
            2
          )
        : _vm._e(),
      !!_vm.perPageNumber && _vm.perPageNumber != null && _vm.perPageNumber != 0
        ? _c(
            "b-container",
            { staticClass: "egl-table-page-ui-wrapper" },
            [
              _c("b-pagination", {
                staticClass: "pagination-sm justify-content-center",
                attrs: {
                  "total-rows": _vm.rows,
                  "per-page": _vm.perPageNumber,
                },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("option", { attrs: { value: "0" } }, [
      _c("span", { staticStyle: { color: "#f00" } }, [_vm._v("All")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("thead", [_c("tr", [_c("th")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }