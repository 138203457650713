<template>
    <div class="kc-home flex-layout-styles">
        <div class="logo f-row">
            <img
                src="https://hugeampkpncms.org/sites/default/files/users/user32/cfde_kc_logo_c.svg"
            />
            <span style="font-size: 16px"
                ><strong style="color: #f26822">The Knowledge Center</strong>
                <strong
                    >integrates data and knowledge generated by ground-breaking
                    research programs in the NIH Common Fund Data
                    Ecosystem.</strong
                >
                Use the KC to see curated and precomputed analyses of data
                within and across Common Fund projects.
            </span>
        </div>
        <div class="search-wrapper f-col align-h-center">
            <h1 class="kc">Search Common Fund Knowledge</h1>
            <div class="f-col fill-width">
                <research-single-search-cfde
                    :single-search-config="sectionConfigs['content']"
                    :phenotypes="phenotypesInUse"
                    :utils="utilsBox"
                >
                </research-single-search-cfde>
                <div
                    v-if="sectionConfigs['content']['search examples']"
                    class="search-extras f-row"
                >
                    <div class="f-row" style="gap: 5px">
                        Try
                        <template
                            v-for="example in sectionConfigs['content'][
                                'search examples'
                            ]"
                        >
                            <a :href="example.url">{{ example.value }}</a>
                        </template>
                    </div>
                </div>
            </div>
            <div class="map-info">
                <div class="f-col">
                    <div class="map-info-title"></div>
                    <div class="map-info-subtitle"></div>
                    <div class="map-info-body"></div>
                </div>
            </div>
        </div>
        <div class="home-section-container">
            <h3 class="section-title">Learn</h3>
            <div class="hero-wrapper f-col" v-if="this.parsedData">
                <div class="hero-q" style="top: 0px" @click="this.toggleCFinfo">
                    What is the Common Fund?
                </div>
                <div class="cf-intro f-col align-h-center">
                    <h2 class="kc">
                        {{ Object.keys(this.parsedData.map).length }} Common
                        Fund Programs
                    </h2>
                    <div>
                        Conducting groundbreaking research across diverse fields
                    </div>
                </div>
                <div class="cf-info" style="display: none">
                    <h2 class="kc">The Common Fund</h2>
                    <div>
                        <b>The Common Fund (CF)</b> is a program within the
                        National Institutes of Health (NIH) designed to support
                        innovative, high-impact research that transcends the
                        mission of any single NIH institute or center.
                        <a href="https://commonfund.nih.gov/" target="_blank"
                            >Learn more.</a
                        >
                    </div>
                </div>
                <div class="cfp-info f-col fill-width">
                    <div class="dcc-info" style="display: none">
                        <h3 class="kc">
                            Common Fund Programs and Data Coordinating Centers
                        </h3>
                        <div>
                            <b>CF Programs</b> are large-scale, trans-NIH
                            research initiatives designed to address significant
                            biomedical challenges that no single NIH institute
                            or center can tackle alone.
                        </div>
                        <div>
                            <b>Data Coordinating Centers (DCCs)</b> are focused
                            on the management, integration, and dissemination of
                            data generated by CF Programs.
                        </div>
                    </div>
                    <div class="dcc-icons-contain">
                        <div class="dcc-icons f-row">
                            <template
                                v-for="(value, key, index) in this.parsedData
                                    .map"
                            >
                                <div
                                    class="dcc-icon"
                                    data-group="dccs"
                                    :data-value="key"
                                    @mouseover="hoverHandler($event)"
                                    @mouseout="outHandler($event)"
                                    @click="clickDcc($event)"
                                >
                                    <img :src="value['logo']" />
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <!--<div class="paths"></div>-->
                <div class="cfde-info f-col fill-width align-h-center">
                    <div class="arrw">➤</div>
                    <div class="hero-q" @click="this.toggleCFDEinfo">
                        What is the CDFE?
                    </div>
                    <div class="cfde-title f-col align-h-center">
                        <h2 class="kc">1 Data Ecosystem</h2>
                        <div>
                            Allowing researchers to easily find, access, and
                            integrate Common Fund datasets
                        </div>
                    </div>
                    <div class="what-cfde-info" style="display: none">
                        <h3 class="kc">Common Fund Data Ecosystem</h3>
                        <div>
                            <b>The Common Fund Data Ecosystem (CFDE)</b> is an
                            initiative under the NIH Common Fund that aims to
                            enhance the accessibility, interoperability, and
                            usability of data generated by Common Fund
                            programs.<br />
                            <a href="https://info.cfde.cloud" target="_blank"
                                >Learn more about the data within the CFDE.</a
                            >
                        </div>
                    </div>
                    <div class="f-col" style="gap: 20px; width: 100%">
                        <div class="eco-row f-col">
                            <div class="eco-title">Omics</div>
                            <div
                                class="omics f-row fill-width spread-out"
                                style="gap: 5px"
                            >
                                <div
                                    v-for="(value, key) in this.parsedData.sets[
                                        'omics'
                                    ]"
                                    class="omic"
                                    data-group="omics"
                                    :data-value="value"
                                    @mouseover="hoverHandler2($event)"
                                    @mouseout="outHandler($event)"
                                >
                                    <span>{{ value }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="eco-row f-col">
                            <div class="eco-title">Entities</div>
                            <div
                                class="entities f-row fill-width spread-out"
                                style="gap: 5px"
                            >
                                <div
                                    v-for="(value, key) in this.parsedData.sets[
                                        'entities'
                                    ]"
                                    class="entity"
                                    data-group="entities"
                                    :data-value="value"
                                    @mouseover="hoverHandler2($event)"
                                    @mouseout="outHandler($event)"
                                >
                                    <span>{{ value }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="home-section-container">
            <h3 class="section-title">Browse</h3>
            <div class="home-section-wrap">
                <h3 class="kc">Examples of CFDE Knowledge</h3>
                <div>
                    Secondary analyses created by The Knowledge Center using
                    Common Fund data
                </div>
                <div class="home-section">
                    <template v-for="example in this.examplesData">
                        <div class="example-item f-col" style="gap: 20px">
                            <div class="f-row" style="gap: 20px">
                                <div
                                    class="f-col"
                                    style="width: 40%; gap: 20px"
                                >
                                    <div>
                                        <div class="example-item-type">
                                            Analysis
                                        </div>
                                        <div class="example-item-analysis">
                                            {{ example.analysis }}
                                        </div>
                                    </div>
                                    <div>
                                        <div class="example-item-type">
                                            Program
                                        </div>
                                        <div
                                            class="f-row align-v-center"
                                            style="gap: 10px"
                                        >
                                            <div class="example-item-logo">
                                                <img :src="example.logo" />
                                            </div>
                                            <div class="example-item-name">
                                                {{ example.name }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="f-col"
                                    style="width: calc(60% - 20px)"
                                >
                                    <div
                                        class="example-item-image f-col align-v-center"
                                    >
                                        <img :src="example.example" />
                                    </div>
                                </div>
                            </div>
                            <div class="f-row" style="align-self: flex-end">
                                <div class="example-item-analysis">
                                    See on
                                    <a
                                        :href="`/r/kc_entity_${example.type.toLowerCase()}?entity=${example.type.toLowerCase()}&${example.type.toLowerCase()}=${
                                            example.entity
                                        }`"
                                        >{{ example.type }}</a
                                    >
                                    page
                                </div>
                            </div>
                        </div>
                    </template>
                    <div
                        class="spotlight-prev"
                        data-dir="prev"
                        @click="this.changeExample"
                    >
                        <svg
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="#7c7c7c"
                            stroke-width="1.2"
                        >
                            <path
                                d="M5.707 9.71a1 1 0 0 0 0 1.415l4.892 4.887a2 2 0 0 0 2.828 0l4.89-4.89a1 1 0 1 0-1.414-1.415l-4.185 4.186a1 1 0 0 1-1.415 0L7.121 9.71a1 1 0 0 0-1.414 0Z"
                                fill="#7c7c7c"
                            />
                        </svg>
                    </div>
                    <div
                        class="spotlight-next"
                        data-dir="next"
                        @click="this.changeExample"
                    >
                        <svg
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="#7c7c7c"
                            stroke-width="1.2"
                        >
                            <path
                                d="M5.707 9.71a1 1 0 0 0 0 1.415l4.892 4.887a2 2 0 0 0 2.828 0l4.89-4.89a1 1 0 1 0-1.414-1.415l-4.185 4.186a1 1 0 0 1-1.415 0L7.121 9.71a1 1 0 0 0-1.414 0Z"
                                fill="#7c7c7c"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>

        <div class="home-section-container">
            <h3 class="section-title">Analyze</h3>
            <div class="home-section-wrap">
                <h3 class="kc">Knowledge Center Workflows</h3>
                <div class="home-section f-row">
                    <div class="f-col">
                        <div class="analysis-figure">
                            <img
                                src="https://hugeampkpncms.org/sites/default/files/users/user32/kc_icons/gene_set.png"
                            />
                        </div>
                        <h2>Gene Set Analysis</h2>
                        <div>
                            Uncover gene set patterns across integrated Common
                            Fund programs—designed for advanced cross-dataset
                            exploration.
                        </div>
                        <a
                            href="/r/kc_gene_set_browser_source?source=all"
                            style="align-self: flex-end"
                            >Start here</a
                        >
                    </div>
                    <div class="f-col">
                        <div class="analysis-figure">
                            <img
                                src="https://hugeampkpncms.org/sites/default/files/users/user32/kc_icons/dge.png"
                            />
                        </div>
                        <h2>Differential Expression Analysis</h2>
                        <div>
                            Compare differentially expressed genes across
                            tissues and diseases, driving insights into gene
                            regulation and disease mechanisms.
                        </div>
                        <a
                            href="/r/kc_dge_top_20?q=1&source=kc_diffexp"
                            style="align-self: flex-end"
                            >Start here</a
                        >
                    </div>
                </div>
            </div>
        </div>

        <div class="home-section-container">
            <h3 class="section-title">More</h3>
            <div class="home-section-wrap" v-if="this.parsedData">
                <h3 class="kc">Common Fund Program Spotlight</h3>
                <div class="home-section">
                    <template v-for="(value, key) in this.parsedData.map">
                        <div class="spotlight-item">
                            <div class="spotlight-logo f-row">
                                <img :src="value.logo" />
                            </div>
                            <div class="f-col fill-width">
                                <div class="spotlight-text f-col fill-height">
                                    <h3>{{ value.name }}</h3>
                                    <div v-html="value.spotlight"></div>
                                </div>
                                <a
                                    style="align-self: flex-end"
                                    :href="`/r/kc_programs?DCC=${key}`"
                                    >Learn More</a
                                >
                            </div>
                        </div>
                    </template>
                    <div
                        class="spotlight-prev"
                        data-dir="prev"
                        @click="this.changeSpotlight"
                    >
                        <svg
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="#7c7c7c"
                            stroke-width="1.2"
                        >
                            <path
                                d="M5.707 9.71a1 1 0 0 0 0 1.415l4.892 4.887a2 2 0 0 0 2.828 0l4.89-4.89a1 1 0 1 0-1.414-1.415l-4.185 4.186a1 1 0 0 1-1.415 0L7.121 9.71a1 1 0 0 0-1.414 0Z"
                                fill="#7c7c7c"
                            />
                        </svg>
                    </div>
                    <div
                        class="spotlight-next"
                        data-dir="next"
                        @click="this.changeSpotlight"
                    >
                        <svg
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="#7c7c7c"
                            stroke-width="1.2"
                        >
                            <path
                                d="M5.707 9.71a1 1 0 0 0 0 1.415l4.892 4.887a2 2 0 0 0 2.828 0l4.89-4.89a1 1 0 1 0-1.414-1.415l-4.185 4.186a1 1 0 0 1-1.415 0L7.121 9.71a1 1 0 0 0-1.414 0Z"
                                fill="#7c7c7c"
                            />
                        </svg>
                    </div>
                </div>
            </div>
            <div
                class="home-section-wrap"
                style="margin: 40px"
                v-if="this.parsedData"
            >
                <h3 class="kc">CFDE Workbench</h3>
                <div class="home-section">
                    <div class="f-row">
                        <div class="drc-logo">
                            <img
                                src="https://hugeampkpncms.org/sites/default/files/users/user32/kc_icons/DRC_logo.png"
                            />
                        </div>
                        <div class="f-col">
                            <div>
                                Visit our sister resource to query, access, and
                                compute Common Fund datasets.
                            </div>
                            <ul>
                                <li>
                                    Search across all Common Fund metadata and
                                    processed data.
                                </li>
                                <li>
                                    Cloud tools to interrogate data sets from
                                    various Common Fund programs.
                                </li>
                                <li>
                                    Training and outreach to highlight Common
                                    Fund data and how to use it effectively.
                                </li>
                            </ul>
                            <div class="drc-link">
                                <a
                                    href="https://data.cfde.cloud/"
                                    target="_blank"
                                    >Visit CFDE Workbench</a
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="f-tooltip top"
            style="top: 0; left: 50px; width: 200px; height: 100px"
        ></div>
        <div
            class="f-tooltip right"
            style="top: 0; left: 300px; width: 200px; height: 100px"
        ></div>
        <div
            class="f-tooltip bottom"
            style="top: 0; left: 550px; width: 200px; height: 100px"
        ></div>
        <div
            class="f-tooltip left"
            style="top: 0; left: 800px; width: 200px; height: 100px"
        ></div>
    </div>
</template>

<script>
import Vue from "vue";
import { BootstrapVueIcons } from "bootstrap-vue";

Vue.use(BootstrapVueIcons);

export default Vue.component("cfde-landing", {
    props: ["sectionConfigs", "phenotypesInUse", "utilsBox", "keyParams"],
    data() {
        return {
            parsedData: null,
            examplesData: null,
            currPov: null,
            currHoverGroup: null,
            freezeSelection: false,
            cfInfoToggle: false,
            cfdeInfoToggle: false,
            lines: [],
            sectionTitles: [],
            spotlightInterval: null,
            examplesInterval: null,
            currSpotlight: -1,
            currExample: -1,
        };
    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
        window.addEventListener("scroll", this.highlightTopmostTitle);
        this.init();
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
        window.addEventListener("scroll", this.highlightTopmostTitle);
    },
    computed: {},
    watch: {},
    updated() {},
    methods: {
        async init() {
            const data = await this.loadFile(
                this.sectionConfigs["content"]["custom"]["viz data"]
            );
            //this.parsedData = await this.parseEntities(data);
            //console.log('parsedData', this.parsedData);
            const jsonData = await JSON.parse(data);
            this.parsedData = this.parseData(jsonData);
            this.examplesData = this.parseExamples(jsonData);
            console.log("examples", this.examplesData);
            setTimeout(() => {
                const edges = this.getNodePoints();
                this.renderEdges(edges);
                this.changeExample(null);
                this.changeSpotlight(null);
                this.spotlightInterval = setInterval(() => {
                    this.changeSpotlight(null);
                }, 10000);
                this.examplesInterval = setInterval(() => {
                    this.changeExample(null);
                }, 10000);
                setTimeout(() => {
                    this.simulateMouseOverSequence();
                }, 400);
            }, 100);
            this.sectionTitles = document.querySelectorAll(".section-title");
            this.highlightTopmostTitle();
        },
        async loadFile(src) {
            //must use dataset endpoint to load csv file from hugeampkpncms (otherwise CORS error)
            const fetchUrl =
                "https://hugeampkpncms.org/servedata/dataset?dataset=" + src;
            //however, dataset enpoint parses the original csv and escapes special characters
            let data = await fetch(fetchUrl).then((resp) => {
                return resp.json();
                //return resp.text(fetchUrl)
            });

            return data;

            //we must undo all of that to get the original data
            data = data.replace(/\\u0022/g, '"'); //quotes
            data = data.replace(/\\\//g, "/"); //slashes
            data = data.replace(/\\n/g, "\n"); //line breaks
            data = data.replace(/\\r/g, "\r"); //carriage returns
            data = data.substring(1, data.length - 1); //remove surrounding quotes
            return data;
        },
        parseData(data) {
            console.log("parseData", data);
            const map = {};
            const sets = {
                programs: new Set(),
                resources: new Set(),
                omics: new Set(),
                entities: new Set(),
            };

            Object.entries(data).forEach((dccItem) => {
                console.log("--", dccItem);
                const dcc = dccItem[0];

                // add to map
                map[dcc] = {
                    logo: dccItem[1]["basic"]["logo"] || "",
                    name: dccItem[1]["basic"]["name"] || "",
                    omics: dccItem[1]["omics"] || [],
                    entities: dccItem[1]["entities"] || [],
                    info: dccItem[1]["basic"]["short description"] || "",
                    spotlight: dccItem[1]["basic"]["spotlight"] || "",
                };

                // add to sets (ensure uniqueness)
                sets.programs.add(dcc);
                (dccItem[1]["omics"] || []).forEach((omic) =>
                    sets.omics.add(omic)
                );
                (dccItem[1]["entities"] || []).forEach((entity) =>
                    sets.entities.add(entity)
                );
            });

            // sets to arrays
            const setsAsArrays = {
                programs: Array.from(sets.programs),
                omics: Array.from(sets.omics),
                entities: Array.from(sets.entities),
            };

            console.log({ map, sets: setsAsArrays });

            return { map, sets: setsAsArrays };

            //return { map, sets: setsAsArrays };
        },
        parseExamples(data) {
            const examples = [];
            Object.entries(data).forEach((dccItem) => {
                const dcc = dccItem[0];

                if (
                    dccItem[1]["examples"]["gene_analysis"] != "" &&
                    dccItem[1]["examples"]["gene_example"] != ""
                ) {
                    examples.push({
                        type: "gene",
                        logo: dccItem[1]["basic"]["logo"] || "",
                        name: dccItem[1]["basic"]["name"] || "",
                        analysis: dccItem[1]["examples"]["gene_analysis"],
                        example: dccItem[1]["examples"]["gene_example"],
                        entity: dccItem[1]["examples"]["example_gene"],
                    });
                }
                if (
                    dccItem[1]["examples"]["disease_analysis"] != "" &&
                    dccItem[1]["examples"]["disease_example"] != ""
                ) {
                    examples.push({
                        type: "disease",
                        logo: dccItem[1]["basic"]["logo"] || "",
                        name: dccItem[1]["basic"]["name"] || "",
                        analysis: dccItem[1]["examples"]["disease_analysis"],
                        example: dccItem[1]["examples"]["disease_example"],
                        entity: dccItem[1]["examples"]["example_disease"],
                    });
                }
            });

            return this.shuffleArray(examples);
        },
        shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]]; // Swap elements
            }
            return array;
        },
        async parseEntities(data) {
            const lines = data.split("\n");
            const headers = lines[0].split(",");
            const result = {};

            for (let i = 1; i < lines.length; i++) {
                const values = lines[i].match(
                    /(".*?"|[^",\r\n]+|(?<=,)(?=,))/g
                ); //.split(',');
                const dcc = values[0];
                result[dcc] = {
                    entities: {},
                    omics: {},
                    resources: {},
                    povs: {},
                    logo: "",
                    info: {},
                };

                for (let j = 1; j < headers.length; j++) {
                    const [prefix, key] = headers[j].split("_");
                    if (prefix && key) {
                        let group;
                        if (prefix === "a") {
                            group = "entities";
                        } else if (prefix === "b") {
                            group = "omics";
                        } else if (prefix === "c") {
                            group = "povs";
                        } else if (prefix === "d") {
                            group = "logo";
                            result[dcc][group] = values[j];
                            continue;
                        } else if (prefix === "e") {
                            group = "resources";
                        } else if (prefix === "r") {
                            group = "info";
                            result[dcc][group][key.trim()] = values[j];
                            continue;
                        }
                        result[dcc][group][key.trim()] = values[j]
                            ? parseInt(values[j])
                            : null;
                    }
                }
            }

            return result;
            //this.parsedData = result;
            //console.log(this.parsedData);
        },
        drawEdge(from, to, thickness, value) {
            let nodeConnectOffset = 20;

            //start and end point offsets
            let startX = from.x;
            let endX = to.x;
            let startY = from.y;
            let endY = to.y;

            let curveDirX = startX < endX ? -1 : 1;
            let curveDirY = startY < endY ? -1 : 1;
            const curveSize = startX === endX ? 0 : nodeConnectOffset;

            //if the endX point is more left than startX point
            //swap them
            if (to.x < from.x) {
                startX = to.x;
                endX = from.x;
                curveDirX = endX < startX ? -1 : 1;
            }

            startX -= thickness;
            endX += thickness;

            // Create an SVG element
            const svg = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "svg"
            );
            const svgWidth = Math.abs(startX - endX);
            const svgHeight = Math.abs(startY - endY);
            svg.style.position = "absolute";
            svg.style.left = startX + "px";
            svg.style.top = startY + "px";
            svg.style.width = svgWidth + "px";
            svg.style.height = svgHeight + "px";
            svg.setAttribute("viewBox", `0 0 ${svgWidth} ${svgHeight}`);
            svg.classList.add("line-svg");
            document.body.append(svg);

            //create the path element
            const path = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "path"
            );
            let sX = thickness;
            let eX = endX - startX - thickness;
            let sY = 0;
            let eY = endY - startY;
            let midY = (eY - sY) / 2;

            if (to.x < from.x) {
                sX = endX - startX - thickness;
                eX = thickness;
            }

            // Draw path
            let d = `M ${sX} ${sY}`;
            d += ` V ${midY - curveSize}`;
            d += ` Q ${sX} ${midY}, ${sX - curveSize * curveDirX} ${midY}`;
            d += ` H ${eX + curveSize * curveDirX}`;
            d += ` Q ${eX} ${midY}, ${eX} ${midY - curveSize * curveDirY}`;
            d += ` V ${eY}`;

            // Set path attributes
            path.setAttribute("data-value", value);
            path.setAttribute("d", d);
            path.setAttribute("stroke", "#f26822");
            path.setAttribute("fill", "transparent");
            path.setAttribute("stroke-width", thickness);

            svg.appendChild(path);

            this.lines.push(path);
        },
        renderEdges(edges) {
            document.querySelectorAll(".line-svg").forEach((el) => {
                el.remove();
            });
            edges.forEach((edge) => {
                this.drawEdge(edge.from_point, edge.to_point, 1, edge.value);
            });
            document.querySelector(".arrw").style.display = "block";
        },
        getNodePoints() {
            const from_nodes = document.querySelectorAll(".dcc-icon");
            const to_node = document.querySelector(".cfde-info");

            function getBottomCenter(element) {
                const rect = element.getBoundingClientRect();
                return {
                    x: rect.left + rect.width / 2,
                    y: rect.bottom + window.scrollY,
                };
            }

            function getTopCenter(element) {
                const rect = element.getBoundingClientRect();
                return {
                    x: rect.left + rect.width / 2,
                    y: rect.top + window.scrollY,
                };
            }

            const to_point = getTopCenter(to_node);

            const edges = Array.from(from_nodes).map((from_node) => ({
                value: from_node.dataset.value,
                from_point: getBottomCenter(from_node),
                to_point: to_point,
            }));

            return edges;
        },
        handleResize() {
            const edges = this.getNodePoints();
            this.renderEdges(edges);
        },
        toggleCFinfo(e) {
            if (!this.cfInfoToggle) {
                e.target.innerHTML = "close ✖";
                document.querySelector(".cf-intro").style.display = "none";
                //document.querySelector('.cfp-info').classList.add('active');
                document.querySelector(".cf-info").style.display = "block";
                document.querySelector(".dcc-info").style.display = "block";
                this.cfInfoToggle = true;
            } else {
                e.target.innerHTML = "What is the Common Fund?";
                document.querySelector(".cf-intro").style.display = "flex";
                //document.querySelector('.cfp-info').classList.remove('active');
                document.querySelector(".cf-info").style.display = "none";
                document.querySelector(".dcc-info").style.display = "none";
                this.cfInfoToggle = false;
            }
            this.handleResize();
        },
        toggleCFDEinfo(e) {
            if (!this.cfdeInfoToggle) {
                e.target.innerHTML = "close ✖";
                document.querySelector(".cfde-title").style.display = "none";
                document.querySelector(".what-cfde-info").style.display =
                    "block";
                this.cfdeInfoToggle = true;
            } else {
                e.target.innerHTML = "What is the CFDE?";
                document.querySelector(".cfde-title").style.display = "flex";
                document.querySelector(".what-cfde-info").style.display =
                    "none";
                this.cfdeInfoToggle = false;
            }
            this.handleResize();
        },
        clickDcc(e) {
            const val = e.target.dataset.value;
            window.location.href = `/r/kc_programs?DCC=${val}`;
        },
        outHandler(e) {
            if (this.freezeSelection) {
                document
                    .querySelectorAll(`[data-group]`)
                    .forEach((item) =>
                        item.classList.remove("on", "on2", "on3")
                    );
                const targetElement = document.querySelector(
                    `[data-value="${this.currPov}"]`
                );
                if (targetElement)
                    targetElement.dispatchEvent(new Event("mouseover"));
            } else {
                document
                    .querySelectorAll(`[data-group]`)
                    .forEach((item) =>
                        item.classList.remove(
                            "on",
                            "on2",
                            "on3",
                            "onA",
                            "on2A",
                            "on3A"
                        )
                    );
                this.lines.forEach((line) => {
                    line.setAttribute("stroke", "#f26822");
                    line.parentNode.style.zIndex = 0;
                });
                document.querySelector(".map-info").style.opacity = 0;
            }
            this.currHoverGroup = null;
        },
        hoverHandler(e) {
            const val = e.target.dataset.value;

            this.lines.forEach((line) => {
                if (line.dataset.value !== val) {
                    line.setAttribute("stroke", "#ddd");
                } else {
                    line.parentNode.style.zIndex = 1;
                }
            });
            if (this.currHoverGroup !== "omics") {
                this.parsedData.map[val]["omics"].forEach((value) => {
                    if (value) {
                        document
                            .querySelector(`.omics [data-value="${value}"]`)
                            .classList.add("on");
                    }
                });
            }
            if (this.currHoverGroup !== "entities") {
                this.parsedData.map[val]["entities"].forEach((value) => {
                    if (value) {
                        document
                            .querySelector(`.entities [data-value="${value}"]`)
                            .classList.add("on");
                    }
                });
            }

            if (e.isTrusted) {
                document.querySelector(".map-info").style.opacity = 1;
                this.mapTooltip(
                    "Program",
                    this.parsedData.map[val].name,
                    this.parsedData.map[val].info
                );
            }
        },
        hoverHandler2(e) {
            clearTimeout(this.mapInfoTimeout);

            const group = e.target.dataset.group;
            let val = e.target.dataset.value;
            this.currHoverGroup = group;

            //un-hilight lines from DCCs
            this.lines.forEach((line) => {
                line.setAttribute("stroke", "#ddd");
            });

            for (const [key, value] of Object.entries(this.parsedData.map)) {
                const el = document.querySelector(`[data-value="${key}"]`);
                if (value[group].includes(val)) {
                    //highlight DCC icon
                    el.classList.add("on");
                    //hilighlight lines from DCC
                    const line = this.lines.find(
                        (element) => element.dataset.value === key
                    );
                    line.setAttribute("stroke", "#f26822");
                    line.parentNode.style.zIndex = 1;
                }
                //ununsed
                if (value[group][val] === 2) {
                    el.classList.add("on2");
                }
                if (value[group][val] === 3) {
                    el.classList.add("on3");
                }
            }
            /*
            if(e.isTrusted){
                document.querySelector('.map-info').style.opacity = 1;
                console.log(group, val, this.parsedData.map[val])
                this.mapTooltip(group, val, "");
            }
            */
        },
        mapTooltip(group, value, body) {
            document.querySelector(".map-info-title").innerHTML = group;
            document.querySelector(".map-info-subtitle").innerHTML = value;
            document.querySelector(".map-info-body").innerHTML = body;
        },
        triggerMouseEvent(element, eventType) {
            const event = new MouseEvent(eventType, {
                bubbles: true,
                cancelable: true,
                view: window,
            });
            element.dispatchEvent(event);
            //eventType === 'mouseover' ? element.classList.add('on') : element.classList.remove('on');
        },
        simulateMouseOverSequence() {
            const delay = 150;
            const elements = document.querySelectorAll(".dcc-icon");

            let lastEl = null;

            elements.forEach((element, index) => {
                setTimeout(() => {
                    if (lastEl) {
                        this.triggerMouseEvent(element, "mouseout");
                        element.classList.remove("on");
                    }
                    this.triggerMouseEvent(element, "mouseover");
                    element.classList.add("on");
                    lastEl = element;
                    if (index === elements.length - 1) {
                        setTimeout(() => {
                            this.triggerMouseEvent(element, "mouseout");
                            element.classList.remove("on");
                        }, delay);
                    }
                }, index * delay);
            });
        },
        changeSpotlight(e) {
            const randInt = (min, max) =>
                Math.floor(Math.random() * (max - min + 1)) + min;
            let currSpotlight = this.currSpotlight;
            const max = Object.keys(this.parsedData.map).length;
            let dir = "next";
            if (e?.target) {
                clearInterval(this.spotlightInterval);
                dir = e.target.dataset.dir;
            }
            if (currSpotlight > 0) {
                document
                    .querySelector(
                        `.spotlight-item:nth-of-type(${this.currSpotlight})`
                    )
                    .classList.remove("active");
            } else {
                currSpotlight = randInt(1, max);
            }
            if (dir === "next") {
                currSpotlight = currSpotlight >= max ? 1 : currSpotlight + 1;
            } else {
                currSpotlight = currSpotlight <= 1 ? max : currSpotlight - 1;
            }
            this.currSpotlight = currSpotlight;
            document
                .querySelector(
                    `.spotlight-item:nth-child(${this.currSpotlight})`
                )
                .classList.add("active");
        },
        changeExample(e) {
            console.log("example", this.currExample);
            const randInt = (min, max) =>
                Math.floor(Math.random() * (max - min + 1)) + min;
            let currExample = this.currExample;
            const max = this.examplesData.length;
            let dir = "next";
            if (e?.target) {
                clearInterval(this.examplesInterval);
                dir = e.target.dataset.dir;
            }
            if (currExample > 0) {
                document
                    .querySelector(
                        `.example-item:nth-of-type(${this.currExample})`
                    )
                    .classList.remove("active");
            } else {
                currExample = randInt(1, max);
            }
            if (dir === "next") {
                currExample = currExample >= max ? 1 : currExample + 1;
            } else {
                currExample = currExample <= 1 ? max : currExample - 1;
            }
            this.currExample = currExample;
            document
                .querySelector(`.example-item:nth-child(${this.currExample})`)
                .classList.add("active");
        },
        highlightSections() {
            const sectionTitles = document.querySelectorAll(".section-title");
            const observerOptions = {
                root: null, // Use the viewport as the root
                rootMargin: "-45% 0px -55% 0px", // Top and bottom margin to trigger halfway through
                threshold: 0, // Trigger as soon as the title touches the margin
            };

            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // Remove 'active' class from all titles
                        sectionTitles.forEach((title) =>
                            title.classList.remove("active")
                        );
                        // Add 'active' class to the current intersecting title
                        entry.target.classList.add("active");
                    }
                });
            }, observerOptions);

            // Observe each section title
            sectionTitles.forEach((title) => observer.observe(title));
        },
        highlightTopmostTitle() {
            let closestTitle = null;
            let closestDistance = Infinity;

            this.sectionTitles.forEach((title) => {
                const rect = title.getBoundingClientRect();
                const distanceFromTop = Math.abs(rect.top);

                if (rect.top >= 0 && distanceFromTop < closestDistance) {
                    closestDistance = distanceFromTop;
                    closestTitle = title;
                }
            });

            if (closestTitle) {
                this.sectionTitles.forEach((title) =>
                    title.classList.remove("active")
                );
                closestTitle.classList.add("active");
            }
        },
    },
});
</script>
<style scoped>
<style scoped > .line-svg {
    pointer-events: none;
}
.f-tooltip {
    position: absolute;
    background: white;
    border-radius: 10px;
    padding: 10px;
    z-index: 100;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: none;
}
.f-tooltip.top::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid white;
    position: absolute;
    left: 50%;
    top: -10px;
    transform: translateX(-50%);
}
.f-tooltip.right::after {
    content: "";
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 10px solid white;
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
}
.f-tooltip.bottom::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid white;
    position: absolute;
    left: 50%;
    bottom: -10px;
    transform: translateX(-50%);
}
.f-tooltip.left::after {
    content: "";
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 10px solid white;
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
}

.flex-layout-styles {
    /* LAYOUT STYLES */
    .no-events {
        pointer-events: none;
    }

    .f-col {
        display: flex;
        flex-direction: column;
    }

    .f-row {
        display: flex;
        flex-direction: row;
    }

    .f-col.align-v-center {
        justify-content: center;
    }

    .f-row.align-v-center {
        align-items: center;
    }

    .f-col.align-h-center {
        align-items: center;
    }

    .f-row.align-h-center {
        justify-content: center;
    }

    .f-col.align-v-bottom {
        justify-content: flex-end;
    }

    .f-row.align-v-bottom {
        align-items: flex-end;
    }

    .f-col.align-h-bottom {
        align-items: flex-end;
    }

    .f-row.align-h-bottom {
        justify-content: flex-end;
    }

    .f-col.spread-out,
    .f-row.spread-out {
        justify-content: space-between;
    }

    .fill-height {
        /*height: -moz-available;
        height: -webkit-fill-avaiilable;*/
        height: stretch;
        height: 100%;
    }

    .fill-width {
        /*width: -moz-available;
        width: -webkit-fill-avaiilable;*/
        width: stretch;
        width: 100%;
    }

    .hug-height {
        height: fit-content;
    }

    .hug-width {
        width: fit-content;
    }

    .grow-children > * {
        flex-grow: 1;
    }
}

.flex-layout-styles {
    /* LAYOUT STYLES */
    .no-events {
        pointer-events: none;
    }

    .f-col {
        display: flex;
        flex-direction: column;
    }

    .f-row {
        display: flex;
        flex-direction: row;
    }

    .f-col.align-v-center {
        justify-content: center;
    }

    .f-row.align-v-center {
        align-items: center;
    }

    .f-col.align-h-center {
        align-items: center;
    }

    .f-row.align-h-center {
        justify-content: center;
    }

    .f-col.align-v-bottom {
        justify-content: flex-end;
    }

    .f-row.align-v-bottom {
        align-items: flex-end;
    }

    .f-col.align-h-bottom {
        align-items: flex-end;
    }

    .f-row.align-h-bottom {
        justify-content: flex-end;
    }

    .f-col.spread-out,
    .f-row.spread-out {
        justify-content: space-between;
    }

    .fill-height {
        /*height: -moz-available;
        height: -webkit-fill-avaiilable;*/
        height: stretch;
        height: 100%;
    }

    .fill-width {
        /*width: -moz-available;
        width: -webkit-fill-avaiilable;*/
        width: stretch;
        width: 100%;
    }

    .hug-height {
        height: fit-content;
    }

    .hug-width {
        width: fit-content;
    }

    .grow-children > * {
        flex-grow: 1;
    }
}

.kc-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f7f6f6;
    margin: -30px;
    margin: -30px;
    padding: 15px;
    color: #7c7c7c;
    font-size: 16px !important;
    font-size: 16px !important;

    .map-info {
        opacity: 0;
        position: absolute;
        bottom: 0;
        background: white;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
            rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
        transition: 0.2s all;
        pointer-events: none;
        padding: 20px;
    }
    .map-info-title {
        font-size: 12px;
        text-transform: capitalize;
        line-height: 10px;
    }
    .map-info-subtitle {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: bold;
        color: #f26822;
    }
    .map-info-body {
        font-size: 14px;
    }

    h1 {
        font-size: 22px;
        font-weight: bold;
        line-height: 22px;
    }

    h2 {
        font-size: 18px;
        font-weight: bold;
        line-height: 18px;
    }

    h3 {
        font-size: 16px;
        font-weight: bold;
        line-height: 16px;
    }

    h1.kc,
    h2.kc,
    h3.kc {
        text-transform: uppercase;
        color: #f26822;
    }

    h1.kc {
        font-size: 22px;
        font-size: 22px;
    }

    h2.kc {
        font-size: 20px;
        font-size: 20px;
    }

    h3.kc {
        font-size: 18px;
        font-size: 18px;
    }

    .logo {
        width: 550px;
        margin-top: 25px;
        margin-bottom: 25px;
        line-height: 23px;
    }
    .logo img {
        width: 120px;
        margin: 0 20px 0 0;
    }

    a,
    a:visited {
        color: #f26822 !important;
    }

    .home-section-container {
        width: 100vw;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 40px 0;
        margin: 0px;
        position: relative;
        border-top: 1px solid #ddd;
    }
    .section-title {
        position: absolute;
        top: 20px;
        left: 20px;
        line-height: 40px;
        font-size: 40px !important;
        color: #ddd;
    }
    .section-title.active {
        color: #ccc;
    }

    .search-wrapper {
        width: 800px;
        margin: 10px 0 50px 0;
        gap: 10px;
        position: relative;
    }

    #byor_single_search {
        border-radius: 10px;
        padding: 25px;
        font-size: 16px;
    }

    .search-extras {
        gap: 5px;
        background: #e6e6e6;
        padding: 5px 25px;
        margin: 0 25px;
        border-radius: 0 0 10px 10px;
        font-size: 14px;
        font-size: 14px;
    }

    .search-extras a,
    .search-extras a:visited {
        color: #f26822 !important;
    }

    .hero-wrapper {
        width: 800px;
        /*background: #f8f6f6;*/
        padding: 0px;
        gap: 20px;
        position: relative;
        border-radius: 10px;
        align-items: center;
        align-items: center;
    }

    .cf-info {
        padding: 0px 0px 0px 0px;
    }

    .cfp-info {
        width: fit-content;
        padding: 0px 20px 20px 20px;
        gap: 30px;
    }

    .cfp-info.active {
        padding-top: 20px;
        background: #ebebeb;
    }

    .cfde-info {
        margin-top: 30px;
        padding: 20px 20px 20px 20px;
        gap: 20px;
        /*background: #ebebeb;*/
        position: relative;
        border-radius: 10px;
    }

    .dcc-icons-contain {
        position: relative;
        height: 70px;
    }

    .dcc-icons {
        gap: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .dcc-icon {
        width: 85px;
        width: 85px;
        aspect-ratio: 1;
        background: #ebebeb;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        transition: 0.15s all linear;
        cursor: pointer;
    }

    .dcc-icon img {
        width: 75%;
        pointer-events: none;
        mix-blend-mode: darken;
    }

    .dcc-icon.on,
    .dcc-icon:hover {
        /*outline: 2px solid #ff6600;*/
        background: #ff660050;
        transform: scale(1.1);
        /*border-bottom:3px solid #ff6600;*/
    }

    .eco-row {
        position: relative;
    }

    .eco-title {
        font-size: 12px;
        font-weight: bold;
        position: absolute;
        left: calc(100% + 25px);
        left: calc(100% + 25px);
        top: 50%;
        transform: translateY(-50%);
    }

    .resources,
    .omics,
    .entities {
        flex-wrap: wrap;
    }

    .resource,
    .omic,
    .entity {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        justify-content: center;
        aspect-ratio: unset;
        height: 20px;
        text-transform: uppercase;
        font-size: 11px;
        padding: 0 5px;
        letter-spacing: 1px;
        font-weight: 600;
        background: #ddd;
        filter: grayscale(1);
        cursor: default;
        transition: 0.15s all linear;
        white-space: nowrap;
    }

    .resource.on,
    .resource:hover,
    .omic.on,
    .omic:hover,
    .entity.on,
    .entity:hover {
        background: #ff802c;
        filter: none;
        color: white;
    }

    .resource span,
    .omic span,
    .entity span {
        pointer-events: none;
    }

    .paths {
        height: 50px;
    }

    .cfde-stats {
        gap: 5px;
    }

    .hero-q {
        align-self: flex-end;
        font-size: 12px;
        /*color: #f26822;*/
        font-weight: bold;
        position: absolute;
        top: 20px;
        left: 100%;
        margin: 0 -5px 0;
        white-space: nowrap;
        cursor: pointer;
        user-select: none;
        background: white;
        padding: 0 10px;
        border-radius: 10px;
    }

    .fold {
        margin: 30px 0;
    }

    .fold-arrow {
        line-height: 0;
        margin: -10px 0 0;
    }

    .fold-arrow svg {
        height: 30px;
    }

    .home-section-wrap {
        width: 800px;
        margin: 0px;
    }

    .home-section {
        background: #eee;
        padding: 20px;
        margin: 10px 0 0;
        gap: 10px;
        position: relative;
        border-radius: 10px;
    }

    .arrw {
        position: absolute;
        top: -10px;
        transform: rotate(90deg);
        display: none;
        font-size: 12px;
        align-self: center;
        color: #f26822;
    }

    .example-item {
        display: none;
        margin: 10px 0 0;
    }
    .example-item.active {
        display: flex;
    }
    .example-item a,
    .example-item a:visited {
        color: #f26822 !important;
    }
    .example-item-type {
        font-size: 12px;
        text-transform: capitalize;
        line-height: 10px;
        margin: 0 0 5px;
        font-weight: bold;
    }
    .example-item-name {
        font-size: 14px;
    }
    .example-item-logo {
        width: 100px;
    }
    .example-item-logo img {
        width: 100%;
        width: -webkit-fill-available;
    }
    .example-item-analysis {
        font-size: 16px;
        min-height: 50px;
    }
    .example-item-image {
        border-radius: 10px;
        height: 250px;
        background: white;
    }
    .example-item-image img {
        /*width: 100%;*/
    }

    .spotlight-item {
        display: none;
        gap: 20px;
        margin-top: 10px;
    }
    .spotlight-item.active {
        display: flex;
    }
    .spotlight-item a,
    .spotlight-item a:visited {
        color: #f26822 !important;
    }
    .spotlight-item a,
    .spotlight-item a:visited {
        color: #f26822 !important;
    }
    .spotlight-logo {
        min-width: 150px;
        width: 150px;
        align-self: flex-start;
        margin-top: 10px;
    }
    .spotlight-logo img {
        width: 100%;
        width: -webkit-fill-available;
        mix-blend-mode: darken;
    }
    .spotlight-text {
        height: 300px;
        overflow-y: auto;
        margin: 0 0 20px;
        padding: 10px;
        border-radius: 10px;
        box-shadow: inset 0 -40px 20px -20px #ddd;
    }

    .spotlight-prev,
    .spotlight-next {
        position: absolute;
        top: 50%;
        width: 30px;
        height: 30px;
        cursor: pointer;
    }
    .spotlight-prev svg,
    .spotlight-next svg {
        pointer-events: none;
    }
    .spotlight-prev {
        left: -30px;
        transform: translateY(-50%) rotate(90deg);
    }
    .spotlight-next {
        right: -30px;
        transform: translateY(-50%) rotate(-90deg);
    }

    .drc-logo {
        background: white;
        border-radius: 50%;
        padding: 10px;
        margin: 0 25px;
        width: 100px;
        height: 100px;
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
        align-self: center;
    }
    .drc-logo img {
        width: 100%;
        width: -webkit-fill-available;
    }
    .drc-link {
        text-align: right;
    }
    .drc-link a,
    .drc-link a:visited {
        color: #f26822 !important;
    }

    .analysis-figure {
        height: 150px;
        margin: 0 auto 20px auto;
    }
    .analysis-figure img {
        height: inherit;
    }
}
</style>
