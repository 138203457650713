var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return this.isLoading
    ? _c("div", { staticClass: "spinner-container" }, [
        _c("div", { staticClass: "loader", class: this.colorStyle }, [
          _vm._m(0),
          _vm._m(1),
          _vm._m(2),
          _vm._m(3),
          _vm._m(4),
          _vm._m(5),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "dot dot1" }, [_c("i")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "dot dot2" }, [_c("i")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "dot dot3" }, [_c("i")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "dot dot4" }, [_c("i")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "dot dot5" }, [_c("i")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "dot dot6" }, [_c("i")])
  },
]
render._withStripped = true

export { render, staticRenderFns }