var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "research-data-table-wrapper",
      class:
        !!_vm.tableFormat.display && _vm.tableFormat.display == "false"
          ? "hidden"
          : "",
    },
    [
      _c("div", { class: ["info-page-nav", _vm.openCard ? "open" : ""] }, [
        !!_vm.tableFormat["rows as info cards"]["minimum view"]
          ? _c(
              "div",
              {
                staticClass: "info-cards-back",
                on: {
                  click: function ($event) {
                    return _vm.hideFeatures()
                  },
                },
              },
              [_c("span", { staticClass: "btn" }, [_vm._v("❮ Back")])]
            )
          : _vm._e(),
        _c("div", { staticClass: "info-cards-help" }, [
          !!_vm.tableFormat["glossary"]
            ? _c("div", { staticClass: "info-cards-glossary" }, [_vm._m(0)])
            : _vm._e(),
          !!_vm.tableFormat["tour"]
            ? _c("div", { staticClass: "info-cards-tour" }, [_vm._m(1)])
            : _vm._e(),
        ]),
      ]),
      _c("div", {
        staticClass: "data-table-legend",
        domProps: { innerHTML: _vm._s(_vm.tableLegend) },
      }),
      !!_vm.searchParameters &&
      _vm.dataComparisonConfig != null &&
      _vm.compareGroups.length > 1
        ? _c(
            "div",
            { staticClass: "table-total-rows" },
            _vm._l(_vm.compareGroups, function (item, itemIndex) {
              return _c("span", {
                key: item,
                class: "group-item-bubble reference bg-color-" + itemIndex,
                domProps: { innerHTML: _vm._s(item) },
              })
            }),
            0
          )
        : _vm._e(),
      !!_vm.tableFormat["rows as info cards"]["minimum view"]
        ? _c(
            "div",
            { class: ["thumbnails-wrapper", _vm.openCard ? "" : "hidden"] },
            [
              _vm._l(_vm.rawData, function (value, index) {
                return [
                  _c(
                    "div",
                    {
                      class: [
                        "info-card",
                        _vm.openCard &&
                        _vm.openCard ==
                          value[_vm.tableFormat["rows as info cards"]["key"]]
                          ? "selected"
                          : "",
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.showHideFeature(
                            _vm.sectionId + index + _vm.featureKey,
                            value[_vm.tableFormat["rows as info cards"]["key"]]
                          )
                        },
                      },
                    },
                    _vm._l(
                      _vm.tableFormat["rows as info cards"]["minimum view"],
                      function (rowKey, rowIndex) {
                        return _c("div", {
                          key: index + "-" + rowIndex,
                          class: ["row-value", rowKey],
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.formatValue(value[rowKey], rowKey)
                            ),
                          },
                        })
                      }
                    ),
                    0
                  ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _c(
        "div",
        { class: ["details-wrapper", _vm.openCard ? "feature" : ""] },
        [
          _vm._l(_vm.rawData, function (value, index) {
            return [
              _c(
                "div",
                {
                  class: [
                    "info-card",
                    !_vm.openCard ||
                    _vm.openCard ==
                      value[_vm.tableFormat["rows as info cards"]["key"]]
                      ? ""
                      : "hidden",
                  ],
                },
                [
                  _c(
                    "div",
                    { staticClass: "info-card-header" },
                    [
                      _vm._l(
                        _vm.tableFormat["logo row"],
                        function (rowKey, rowIndex) {
                          return _c("div", {
                            key: index + "-" + rowIndex,
                            class: "row-value " + rowKey,
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.formatValue(value[rowKey], rowKey)
                              ),
                            },
                          })
                        }
                      ),
                      _c(
                        "div",
                        { staticClass: "info-card-header-extra" },
                        _vm._l(
                          _vm.tableFormat["title rows"],
                          function (rowKey, rowIndex) {
                            return _c("div", {
                              key: index + "-" + rowIndex,
                              class: rowKey,
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.formatValue(value[rowKey], rowKey)
                                ),
                              },
                            })
                          }
                        ),
                        0
                      ),
                    ],
                    2
                  ),
                  _vm._l(
                    _vm.tableFormat["top rows"],
                    function (rowKey, rowIndex) {
                      return [
                        !!value[rowKey]
                          ? _c(
                              "div",
                              {
                                key: index + "-" + rowIndex,
                                staticClass: "info-card-row",
                              },
                              [
                                _c("div", { class: "row-key " + rowKey }, [
                                  _vm._v(_vm._s(rowKey)),
                                ]),
                                _c("div", {
                                  class: "row-value " + rowKey,
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.formatValue(value[rowKey], rowKey)
                                    ),
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }
                  ),
                  _vm._l(
                    _vm.tableFormat["features"],
                    function (featureKey, featureIndex) {
                      return _c(
                        "div",
                        {
                          key: index + "-feature-" + featureIndex,
                          class: [
                            "info-card-more",
                            _vm.openCard &&
                            _vm.openCard ==
                              value[
                                _vm.tableFormat["rows as info cards"]["key"]
                              ]
                              ? "hidden"
                              : "",
                          ],
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:;" },
                              on: {
                                click: function ($event) {
                                  return _vm.showHideFeature(
                                    _vm.sectionId + index + featureKey,
                                    value[
                                      _vm.tableFormat["rows as info cards"][
                                        "key"
                                      ]
                                    ]
                                  )
                                },
                              },
                            },
                            [_vm._v(" More ❯ ")]
                          ),
                        ]
                      )
                    }
                  ),
                  _vm._l(
                    _vm.tableFormat["features"],
                    function (featureKey, featureIndex) {
                      return _c(
                        "div",
                        {
                          key: featureIndex,
                          staticClass: "info-card-more-wrapper",
                          class: [
                            featureKey,
                            _vm.openCard &&
                            _vm.openCard ==
                              value[
                                _vm.tableFormat["rows as info cards"]["key"]
                              ]
                              ? ""
                              : "hidden",
                          ],
                          attrs: { id: _vm.sectionId + index + featureKey },
                        },
                        [
                          _vm._l(
                            _vm.tableFormat[featureKey],
                            function (fRowKey, fRowIndex) {
                              return [
                                !!value[fRowKey]
                                  ? _c(
                                      "div",
                                      {
                                        key: fRowIndex,
                                        staticClass: "info-card-row",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "feature",
                                            class: "row-key " + fRowKey,
                                          },
                                          [_vm._v(_vm._s(fRowKey))]
                                        ),
                                        _c("div", {
                                          staticClass: "feature",
                                          class: "row-value " + fRowKey,
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.formatValue(
                                                value[fRowKey],
                                                fRowKey
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            }
                          ),
                        ],
                        2
                      )
                    }
                  ),
                ],
                2
              ),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "btn" }, [
      _c("span", { staticClass: "btn-icon" }, [_vm._v("?")]),
      _vm._v("Glossary"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "btn" }, [
      _c("span", { staticClass: "btn-icon" }, [_vm._v("i")]),
      _vm._v("Tour"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }