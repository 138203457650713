var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "chart-wrapper" },
    [
      !!_vm.summaryPlot["title"]
        ? _c("div", [_c("h4", [_vm._v(_vm._s(_vm.summaryPlot["title"]))])])
        : _vm._e(),
      _vm.summaryPlot.type == "multi"
        ? _c("research-multi-plot", {
            attrs: { summaryPlot: _vm.summaryPlot, rawData: _vm.rawData },
          })
        : _c("div", { staticClass: "all-charts" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }