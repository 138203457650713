var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "mbm-plot-content row" }, [
    _vm.searchingRegion != null
      ? _c("div", { staticClass: "col-md-12 gene-links-plot-wrapper" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { attrs: { id: "geneLinksUIWrapper" } }, [
              _c("strong", [
                _vm._v("Filter associated variants by links to genes. "),
              ]),
              _c(
                "div",
                {
                  staticClass: "filtering-ui-wrapper add-content",
                  staticStyle: {
                    width: "100%",
                    padding: "0 10px",
                    "text-align": "left",
                  },
                },
                [
                  _c("div", { staticClass: "filtering-ui-content" }, [
                    _c(
                      "div",
                      { staticClass: "col", staticStyle: { padding: "2px" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "label",
                            staticStyle: {
                              display: "inline-block",
                              "margin-right": "10px",
                            },
                          },
                          [_vm._v(" Select Tissue ")]
                        ),
                        _c(
                          "select",
                          {
                            staticClass: "custom-select",
                            on: {
                              change: function ($event) {
                                return _vm.getGeneLinks($event)
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(" " + _vm._s("Select Tissue") + " "),
                            ]),
                            _vm._l(_vm.GLTissues, function (tissue) {
                              return [
                                _c("option", {
                                  key: tissue.tissue,
                                  domProps: {
                                    value: tissue.tissue,
                                    innerHTML: _vm._s(
                                      _vm.getTissueLabel(tissue)
                                    ),
                                  },
                                }),
                              ]
                            }),
                          ],
                          2
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col",
                        staticStyle: { padding: "2px", "margin-left": "15px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "label",
                            staticStyle: {
                              display: "inline-block",
                              "margin-right": "10px",
                            },
                          },
                          [_vm._v(" Select SNP 2 Gene ")]
                        ),
                        _c(
                          "select",
                          {
                            staticClass: "custom-select",
                            on: {
                              change: function ($event) {
                                return _vm.getS2GeneLinks($event)
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(" " + _vm._s("Select one") + " "),
                            ]),
                            _c("option", {
                              attrs: { value: "s2g" },
                              domProps: { innerHTML: _vm._s("SNP to Gene") },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _vm.GLData != null &&
                  !_vm.renderConfig["no search key bubbles"]
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            top: "7px",
                          },
                        },
                        [
                          _vm._l(Object.keys(_vm.GLData), function (tissue) {
                            return [
                              _c("span", {
                                key: tissue,
                                class: "btn GL-search-bubble ",
                                domProps: {
                                  innerHTML: _vm._s(
                                    tissue +
                                      "&nbsp;<span class='remove'>X</span>"
                                  ),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeGLTissue(tissue)
                                  },
                                },
                              }),
                            ]
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "col-md-9 gene-link-plot-wrapper" }, [
            _c(
              "div",
              {
                class:
                  _vm.pkgDataSelected.filter((s) => s.type == "GLTissue")
                    .length == 0
                    ? "height-1px"
                    : "height-auto",
                attrs: { id: "geneLinksPlotWrapper" },
              },
              [
                _c(
                  "div",
                  { staticClass: "hidden", attrs: { id: "GLInfoBox" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "fixed-info-box-close",
                        attrs: { id: "info_box_close" },
                        on: {
                          click: function ($event) {
                            return _vm.utils.uiUtils.removeOnMouseOut(
                              "GLInfoBox",
                              100
                            )
                          },
                        },
                      },
                      [_c("b-icon", { attrs: { icon: "x-circle-fill" } })],
                      1
                    ),
                    _c("span", { attrs: { id: "GLInfoBoxContent" } }),
                  ]
                ),
                _c("canvas", {
                  attrs: { id: "geneLinksPlot", width: "", height: "" },
                  on: {
                    resize: _vm.onResize,
                    mousemove: function ($event) {
                      return _vm.checkPosition($event, "hover")
                    },
                    click: function ($event) {
                      return _vm.checkPosition($event, "click")
                    },
                    mouseout: function ($event) {
                      !_vm.utils.uiUtils.isIdFixed("#GLInfoBox")
                        ? _vm.utils.uiUtils.removeOnMouseOut("GLInfoBox", 1000)
                        : ""
                    },
                  },
                }),
              ]
            ),
          ]),
          _vm.GLData != null
            ? _c(
                "div",
                { staticClass: "col-md-3 GL-plot-ui-wrapper reference-area" },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-outline-secondary",
                          staticStyle: {
                            "margin-right": "5px",
                            "margin-bottom": "10px",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.checkUncheckAll("check")
                            },
                          },
                        },
                        [_vm._v(" Select all ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-outline-secondary",
                          staticStyle: { "margin-bottom": "10px" },
                          on: {
                            click: function ($event) {
                              return _vm.checkUncheckAll("uncheck")
                            },
                          },
                        },
                        [_vm._v(" Unselect all ")]
                      ),
                      _vm._m(0),
                      _vm._l(
                        _vm.getMethodsArr(_vm.GLData),
                        function (m, mIndex) {
                          return _c(
                            "div",
                            {
                              key: m,
                              staticStyle: { display: "inline-block" },
                            },
                            [
                              _c(
                                "label",
                                {
                                  style:
                                    "padding-right: 5px; margin-right: 5px; border-bottom: solid 3px " +
                                    _vm.methodsColors[mIndex] +
                                    ";",
                                },
                                [
                                  _c("input", {
                                    class: m.replace(/ /g, "_"),
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      value: m,
                                      checked: !_vm.pkgDataSelected
                                        .filter((s) => s.type == "GL-Method")
                                        .map((s) => s.id)
                                        .includes(m),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addRemoveParameter(
                                          m,
                                          "GL-Method"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(_vm._s(" " + m + " ") + " "),
                                ]
                              ),
                            ]
                          )
                        }
                      ),
                      _vm._m(1),
                      _vm._l(_vm.getGenesArr(_vm.GLData), function (g) {
                        return _c(
                          "div",
                          { key: g, staticStyle: { display: "inline-block" } },
                          [
                            _c(
                              "label",
                              { staticStyle: { "padding-right": "10px" } },
                              [
                                _c("input", {
                                  class: g.replace(/ /g, "_"),
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    value: g,
                                    checked: !_vm.pkgDataSelected
                                      .filter((s) => s.type == "GL-Gene")
                                      .map((s) => s.id)
                                      .includes(g),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addRemoveParameter(
                                        g,
                                        "GL-Gene"
                                      )
                                    },
                                  },
                                }),
                                _vm._v(_vm._s(" " + g + " ") + " "),
                              ]
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h6", [_c("strong", [_vm._v("Methods")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h6", [_c("strong", [_vm._v("Genes")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }