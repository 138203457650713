var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.plotData && _vm.renderConfig
    ? _c(
        "div",
        {
          staticClass: "egl-m-plot-content",
          style: "height:" + _vm.renderConfig["height"] + "px;",
        },
        [
          _c("div", { staticClass: "bunch-by-locus" }, [
            _c("div", { staticClass: "bunch-ui" }, [
              _c("input", {
                staticClass: "form-control",
                attrs: {
                  type: "checkbox",
                  id: `${_vm.plotId}_groupByLocusCheck`,
                },
                on: {
                  click: function ($event) {
                    return _vm.renderPlot(_vm.plotId)
                  },
                },
              }),
              _vm._v(" Render by region "),
            ]),
            _c("div", { staticClass: "bunch-ui" }, [
              _vm._v(" Count region by: "),
              _c(
                "select",
                {
                  staticClass: "form-control",
                  attrs: { id: `${_vm.plotId}_mergeByNumber` },
                  on: {
                    change: function ($event) {
                      return _vm.renderPlot(_vm.plotId)
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
                  _c("option", { attrs: { value: "1000" } }, [_vm._v("1K")]),
                  _c("option", { attrs: { value: "10000" } }, [_vm._v("10K")]),
                  _c("option", { attrs: { value: "100000" } }, [
                    _vm._v("100K"),
                  ]),
                  _c("option", { attrs: { value: "1000000" } }, [_vm._v("1M")]),
                  _c("option", { attrs: { value: "10000000" } }, [
                    _vm._v("10M"),
                  ]),
                ]
              ),
              _vm._v(" bp "),
            ]),
          ]),
          _c("div", { staticClass: "y-axis-label" }, [
            _vm._v(_vm._s(_vm.renderConfig["y axis label"])),
          ]),
          _c("div", {
            staticClass: "egl_m_plot_y",
            attrs: { id: `${_vm.plotId}_egl_m_plot_y_axis` },
          }),
          _c("div", {
            staticClass: "egl-m-plot",
            attrs: { id: `${_vm.plotId}_egl_m_plot` },
          }),
          _c("div", { staticClass: "x-axis-label" }, [
            _vm._v(_vm._s(_vm.renderConfig["x axis label"])),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }