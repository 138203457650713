import { render, staticRenderFns } from "./ResearchPageFooter.vue?vue&type=template&id=2e859fed"
import script from "./ResearchPageFooter.vue?vue&type=script&lang=js"
export * from "./ResearchPageFooter.vue?vue&type=script&lang=js"
import style0 from "./ResearchPageFooter.vue?vue&type=style&index=0&id=2e859fed&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ec2-user/.jenkins/workspace/CFDE Knowledge - Dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2e859fed')) {
      api.createRecord('2e859fed', component.options)
    } else {
      api.reload('2e859fed', component.options)
    }
    module.hot.accept("./ResearchPageFooter.vue?vue&type=template&id=2e859fed", function () {
      api.rerender('2e859fed', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/researchPortal/ResearchPageFooter.vue"
export default component.exports