var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "mbm-plot-content row" }, [
    _vm.searchingRegion != null
      ? _c("div", { staticClass: "col-md-12 annotations-plot-wrapper" }, [
          _c("div", { staticClass: "col-md-12 anno-plot-wrapper" }, [
            _c("div", { attrs: { id: "annotationsUIWrapper" } }, [
              _vm._m(0),
              _c(
                "div",
                {
                  staticClass: "filtering-ui-wrapper add-content",
                  staticStyle: {
                    width: "100%",
                    padding: "0 10px",
                    "text-align": "left",
                  },
                },
                [
                  _c("div", { staticClass: "filtering-ui-content" }, [
                    _c(
                      "div",
                      { staticClass: "col", staticStyle: { padding: "2px" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "label",
                            staticStyle: {
                              display: "inline-block",
                              "margin-right": "10px",
                            },
                          },
                          [_vm._v(" Select Annotation ")]
                        ),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.annotationOnFocus,
                                expression: "annotationOnFocus",
                              },
                            ],
                            staticClass: "custom-select",
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.annotationOnFocus = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                function ($event) {
                                  return _vm.addAnnoTrack($event)
                                },
                              ],
                            },
                          },
                          [
                            _c("option", { attrs: { value: "null" } }, [
                              _vm._v(" " + _vm._s("Show all") + " "),
                            ]),
                            _vm._l(_vm.annoData, function (annoValue, annoKey) {
                              return _c("option", {
                                key: annoKey,
                                domProps: {
                                  value: annoKey,
                                  innerHTML: _vm._s(annoKey),
                                },
                              })
                            }),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]),
                  _vm.pkgDataSelected.filter((s) => s.type == "Annotation")
                    .length > 0 && !_vm.renderConfig["no search key bubbles"]
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            top: "7px",
                          },
                        },
                        [
                          _vm._l(
                            _vm.pkgDataSelected.filter(
                              (s) => s.type == "Annotation"
                            ),
                            function (a) {
                              return [
                                _c("span", {
                                  key: a.id,
                                  class: "btn search-bubble ",
                                  style:
                                    "background-color:" +
                                    _vm.getColorIndex(a.id),
                                  domProps: {
                                    innerHTML: _vm._s(
                                      a.id +
                                        "&nbsp;<span class='remove'>X</span>"
                                    ),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeAnnoTrack(a.id)
                                    },
                                  },
                                }),
                              ]
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "col-md-12 anno-plot-ui-wrapper",
                staticStyle: { "padding-top": "15px" },
              },
              [
                _vm._m(1),
                _c(
                  "div",
                  _vm._l(
                    _vm.annoData,
                    function (annoValue, annoKey, annoIndex) {
                      return _c(
                        "div",
                        { key: annoKey, staticClass: "anno-bubble-wrapper" },
                        [
                          _c("span", {
                            staticClass: "anno-bubble",
                            style:
                              "background-color:" +
                              _vm.compareGroupColors[annoIndex] +
                              ";",
                            domProps: { innerHTML: _vm._s("&nbsp;") },
                          }),
                          _c("span", {
                            domProps: { innerHTML: _vm._s(annoKey) },
                          }),
                        ]
                      )
                    }
                  ),
                  0
                ),
                _vm.searchingPhenotype != null
                  ? _c("div", { attrs: { id: "GEPlotWrapper" } }, [
                      _c("div", {
                        staticClass: "hidden",
                        attrs: { id: "GEInfoBox" },
                      }),
                      _c("canvas", {
                        staticStyle: { "background-color": "#ffffff" },
                        attrs: { id: "GEPlot", width: "", height: "" },
                        on: {
                          mousemove: function ($event) {
                            return _vm.checkGEPosition($event)
                          },
                          mouseout: function ($event) {
                            return _vm.onMouseOut("GEInfoBox")
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              {
                class:
                  _vm.pkgDataSelected.filter((s) => s.type == "Annotation")
                    .length == 0
                    ? "height-1px"
                    : "height-auto",
                attrs: { id: "annotationsPlotWrapper" },
              },
              [
                _vm.pkgDataSelected.filter((s) => s.type == "Annotation")
                  .length > 0
                  ? _c("strong", [
                      _vm._v(
                        " Click on a tissue track to see annotations in specific cell types."
                      ),
                    ])
                  : _vm._e(),
                _c("div", {
                  staticClass: "hidden",
                  attrs: { id: "tissueInfoBox" },
                }),
                _c("canvas", {
                  attrs: { id: "annotationsPlot", width: "", height: "" },
                  on: {
                    resize: _vm.onResize,
                    mousemove: function ($event) {
                      return _vm.checkPosition($event, "hover")
                    },
                    click: function ($event) {
                      return _vm.checkPosition($event, "click")
                    },
                    mouseout: function ($event) {
                      return _vm.onMouseOut("tissueInfoBox")
                    },
                  },
                }),
              ]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", [
      _c("strong", [
        _vm._v(
          "Filter associated variants by location within regulatory regions annotated in broad tissue categories."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h6", [_c("strong", [_vm._v("Global Enrichment")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }