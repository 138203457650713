var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "multi-section",
      class: "wrapper-" + _vm.sectionIndex,
      attrs: { id: "wrapper_" + _vm.sectionIndex },
    },
    [
      !_vm.isInTab
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm show-evidence-btn capture-data",
                  attrs: { title: "Capture data in section" },
                  on: {
                    click: function ($event) {
                      return _vm.captureData()
                    },
                  },
                },
                [_c("b-icon", { attrs: { icon: "camera" } })],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm show-evidence-btn show-hide-section",
                  attrs: { title: "Show / hide section" },
                  on: {
                    click: function ($event) {
                      return _vm.utils.uiUtils.showHideElement(
                        "section_" + _vm.sectionID
                      )
                    },
                  },
                },
                [_c("b-icon", { attrs: { icon: "eye" } })],
                1
              ),
              _c("h4", [_vm._v(_vm._s(_vm.sectionsConfig.header) + " ")]),
            ]),
          ])
        : _vm._e(),
      !!_vm.description
        ? _c("research-page-description", {
            attrs: { content: _vm.description, utils: _vm.utils },
          })
        : _vm._e(),
      _c(
        "div",
        _vm._l(
          _vm.sectionsConfig.sections["sub sections"],
          function (section, sIndex) {
            return _c(
              "div",
              {
                staticClass: "summary-filter-wrapper",
                class:
                  _vm.isSectionData(section.section) == true ? "" : "hidden",
              },
              [
                _c("input", {
                  staticClass: "summary-filter-chkbox",
                  attrs: {
                    type: "checkbox",
                    id: "filter_" + _vm.sectionID + "_" + section.section,
                  },
                  domProps: { value: section.section },
                }),
                _c("label", { attrs: { for: section.section } }, [
                  _vm._v(_vm._s(section.label)),
                ]),
              ]
            )
          }
        ),
        0
      ),
      _c("div", [
        _c(
          "h5",
          {
            staticClass: "btn btn-primary",
            on: {
              click: function ($event) {
                return _vm.buildSummary()
              },
            },
          },
          [_c("strong", [_vm._v("Update Summary")])]
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "row card-body",
          attrs: { id: "section_" + _vm.sectionID },
        },
        [
          _c(
            "div",
            { staticClass: "col-md-12", class: "wrapper-" + _vm.sectionIndex },
            [
              !!_vm.sectionData && _vm.summarizing == null
                ? _c("research-data-table", {
                    attrs: {
                      pageID: _vm.sectionIndex,
                      dataset: _vm.sectionData,
                      tableFormat: _vm.tableFormat,
                      initPerPageNumber: 10,
                      tableLegend: null,
                      dataComparisonConfig: null,
                      searchParameters: null,
                      pkgData: null,
                      pkgDataSelected: null,
                      phenotypeMap: null,
                      sectionId: _vm.sectionsConfig["section id"],
                      multiSectionPage: true,
                      summarySection: true,
                      starItems: _vm.starItems,
                      utils: _vm.utils,
                      region: _vm.regionParam,
                      regionZoom: _vm.regionZoom,
                      regionViewArea: _vm.regionViewArea,
                    },
                    on: {
                      "clicked-sort": _vm.sortData,
                      "on-star": _vm.starColumn,
                      "on-feature-rows-change": _vm.setFeatureRows,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }