var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "mbm-plot-content row" }, [
    _vm.searchingRegion != null
      ? _c("div", { staticClass: "col-md-12 annotations-plot-wrapper" }, [
          _c("div", { staticClass: "col-md-9 anno-plot-wrapper" }, [
            _c("div", { attrs: { id: "annotationsUIWrapper" } }, [
              _c(
                "div",
                {
                  staticClass: "filtering-ui-wrapper add-content",
                  staticStyle: {
                    width: "100%",
                    padding: "0 10px",
                    "text-align": "left",
                  },
                },
                [
                  _c("div", { staticClass: "filtering-ui-content" }, [
                    _c(
                      "div",
                      { staticClass: "col", staticStyle: { padding: "2px" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "label",
                            staticStyle: {
                              display: "inline-block",
                              "margin-right": "10px",
                            },
                          },
                          [_vm._v(" Select Annotation ")]
                        ),
                        _c(
                          "select",
                          {
                            staticClass: "custom-select",
                            on: {
                              change: function ($event) {
                                return _vm.addAnnoTrack($event)
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(" " + _vm._s("Select annotation") + " "),
                            ]),
                            _vm._l(_vm.annoData, function (annoValue, annoKey) {
                              return _c("option", {
                                key: annoKey,
                                domProps: {
                                  value: annoKey,
                                  innerHTML: _vm._s(annoKey),
                                },
                              })
                            }),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]),
                  _vm.pkgDataSelected.filter((s) => s.type == "Annotation")
                    .length > 0 && !_vm.renderConfig["no search key bubbles"]
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            top: "7px",
                          },
                        },
                        [
                          _vm._l(
                            _vm.pkgDataSelected.filter(
                              (s) => s.type == "Annotation"
                            ),
                            function (a) {
                              return [
                                _c("span", {
                                  key: a.id,
                                  class: "btn search-bubble ",
                                  style:
                                    "background-color:" +
                                    _vm.getColorIndex(a.id),
                                  domProps: {
                                    innerHTML: _vm._s(
                                      a.id +
                                        "&nbsp;<span class='remove'>X</span>"
                                    ),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeAnnoTrack(a.id)
                                    },
                                  },
                                }),
                              ]
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              ),
            ]),
            _vm.pkgDataSelected.filter((s) => s.type == "Annotation").length > 0
              ? _c("div", [
                  _c(
                    "div",
                    { staticStyle: { padding: "5px", "text-align": "left" } },
                    [
                      _c("strong", [_vm._v("Select Tissues")]),
                      _vm.pkgDataSelected.filter((s) => s.type == "Tissue")
                        .length > 0 &&
                      !_vm.renderConfig["no search key bubbles"]
                        ? _c(
                            "div",
                            { staticStyle: { float: "right" } },
                            [
                              _vm._l(
                                _vm.pkgDataSelected.filter(
                                  (s) => s.type == "Tissue"
                                ),
                                function (a) {
                                  return [
                                    _c("span", {
                                      key: a.id,
                                      class: "btn search-bubble ",
                                      style: "background-color:#999999",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          a.id +
                                            "&nbsp;<span class='remove'>X</span>"
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addRemoveTissueTrack(
                                            null,
                                            a.id
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                }
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c("div", { staticClass: "annotations-table-wrapper" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          !!_vm.renderConfig["ui table legend"]
                            ? _vm.renderConfig["ui table legend"]
                            : "Table is sort by fold (SNPs/expectedSNPs) across annotations. Tissue columns show P-Value / Fold."
                        ),
                      },
                    }),
                    _c(
                      "table",
                      {
                        staticClass: "table table-sm ge-data-table",
                        attrs: { cellpadding: "0", cellspacing: "0" },
                      },
                      [
                        _c("thead", [
                          _c(
                            "tr",
                            _vm._l(_vm.GEData, function (pValue, pKey, pIndex) {
                              return _c("th", {
                                key: pKey,
                                domProps: { innerHTML: _vm._s(pKey) },
                              })
                            }),
                            0
                          ),
                        ]),
                        _c("tbody", [
                          _c(
                            "tr",
                            _vm._l(_vm.GEData, function (pValue, pKey, pIndex) {
                              return _c(
                                "td",
                                {
                                  key: pKey,
                                  staticClass: "phenotype-tissue-td",
                                  staticStyle: {
                                    padding: "0",
                                    border: "none !important",
                                  },
                                },
                                [
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "table table-sm ge-data-table",
                                      attrs: {
                                        cellpadding: "0",
                                        cellspacing: "0",
                                      },
                                    },
                                    [
                                      _c("thead", [
                                        _c(
                                          "tr",
                                          [
                                            _c("th"),
                                            _c("th", [_vm._v("Tissues")]),
                                            _vm._l(
                                              _vm.pkgDataSelected.filter(
                                                (s) => s.type == "Annotation"
                                              ),
                                              function (annotation) {
                                                return _c("th", {
                                                  key: annotation.id,
                                                  class: annotation.id,
                                                  style:
                                                    "background-color:" +
                                                    _vm.getColorIndex(
                                                      annotation.id
                                                    ),
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      annotation.id
                                                    ),
                                                  },
                                                })
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          _vm.getSortByAnno(
                                            _vm.pkgData.GEByTissueData[pKey]
                                          ),
                                          function (tissueValue, tissueKey) {
                                            return _c(
                                              "tr",
                                              { key: tissueKey + pKey },
                                              [
                                                _c("td", [
                                                  _c("input", {
                                                    class: tissueKey.replace(
                                                      / /g,
                                                      "_"
                                                    ),
                                                    attrs: { type: "checkbox" },
                                                    domProps: {
                                                      value: tissueKey,
                                                      checked:
                                                        _vm.pkgDataSelected
                                                          .filter(
                                                            (s) =>
                                                              s.type == "Tissue"
                                                          )
                                                          .map((s) => s.id)
                                                          .includes(tissueKey),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addRemoveTissueTrack(
                                                          $event,
                                                          null
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]),
                                                _c("td", {
                                                  domProps: {
                                                    innerHTML:
                                                      _vm._s(tissueKey),
                                                  },
                                                }),
                                                _vm._l(
                                                  _vm.pkgDataSelected.filter(
                                                    (s) =>
                                                      s.type == "Annotation"
                                                  ),
                                                  function (annotation) {
                                                    return _c(
                                                      "td",
                                                      {
                                                        key: annotation.id,
                                                        class: annotation.id,
                                                      },
                                                      [
                                                        !!_vm.annoData[
                                                          annotation.id
                                                        ][tissueKey]
                                                          ? _c("span", {
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    !!tissueValue[
                                                                      annotation
                                                                        .id
                                                                    ]
                                                                      ? tissueValue[
                                                                          annotation
                                                                            .id
                                                                        ]
                                                                          .pValue +
                                                                          " / " +
                                                                          tissueValue[
                                                                            annotation
                                                                              .id
                                                                          ].fold
                                                                      : ""
                                                                  ),
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
            _c("div", { attrs: { id: "annotationsPlotWrapper" } }, [
              _c("div", {
                staticClass: "hidden",
                attrs: { id: "tissueInfoBox" },
              }),
              _c("canvas", {
                attrs: { id: "annotationsPlot", width: "", height: "" },
                on: {
                  resize: _vm.onResize,
                  mousemove: function ($event) {
                    return _vm.checkPosition($event)
                  },
                  mouseout: function ($event) {
                    return _vm.onMouseOut("tissueInfoBox")
                  },
                },
              }),
              _c("div", {
                class:
                  _vm.pkgDataSelected.filter((s) => s.type == "Annotation")
                    .length > 0
                    ? "hidden"
                    : "",
                attrs: { id: "annoInitialMessage" },
                domProps: { innerHTML: _vm._s("Please select annotation.") },
              }),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "col-md-3 anno-plot-ui-wrapper reference-area" },
            [
              _vm._m(0),
              _c(
                "div",
                _vm._l(_vm.annoData, function (annoValue, annoKey, annoIndex) {
                  return _c(
                    "div",
                    { key: annoKey, staticClass: "anno-bubble-wrapper" },
                    [
                      _c("span", {
                        staticClass: "anno-bubble",
                        style:
                          "background-color:" +
                          _vm.compareGroupColors[annoIndex] +
                          ";",
                        domProps: { innerHTML: _vm._s("&nbsp;") },
                      }),
                      _c("span", { domProps: { innerHTML: _vm._s(annoKey) } }),
                    ]
                  )
                }),
                0
              ),
              _vm.searchingPhenotype != null
                ? _c("div", { attrs: { id: "GEPlotWrapper" } }, [
                    _c("div", {
                      staticClass: "hidden",
                      attrs: { id: "GEInfoBox" },
                    }),
                    _c("canvas", {
                      staticStyle: { "background-color": "#ffffff" },
                      attrs: { id: "GEPlot", width: "", height: "" },
                      on: {
                        mousemove: function ($event) {
                          return _vm.checkGEPosition($event)
                        },
                        mouseout: function ($event) {
                          return _vm.onMouseOut("GEInfoBox")
                        },
                      },
                    }),
                  ])
                : _vm._e(),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h6", [_c("strong", [_vm._v("Global Enrichment")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }