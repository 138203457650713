var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "container-fluid mdkp-body flex-body front-page",
      class: !!_vm.sectionConfigs["content"]["search enabled"]
        ? "with-single-search"
        : "",
    },
    [
      _c(
        "div",
        {
          staticClass: "fp-top",
          style: {
            background: `linear-gradient( ${this.sectionConfigs["content"]["color secondary"]} 0%, ${this.sectionConfigs["content"]["color primary"]} 100% )`,
          },
        },
        [
          _c("div", { staticClass: "fp-intro-section" }, [
            _c("div", { staticClass: "fp-intro-logo" }, [
              _c("img", {
                staticStyle: { width: "400px" },
                attrs: {
                  src: _vm.getLogo(_vm.sectionConfigs["content"]["logo large"]),
                },
              }),
            ]),
            _c("div", { staticClass: "fp-intro-divider" }),
            _c("div", { staticClass: "fp-intro-blurb" }, [
              _c("div", { staticClass: "fp-intro-blurb-text" }, [
                _vm._v(_vm._s(_vm.sectionConfigs["content"]["tagline"])),
              ]),
            ]),
          ]),
          _vm.sectionConfigs["content"]["search enabled"]
            ? _c("div", { staticClass: "row fp-search-section" }, [
                _c(
                  "div",
                  { staticClass: "fp-search" },
                  [
                    !_vm.sectionConfigs["content"]["single search version"]
                      ? _c("research-single-search", {
                          attrs: {
                            "single-search-config":
                              _vm.sectionConfigs["content"],
                            phenotypes: _vm.phenotypesInUse,
                            utils: _vm.utilsBox,
                          },
                        })
                      : _vm._e(),
                    !!_vm.sectionConfigs["content"]["single search version"] &&
                    _vm.sectionConfigs["content"]["single search version"] ==
                      "2.0"
                      ? _c("research-single-search-v2", {
                          attrs: {
                            "single-search-config":
                              _vm.sectionConfigs["content"],
                            phenotypes: _vm.phenotypesInUse,
                            utils: _vm.utilsBox,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                !!_vm.sectionConfigs["content"]["search examples"]
                  ? _c(
                      "div",
                      { staticClass: "fp-search-examples" },
                      [
                        _c("span", {
                          domProps: { innerHTML: _vm._s("examples: ") },
                        }),
                        _vm._l(
                          _vm.sectionConfigs["content"]["search examples"],
                          function (example) {
                            return _c("span", {
                              key: example.value,
                              domProps: {
                                innerHTML: _vm._s(_vm.getExampleLink(example)),
                              },
                            })
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      ),
      _c("div", { staticClass: "fp-bottom" }, [
        _c(
          "div",
          { staticClass: "fp-bottom-container" },
          [
            _vm.pageDescription
              ? _c("research-page-description", {
                  attrs: { content: _vm.pageDescription, utils: _vm.utilsBox },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }