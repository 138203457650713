var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "volcano-plot-content" },
    [
      _c("div", {
        staticClass: "clicked-dot-value hidden",
        attrs: { id: "clicked_dot_value" + _vm.sectionId },
      }),
      !!_vm.renderConfig
        ? _c("canvas", {
            staticClass: "volcano-plot",
            style:
              "width:" +
              (_vm.renderConfig.width + 120) +
              "px;height:" +
              (_vm.renderConfig.height + 120) +
              "px;",
            attrs: {
              id: "volcanoPlot" + _vm.sectionId,
              width: _vm.renderConfig.width * 2 + 240,
              height: _vm.renderConfig.height * 2 + 240,
            },
            on: {
              mouseleave: _vm.hidePanel,
              mousemove: _vm.checkPosition,
              click: _vm.filterTable,
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "download-images-setting" }, [
        _c(
          "span",
          { staticClass: "btn btn-default options-gear" },
          [_vm._v("Download "), _c("b-icon", { attrs: { icon: "download" } })],
          1
        ),
        _c("ul", { staticClass: "options" }, [
          _c("li", [
            _c(
              "a",
              {
                attrs: { href: "javascript:;" },
                on: {
                  click: function ($event) {
                    return _vm.downloadImage(
                      "vector_wrapper_" + _vm.sectionId,
                      _vm.sectionId + "_volcanoPlot",
                      "svg"
                    )
                  },
                },
              },
              [_vm._v("Download SVG")]
            ),
          ]),
          _c("li", [
            _c(
              "a",
              {
                attrs: { href: "javascript:;" },
                on: {
                  click: function ($event) {
                    return _vm.downloadImage(
                      "volcanoPlot" + _vm.sectionId,
                      _vm.sectionId + "_volcanoPlot",
                      "png"
                    )
                  },
                },
              },
              [_vm._v("Download PNG")]
            ),
          ]),
        ]),
      ]),
      !!_vm.renderData
        ? _c("research-volcano-plot-vector", {
            ref: _vm.sectionId + "_volcanoPlot",
            attrs: {
              renderData: _vm.renderData,
              renderConfig: _vm.renderConfig,
              margin: _vm.adjPlotMargin,
              sectionId: _vm.sectionId,
              utils: _vm.utils,
            },
          })
        : _vm._e(),
      !!_vm.renderConfig.label
        ? _c("div", {
            staticClass: "volcano-plot-label",
            domProps: { innerHTML: _vm._s(_vm.renderConfig.label) },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }