var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-dropdown",
    {
      staticClass: "download-chart",
      attrs: {
        variant: "secondary",
        right: "",
        size: "sm",
        text: "Download chart",
      },
    },
    [
      _c("b-dropdown-text", [_vm._v("Save chart as")]),
      _c("b-dropdown-divider"),
      _c(
        "b-dropdown-item",
        {
          on: {
            click: function ($event) {
              return _vm.downloadSvg()
            },
          },
        },
        [_vm._v("SVG")]
      ),
      _c(
        "b-dropdown-item",
        {
          on: {
            click: function ($event) {
              return _vm.downloadPng()
            },
          },
        },
        [_vm._v("PNG")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }