var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "research-data-table-features-wrapper" },
    _vm._l(_vm.featuresFormat["features"], function (value, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "research-data-table-features",
          attrs: {
            "v-if": !!_vm.featuresData && !!_vm.featuresFormat["features"],
          },
        },
        [
          _c(
            "table",
            { staticClass: "table table-sm feature-table" },
            [
              _c(
                "tr",
                [
                  _c(
                    "th",
                    {
                      staticClass: "byor-tooltip",
                      class: "feature-title-th feature-th-" + index,
                      attrs: { rowspan: _vm.featuresData[value].length + 1 },
                    },
                    [
                      _c("span", { staticClass: "feature-title" }, [
                        _vm._v(_vm._s(value)),
                      ]),
                    ]
                  ),
                  _vm._l(
                    _vm.featuresFormat[value],
                    function (headerValue, headerIndex) {
                      return _c(
                        "th",
                        {
                          key: headerIndex,
                          staticClass: "byor-tooltip",
                          class: "feature-th-" + index,
                        },
                        [
                          _c("span", {
                            domProps: { innerHTML: _vm._s(headerValue) },
                          }),
                          !!_vm.featuresFormat["tool tips"] &&
                          !!_vm.featuresFormat["tool tips"][headerValue]
                            ? _c("span", {
                                staticClass: "tooltiptext",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.featuresFormat["tool tips"][headerValue]
                                  ),
                                },
                              })
                            : _vm._e(),
                        ]
                      )
                    }
                  ),
                ],
                2
              ),
              _vm._l(
                _vm.featuresData[value],
                function (featureValue, featureIndex) {
                  return _c(
                    "tr",
                    { key: featureIndex },
                    [
                      _vm._l(_vm.featuresFormat[value], function (headerValue) {
                        return [
                          !!_vm.summarySection &&
                          featureIndex < _vm.featureRowsNumber &&
                          !!_vm.featuresFormat[value].includes(headerValue)
                            ? _c("td", {
                                key: headerValue,
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.formatValue(
                                      featureValue[headerValue],
                                      headerValue
                                    )
                                  ),
                                },
                              })
                            : _vm._e(),
                          !_vm.summarySection &&
                          !!_vm.featuresFormat[value].includes(headerValue)
                            ? _c("td", {
                                key: headerValue,
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.formatValue(
                                      featureValue[headerValue],
                                      headerValue
                                    )
                                  ),
                                },
                              })
                            : _vm._e(),
                        ]
                      }),
                      !!_vm.summarySection &&
                      featureIndex == _vm.featureRowsNumber
                        ? _c(
                            "td",
                            {
                              staticStyle: {
                                color: "red",
                                "text-align": "center",
                              },
                              attrs: { colspan: "100" },
                            },
                            [
                              _vm._v(
                                " There are " +
                                  _vm._s(
                                    featureValue.featureRows -
                                      _vm.featureRowsNumber
                                  ) +
                                  " more rows of evidence data. Click to 'Set summary table' button to set the number of visible evidence data rows."
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                }
              ),
            ],
            2
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }