var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "byor-single-search-wrapper" }, [
    _c(
      "div",
      {
        class: !!_vm.summaryPopup
          ? "ss-summary-popup"
          : "ss-summary-popup hidden",
        attrs: { id: "summary_popup" },
      },
      [
        _c(
          "span",
          {
            staticClass: "btn btn-default reset-search",
            on: {
              click: function ($event) {
                _vm.summaryPopup = !_vm.summaryPopup ? true : null
              },
            },
          },
          [_c("b-icon", { attrs: { icon: "arrow-down-left-square" } })],
          1
        ),
        _c("h4", [_vm._v("Search quick view")]),
        _c(
          "div",
          { staticClass: "summary-content" },
          _vm._l(_vm.summaryAll, function (item) {
            return _c("div", { domProps: { innerHTML: _vm._s(item.data) } })
          }),
          0
        ),
      ]
    ),
    _vm.summaryAll.length > 0
      ? _c(
          "span",
          {
            staticClass: "btn btn-default ss-summary-popup-btn",
            on: {
              click: function ($event) {
                _vm.summaryPopup = !_vm.summaryPopup ? true : null
              },
            },
          },
          [
            _vm._v(" Search quick view "),
            _c("b-icon", { attrs: { icon: "arrow-up-right-square" } }),
          ],
          1
        )
      : _vm._e(),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.singleSearchParam,
          expression: "singleSearchParam",
        },
      ],
      staticClass: "form-control byor-single-search",
      attrs: {
        type: "text",
        id: "byor_single_search",
        placeholder:
          !!_vm.singleSearchConfig &&
          !!_vm.singleSearchConfig["search instruction"]
            ? _vm.singleSearchConfig["search instruction"]
            : "Search gene, variant, region, phenotype, or tissue",
        autocomplete: "off",
      },
      domProps: { value: _vm.singleSearchParam },
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          return _vm.onSearch.apply(null, arguments)
        },
        input: function ($event) {
          if ($event.target.composing) return
          _vm.singleSearchParam = $event.target.value
        },
      },
    }),
    !!_vm.singleSearchParam
      ? _c(
          "span",
          {
            staticClass: "btn btn-default reset-search",
            on: {
              click: function ($event) {
                return _vm.resetSearch()
              },
            },
          },
          [_c("b-icon", { attrs: { icon: "x-circle-fill" } })],
          1
        )
      : _vm._e(),
    !!_vm.singleSearchConfig
      ? _c("div", { staticClass: "byor-single-search-results-wrapper" }, [
          _vm.anyResults() > 0
            ? _c(
                "div",
                {
                  staticClass: "byor-single-search-results",
                  attrs: { id: "byor_single_search_results" },
                },
                [
                  _vm._l(_vm.singleSearchResult.genes, function (gene) {
                    return _c("div", { key: gene }, [
                      !!_vm.isParameterActive("kp genes").active &&
                      !!_vm.isParameterActive("kp genes").options
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "single-search-option search-gene-link",
                              attrs: { href: "javascript:;" },
                            },
                            [
                              _vm._v(_vm._s(gene) + " "),
                              _c("span", { staticClass: "more-options" }, [
                                _c(
                                  "div",
                                  { staticClass: "ss-options-wrapper" },
                                  _vm._l(
                                    _vm.isParameterActive("kp genes").options,
                                    function (option) {
                                      return _c("div", [
                                        _c("span", [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "ss-explore",
                                              attrs: {
                                                href: option.url
                                                  ? option.url + gene
                                                  : "javascript:;",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(option["url label"])
                                              ),
                                            ]
                                          ),
                                          !!option.url && !!option.sections
                                            ? _c("span", [_vm._v(" | ")])
                                            : _vm._e(),
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "ss-generate-summary",
                                              attrs: { href: "javascript:;" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.generateSummary(
                                                    gene,
                                                    option["summary id"],
                                                    option["summary label"],
                                                    option.sections
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(option["summary label"])
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c("span", { staticClass: "search-word-group" }, [
                        _vm._v(_vm._s("Gene")),
                      ]),
                    ])
                  }),
                  !!_vm.isParameterActive("kp phenotypes").active &&
                  !!_vm.isParameterActive("kp phenotypes").options
                    ? _vm._l(
                        _vm.singleSearchResult.phenotypes,
                        function (phenotype) {
                          return _c(
                            "div",
                            {
                              key: phenotype.name,
                              attrs: { value: phenotype.name },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "single-search-option",
                                  attrs: { href: "javascript:;" },
                                },
                                [
                                  _vm._v(_vm._s(phenotype.description) + " "),
                                  _c("span", { staticClass: "more-options" }, [
                                    _c(
                                      "div",
                                      { staticClass: "ss-options-wrapper" },
                                      _vm._l(
                                        _vm.isParameterActive("kp phenotypes")
                                          .options,
                                        function (option) {
                                          return _c("div", [
                                            _c("span", [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "ss-explore",
                                                  attrs: {
                                                    href: option.url
                                                      ? option.url +
                                                        phenotype.name
                                                      : "javascript:;",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(option["url label"])
                                                  ),
                                                ]
                                              ),
                                              !!option.url && !!option.sections
                                                ? _c("span", [_vm._v(" | ")])
                                                : _vm._e(),
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "ss-generate-summary",
                                                  attrs: {
                                                    href: "javascript:;",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.generateSummary(
                                                        phenotype.name,
                                                        option["summary id"],
                                                        option["summary label"],
                                                        option.sections
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      option["summary label"]
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                ]
                              ),
                              _c("span", { staticClass: "search-word-group" }, [
                                _vm._v(_vm._s(phenotype.group)),
                              ]),
                            ]
                          )
                        }
                      )
                    : _vm._e(),
                  _vm._l(
                    _vm.singleSearchConfig["search parameters"],
                    function (param) {
                      return [
                        !param.values ||
                        (!!param.values &&
                          param.values != "kp genes" &&
                          param.values != "kp phenotypes")
                          ? [
                              !!_vm.isParameterActive(param["parameter"]).active
                                ? _vm._l(
                                    _vm.singleSearchResult[param["parameter"]],
                                    function (item) {
                                      return _c("div", [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "single-search-option",
                                            attrs: { href: "javascript:;" },
                                          },
                                          [
                                            _vm._v(_vm._s(item.label) + " "),
                                            _c(
                                              "span",
                                              { staticClass: "more-options" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ss-options-wrapper",
                                                  },
                                                  _vm._l(
                                                    _vm.isParameterActive(
                                                      param["parameter"]
                                                    ).options,
                                                    function (option) {
                                                      return _c("div", [
                                                        _c("span", [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "ss-explore",
                                                              attrs: {
                                                                href: option.url
                                                                  ? option.url +
                                                                    item.value
                                                                  : "javascript:;",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  option[
                                                                    "url label"
                                                                  ]
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          !!option.url &&
                                                          !!option.sections
                                                            ? _c("span", [
                                                                _vm._v(" | "),
                                                              ])
                                                            : _vm._e(),
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "ss-generate-summary",
                                                              attrs: {
                                                                href: "javascript:;",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.generateSummary(
                                                                      item.value,
                                                                      option[
                                                                        "summary id"
                                                                      ],
                                                                      option[
                                                                        "summary label"
                                                                      ],
                                                                      option.sections
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  option[
                                                                    "summary label"
                                                                  ]
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                      ])
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "search-word-group" },
                                          [_vm._v(_vm._s(param["parameter"]))]
                                        ),
                                      ])
                                    }
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ]
                    }
                  ),
                ],
                2
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }