var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      {
        staticClass: "m-plot-content col-md-6",
        attrs: { id: "mPlotWrapper" + _vm.sectionId },
      },
      [
        _c(
          "div",
          {
            staticClass: "m-clicked-dot-value hidden",
            attrs: { id: "m_clicked_dot_value" + _vm.sectionId },
          },
          [
            _c("div", {
              attrs: { id: "m_clicked_dot_value_content" + _vm.sectionId },
            }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "m-dot-value-full-list hidden",
            attrs: { id: "m_dot_value_full_list" + _vm.sectionId },
          },
          [
            _c(
              "div",
              {
                staticClass: "m-clicked-dot-value-close",
                on: {
                  click: function ($event) {
                    return _vm.hidePanel(
                      "m_dot_value_full_list" + _vm.sectionId
                    )
                  },
                },
              },
              [_c("b-icon", { attrs: { icon: "x-circle-fill" } })],
              1
            ),
            _c("div", {
              attrs: { id: "m_dot_value_full_list_content" + _vm.sectionId },
            }),
          ]
        ),
        !!_vm.renderConfig.legend
          ? _c("div", {
              staticClass: "m-plot-legend",
              domProps: { innerHTML: _vm._s(_vm.renderConfig.legend) },
            })
          : _vm._e(),
        _vm._l(_vm.plotsList, function (item) {
          return _c(
            "div",
            { key: item, attrs: { id: _vm.sectionId + "mPlotWrapper" } },
            [
              item != "default" ? _c("h4", [_vm._v(_vm._s(item))]) : _vm._e(),
              !!_vm.renderConfig
                ? _c("canvas", {
                    attrs: {
                      id: "mPlot" + _vm.sectionId + item,
                      width: "",
                      height: "",
                    },
                    on: {
                      mouseleave: _vm.hidePanel,
                      mousemove: function ($event) {
                        return _vm.checkPosition($event, item, "m")
                      },
                      resize: _vm.onResize,
                      click: function ($event) {
                        return _vm.getFullList($event, item, "m")
                      },
                    },
                  })
                : _vm._e(),
              _c("div", { staticClass: "download-images-setting" }, [
                _c(
                  "span",
                  { staticClass: "btn btn-default options-gear" },
                  [
                    _vm._v("Download "),
                    _c("b-icon", { attrs: { icon: "download" } }),
                  ],
                  1
                ),
                _c("ul", { staticClass: "options" }, [
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function ($event) {
                            return _vm.downloadImage(
                              "vector_wrapper_" + _vm.sectionId,
                              _vm.sectionId + "_mPlot",
                              "svg",
                              "vector_m_plot_" + _vm.sectionId,
                              item,
                              _vm.sectionId + "_mPlot"
                            )
                          },
                        },
                      },
                      [_vm._v("Download SVG")]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function ($event) {
                            return _vm.downloadImage(
                              "mPlot" + _vm.sectionId + item,
                              _vm.sectionId + item + "_mPlot",
                              "png"
                            )
                          },
                        },
                      },
                      [_vm._v("Download PNG")]
                    ),
                  ]),
                ]),
              ]),
            ]
          )
        }),
        !!_vm.renderData
          ? _c("research-m-bitmap-plot-vector", {
              ref: _vm.sectionId + "_mPlot",
              attrs: {
                renderData: _vm.renderData,
                renderConfig: _vm.mPlotRenderConfig,
                colors: _vm.chromosomeColors,
                margin: _vm.adjPlotMargin,
                chrLengths: _vm.chromosomeLength,
                sectionId: _vm.sectionId,
                utils: _vm.utils,
              },
            })
          : _vm._e(),
        !!_vm.renderConfig.label
          ? _c("div", {
              staticClass: "m-plot-label",
              domProps: { innerHTML: _vm._s(_vm.renderConfig.label) },
            })
          : _vm._e(),
      ],
      2
    ),
    _c(
      "div",
      {
        staticClass: "qq-plot-content col-md-6",
        attrs: { id: "qqPlotWrapper" + _vm.sectionId },
      },
      [
        _c(
          "div",
          {
            staticClass: "qq-clicked-dot-value hidden",
            attrs: { id: "qq_clicked_dot_value" + _vm.sectionId },
          },
          [
            _c("div", {
              attrs: { id: "qq_clicked_dot_value_content" + _vm.sectionId },
            }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "qq-dot-value-full-list hidden",
            attrs: { id: "qq_dot_value_full_list" + _vm.sectionId },
          },
          [
            _c(
              "div",
              {
                staticClass: "qq-clicked-dot-value-close",
                on: {
                  click: function ($event) {
                    return _vm.hidePanel(
                      "qq_dot_value_full_list" + _vm.sectionId
                    )
                  },
                },
              },
              [_c("b-icon", { attrs: { icon: "x-circle-fill" } })],
              1
            ),
            _c("div", {
              attrs: { id: "qq_dot_value_full_list_content" + _vm.sectionId },
            }),
          ]
        ),
        !!_vm.renderConfig.legend
          ? _c("div", {
              staticClass: "qq-plot-legend",
              domProps: { innerHTML: _vm._s(_vm.renderConfig.legend) },
            })
          : _vm._e(),
        _vm._l(_vm.plotsList, function (item) {
          return _c(
            "div",
            { key: item, attrs: { id: _vm.sectionId + "qqPlotWrapper" } },
            [
              item != "default" ? _c("h4", [_vm._v(_vm._s(item))]) : _vm._e(),
              !!_vm.renderConfig
                ? _c("canvas", {
                    attrs: {
                      id: "qqPlot" + _vm.sectionId + item,
                      width: "",
                      height: "",
                    },
                    on: {
                      mouseleave: _vm.hidePanel,
                      mousemove: function ($event) {
                        return _vm.checkPosition($event, item, "qq")
                      },
                      resize: _vm.onResize,
                      click: function ($event) {
                        return _vm.getFullList($event, item, "qq")
                      },
                    },
                  })
                : _vm._e(),
              _c("div", { staticClass: "download-images-setting" }, [
                _c(
                  "span",
                  { staticClass: "btn btn-default options-gear" },
                  [
                    _vm._v("Download "),
                    _c("b-icon", { attrs: { icon: "download" } }),
                  ],
                  1
                ),
                _c("ul", { staticClass: "options" }, [
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function ($event) {
                            return _vm.downloadImage(
                              "vector_wrapper_" + _vm.sectionId,
                              _vm.sectionId + "_qqPlot",
                              "svg",
                              "vector_qq_plot_" + _vm.sectionId,
                              item,
                              _vm.sectionId + "_qqPlot"
                            )
                          },
                        },
                      },
                      [_vm._v("Download SVG")]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function ($event) {
                            return _vm.downloadImage(
                              "qqPlot" + _vm.sectionId + item,
                              _vm.sectionId + item + "_qqPlot",
                              "png"
                            )
                          },
                        },
                      },
                      [_vm._v("Download PNG")]
                    ),
                  ]),
                ]),
              ]),
            ]
          )
        }),
        !!_vm.renderData
          ? _c("research-qq-plot-vector", {
              ref: _vm.sectionId + "_qqPlot",
              attrs: {
                renderData: _vm.renderData,
                renderConfig: _vm.mqQPlotRenderConfig,
                colors: _vm.chromosomeColors,
                margin: _vm.adjPlotMargin,
                chrLengths: _vm.chromosomeLength,
                sectionId: _vm.sectionId,
                utils: _vm.utils,
              },
            })
          : _vm._e(),
        !!_vm.renderConfig.label
          ? _c("div", {
              staticClass: "qq-plot-label",
              domProps: { innerHTML: _vm._s(_vm.renderConfig.label) },
            })
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }