var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.searchingRegion != null && _vm.searchingPhenotype != null
    ? _c("div", { staticClass: "mbm-plot-content row" }, [
        _c("div", { staticClass: "col-md-12 CS-plot-wrapper" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { attrs: { id: "CSUIWrapper" } }, [
              _vm._m(0),
              _c(
                "div",
                {
                  staticClass: "filtering-ui-wrapper add-content",
                  staticStyle: {
                    width: "100%",
                    padding: "0 10px",
                    "text-align": "left",
                  },
                },
                [
                  _c("div", { staticClass: "filtering-ui-content" }, [
                    _c(
                      "div",
                      { staticClass: "col", staticStyle: { padding: "2px" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "label",
                            staticStyle: {
                              display: "inline-block",
                              "margin-right": "8px",
                            },
                          },
                          [_vm._v(" Select Credible Sets ")]
                        ),
                        _c(
                          "select",
                          {
                            staticClass: "custom-select",
                            on: {
                              change: function ($event) {
                                return _vm.getCS($event)
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(" " + _vm._s("Select credible set") + " "),
                            ]),
                            _vm._l(_vm.credibleSets, function (credibleSet) {
                              return _c("option", {
                                key:
                                  credibleSet.credibleSetId +
                                  "," +
                                  credibleSet.phenotype,
                                domProps: {
                                  value:
                                    credibleSet.credibleSetId +
                                    "," +
                                    credibleSet.phenotype,
                                  innerHTML: _vm._s(
                                    _vm.renderConfig["credible sets server"] ==
                                      "KP BioIndex"
                                      ? credibleSet.credibleSetId +
                                          " (Method:" +
                                          credibleSet.method +
                                          ", PMID:" +
                                          credibleSet.pmid +
                                          ")"
                                      : credibleSet.credibleSetId +
                                          "(" +
                                          credibleSet.phenotype +
                                          ", " +
                                          credibleSet.dataset +
                                          ")"
                                  ),
                                },
                              })
                            }),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "absolute",
                        right: "10px",
                        top: "7px",
                      },
                    },
                    [
                      _vm._l(_vm.credibleSets, function (c) {
                        return [
                          _vm.pkgDataSelected
                            .filter((s) => s.type == "Credible Set")
                            .map((s) => s.id)
                            .indexOf(c.credibleSetId) > -1
                            ? _c("span", {
                                key: c.credibleSetId + ", " + c.phenotype,
                                staticClass: "CS-bubble",
                                style:
                                  "background-color:" +
                                  _vm.getColorIndex(
                                    c.credibleSetId + ", " + c.phenotype
                                  ) +
                                  ";",
                                attrs: {
                                  id: _vm.getBubbleId(
                                    c.credibleSetId,
                                    c.phenotype
                                  ),
                                },
                                domProps: {
                                  innerHTML: _vm._s(
                                    c.credibleSetId +
                                      ", " +
                                      c.phenotype +
                                      " &nbsp;<span class='remove'>X</span>"
                                  ),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeCSData(
                                      c.credibleSetId,
                                      c.phenotype
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "col-md-9",
              staticStyle: { display: "inline-block" },
              attrs: { id: "CSPlotWrapper" },
            },
            [
              _c("div", { staticClass: "hidden", attrs: { id: "CSInfoBox" } }, [
                _c(
                  "div",
                  {
                    staticClass: "fixed-info-box-close",
                    on: {
                      click: function ($event) {
                        return _vm.showHidePanel("#CSInfoBox", "closeBtn")
                      },
                    },
                  },
                  [_c("b-icon", { attrs: { icon: "x-circle-fill" } })],
                  1
                ),
                _c("div", {
                  staticClass: "info-box-content",
                  attrs: { id: "CSInfoBoxContent" },
                }),
              ]),
              _c("canvas", {
                class:
                  Object.keys(_vm.CSData).length > 0
                    ? "CS-plot"
                    : "CS-empty-plot",
                attrs: { id: "CSPlot", width: "", height: "" },
                on: {
                  mousemove: function ($event) {
                    return _vm.checkCSPosition($event)
                  },
                  mouseout: function ($event) {
                    return _vm.onMouseOut("CSInfoBox")
                  },
                  click: function ($event) {
                    return _vm.showHidePanel("#CSInfoBox")
                  },
                },
              }),
              _vm.pkgDataSelected
                .filter((s) => s.type == "Credible Set")
                .map((s) => s.id).length != 0 && _vm.credibleSets.length == 0
                ? _c("div", {
                    attrs: { id: "CSInitialMessage" },
                    domProps: {
                      innerHTML: _vm._s("There is no available credible set."),
                    },
                  })
                : _vm._e(),
            ]
          ),
          _c("div", {
            staticClass: "col-md-3",
            staticStyle: { display: "inline-block" },
          }),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", [
      _c("strong", [
        _vm._v(
          "Filter associated variants by credible set membership to view the set(s) of fine-mapped variants most likely to include the causal variant for this locus."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }