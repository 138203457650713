var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.diseaseGroup
    ? _c("div", [
        _c(
          "div",
          { class: "container-fluid " + _vm.diseaseGroup.name + "kp-footer" },
          [_vm._m(0)]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c(
        "a",
        {
          staticStyle: { color: "#fff !important" },
          attrs: { href: "https://kp4cd.org/" },
        },
        [
          _vm._v(" Powered by the "),
          _c(
            "span",
            {
              staticStyle: {
                "font-weight": "500",
                "font-size": "1.2em",
                "vertical-align": "-.1em",
              },
            },
            [_vm._v("HuGeAMP")]
          ),
          _c("span", { staticClass: "registered-mark" }, [
            _vm._v(
              "   |  ACCELERATING MEDICINES PARTNERSHIP and AMP are registered service marks of the U.S. Department of Health and Human Services. "
            ),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }