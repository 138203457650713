var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { class: this.wrapperClass + " " + _vm.contentID }, [
    this.isHover == false
      ? _c(
          "span",
          {
            class: "help-content-caller no-icon-" + this.noIcon,
            on: {
              click: function ($event) {
                return _vm.showHideHelpContent(_vm.contentID)
              },
            },
          },
          [_c("b-icon-plus-circle-fill")],
          1
        )
      : _vm._e(),
    this.isHover == true
      ? _c(
          "span",
          {
            class: "help-content-caller hover no-icon-" + this.noIcon,
            on: {
              mouseover: function ($event) {
                return _vm.getToolTipPosition(_vm.contentID)
              },
            },
          },
          [_c("b-icon-info-circle-fill")],
          1
        )
      : _vm._e(),
    this.isHover == false
      ? _c(
          "div",
          {
            staticClass: "help-content-modal hidden",
            attrs: { id: _vm.contentID },
          },
          [
            _c(
              "span",
              {
                staticClass: "help-content-close",
                on: {
                  click: function ($event) {
                    return _vm.showHideHelpContent(_vm.contentID)
                  },
                },
              },
              [_vm._v("+")]
            ),
            _c("div", {
              staticClass: "help-content-wrapper",
              domProps: { innerHTML: _vm._s(_vm.tooltipDocumentationContent) },
            }),
          ]
        )
      : _vm._e(),
    this.isHover == true
      ? _c(
          "div",
          {
            class: "help-hover-content-modal no-icon-" + this.noIcon,
            attrs: { id: _vm.contentID },
          },
          [
            _c("div", {
              staticClass: "help-content-wrapper",
              domProps: {
                innerHTML: _vm._s(
                  !!_vm.supplyText
                    ? _vm.supplyText
                    : _vm.tooltipDocumentationContent
                ),
              },
            }),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }