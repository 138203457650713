var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "mbm-plot-content row" }, [
    _c("div", { staticClass: "col-md-12 genes-plot-wrapper" }, [
      _c("div", {}, [
        _c(
          "div",
          {
            class: _vm.plotType == "region plot" ? "col-md-9" : "col-md-12",
            attrs: { id: "genesTrackWrapper" + _vm.sectionId },
          },
          [
            _c("canvas", {
              attrs: {
                id: "genesTrack" + _vm.sectionId,
                width: "",
                height: "",
              },
              on: { resize: _vm.onResize },
            }),
          ]
        ),
        _c("div", {
          class: _vm.plotType == "region plot" ? "col-md-3" : "",
          attrs: { id: "genesPEGWrapper" },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }