<template>
	<div>
		<div class="kc-hero">
			<div class="kc-wrap">
				<div class="blurb">
					<div class="logo"><img src="https://hugeampkpncms.org/sites/default/files/users/user32/cfde_kc_logo_c.svg"></div>
					<div><span class="highlight">The Knowledge Center</span> integrates data and knowledge generated by ground-breaking research programs in the NIH Common Fund Data Ecosystem.
						<span>Use the KC to see curated and precomputed analyses of data within and across Common Fund projects.</span>
					</div>
				</div>
			</div>
			<!-- move anything above this point out of the component -->
			<div class="overlay-window context-info">
				<div class="overlay-window-close" data-info-el="context-info" @click="hideInfoContent($event)">&#10005;</div>
				We defined <a href="/research.html?pageid=KC_research_contexts">four research contexts</a> and then used a combination of Large Language Models (LLM) and manual curation to weight the relevance of the knowledge produced by each CFDE program to each context (for details, <a href="/research.html?pageid=kc_documentation">see here</a>). <br>&nbsp;&nbsp;Setting a research context will change the ordering of DCCs on each of the KC pages and also modify the text describing and contextualizing the results shown. <br>&nbsp;&nbsp;You can change or remove a research context on any page.
			</div>
			<div class="map-bg">
				<div class="map-title boxed" style="display:none"><span>> </span>Explore the diagram with your mouse to see connections between research programs, their omics data types and biological entities, with relevance to different research contexts.
					<div class="how">Source<span class="btn-icon">?</span></div>
				</div>
				<div class="map-title2">
					<span>SET RESEARCH CONTEXT
						<span class="btn-icon" 
							data-info-el="context-info"
							@click="showInfoContent($event)"
						>
						?
						</span>
					</span>
					To tailor information shown throughout the KC to a selected research area.
				</div>
				<div class="map-pov-message">Click to set context</div>
				<div class="map" v-if="this.parsedData">
					<div class="povs">
						<div class="map-label hideable"><div>Research Context</div></div>
						<div v-for="(value, key) in this.parsedData[Object.keys(this.parsedData)[0]]['povs']"
							class="pov"
							data-group="povs"
							:data-value="key"
							@mouseover="hoverHandler2($event)"
							@mousemove="povMoveHandler($event)"
							@mouseout="outHandler($event)"
							@click="povHandler($event)">
							<div class="img">
								<img :src="`https://hugeampkpncms.org/sites/default/files/users/user32/kc_icons/${key}.svg`">
							</div>
							<span>{{ key.replace('-', ' ') }}</span>
						</div>
					</div>
					<div class="line2">
						<div class="map-label hideable">
							<div>
								<div>Relevance</div>
								<div class="relevance-legend">
									<div class="relevance-hi">high</div>
									<div class="relevance-md">med</div>
									<div class="relevance-lo">low</div>
								</div>
							</div>
						</div>
						<div class="line2info"></div>
						<div class="line2note"></div>
					</div>
					<div class="dccs">
						<div class="map-label hideable"><div>Common Fund Programs</div></div>
						<template v-for="(value, key, index) in this.parsedData">
							<div 
								class="dcc"
								data-group="dccs"
								:data-value="key"
								@mouseover="hoverHandler($event)"
								@mouseout="outHandler($event)"
							>
								<img :src="value['logo']">
							</div>
							<div class="dcc-separator"></div>
						</template>
					</div>
					<div class="omics">
						<div class="map-label hideable"><div>Omics Data</div></div>
						<div v-for="(value, key) in this.parsedData[Object.keys(this.parsedData)[0]]['omics']"
							class="omic"
							data-group="omics"
							:data-value="key"
							@mouseover="hoverHandler2($event)"
							@mouseout="outHandler($event)">
							<span>{{ key }}</span>
						</div>
					</div>
					<div class="entities">
						<div class="map-label hideable"><div>Searchable Entities</div></div>
						<div v-for="(value, key) in this.parsedData[Object.keys(this.parsedData)[0]]['entities']"
							class="entity"
							data-group="entities"
							:data-value="key"
							@mouseover="hoverHandler2($event)"
							@mouseout="outHandler($event)">
							<span>{{ key }}</span>
						</div>
					</div>
				</div>
			</div>
		
		
			<div class="section section-search-drc">
				<div class="section-title"><div class="drc-logo"><img src="https://hugeampkpncms.org/sites/default/files/users/user32/kc_icons/DRC_logo.png"></div>CFDE WORKBENCH</div>
				<div class="section-wrap">
					<div class="section-col">
						<div class="section-subtitle">Visit our sister resource to query, access, and compute Common Fund datasets.</div>
						<a href="https://data.cfde.cloud/" target="_blank">Visit CFDE WORKBENCH</a>
					</div>
				</div>
			</div>
			<div class="section section-search-kc">
				<div class="map-title2 v2">
					<span>SEARCH THE KC</span>
					<div class="search-context notset">Set <span class="search-context-label">genetics</span> context?<button @click="setContext()">yes</button></div>
					<div class="search-context set">Help text and results order will be tailored to a  <span class="search-context-label">genetics</span> use case.</div>
				</div>
				<research-single-search
					:single-search-config="sectionConfigs['content']"
					:phenotypes="phenotypesInUse"
					:utils="utilsBox"
				></research-single-search> 
				<div class="search-extras">
					<div class="search-try"><span>Try</span><a href="/research.html?entity=gene&gene=BDH2&pageid=kc_entity&tissue=blood">BDH2</a><a href="/research.html?disease=MONDO%3A0004985&entity=disease&pageid=kc_entity&tissue=blood">Bipolar disorder</a></div>
				</div>
			</div>
		</div>
<!-- Move everything bellow this point out of the component -->
		<div id="kc-section-a" class="section">
			<div class="section-title">See curated summaries of knowledge from CFDE programs</div>
			<div class="section-wrap">
				<div class="section-col">
					<div class="section-subtitle">Genes</div>
					<div class="placeholder">
						<div class="mini-card-video">
							<video 
								@ended="minVidEnd($event)" 
								@mouseover="minVidHover($event)"
								@mouseout="minVidHoverOut($event)"
								data-loop-max="1"
								data-loop-count="0"
								src="https://hugeampkpncms.org/sites/default/files/users/user32/kc_tools/KC_gene_page_480.mp4" 
								poster="https://hugeampkpncms.org/sites/default/files/users/user32/kc_tools/KC_gene_page.jpg" 
								autoplay muted playsinline 
							/>
                        </div>
					</div>
				</div>
				<div class="section-col">
					<div class="section-subtitle">Diseases</div>
					<div class="placeholder">
						<div class="mini-card-video">
                        	<video 
								@ended="minVidEnd($event)" 
								@mouseover="minVidHover($event)"
								@mouseout="minVidHoverOut($event)"
								data-loop-max="1"
								data-loop-count="0"
								src="https://hugeampkpncms.org/sites/default/files/users/user32/kc_tools/KC_disease_page_480.mp4" 
								poster="https://hugeampkpncms.org/sites/default/files/users/user32/kc_tools/KC_disease_page.jpg" 
								autoplay muted playsinline 
							/>
                        </div>
					</div>
				</div>
			</div>
		</div>

		<div id="kc-section-b" class="section">
			<div class="section-title">Knowledge about CFDE programs and partnerships</div>
			<div class="section-wrap">
				<div class="section-col">
					<div class="section-subtitle">CFDE programs</div>
					<div class="placeholder">
						<div class="mini-card-video">
							<a href="/research.html?pageid=kc_dccs">
								<video 
									@ended="minVidEnd($event)" 
									@mouseover="minVidHover($event)"
									@mouseout="minVidHoverOut($event)"
									data-loop-max="1"
									data-loop-count="0"
									src="https://hugeampkpncms.org/sites/default/files/users/user32/kc_tools/Landing_DCCs_480.mp4" 
									poster="https://hugeampkpncms.org/sites/default/files/users/user32/kc_tools/Landing_DCCs.jpg" 
									autoplay muted playsinline 
								/>
							</a>
                        </div>
					</div>
				</div>
				<div class="section-col">
					<div class="section-subtitle">Partnerships</div>
					<div class="placeholder">(coming soon)</div>
				</div>
			</div>
		</div>

		<div id="kc-section-c" class="section">
			<div class="section-title"><div class="drc-logo"><img src="https://hugeampkpncms.org/sites/default/files/users/user32/kc_icons/DRC_logo.png"></div>CFDE WORKBENCH</div>
			<div class="section-wrap">
				<div class="section-col">
					<div class="section-subtitle">Visit our sister resource to query, access, and compute Common Fund datasets.</div>
					<ul>
						<li>Search across all Common Fund metadata and processed data.</li>
						<li>Cloud tools to interrogate data sets from various Common Fund programs.</li>
						<li>Training and outreach to highlight Common Fund data and how to use it effectively.</li>
					</ul>
					<a href="https://data.cfde.cloud/" target="_blank">Visit CFDE WORKBENCH</a>
				</div>
			</div>
		</div>

		<!--<div id="kc-section-d" class="section">
			<div class="section-title">Funding</div>
			<div class="section-wrap">
				<div class="section-col">
					<div class="section-subtitle"></div>	
					<div class="logo"><img src="https://hugeampkpncms.org/sites/default/files/users/user32/kc_icons/NIH_logo.png"></div>
				</div>
			</div>
		</div>-->
	</div>
</template>

<script>
import Vue from "vue";
import $ from "jquery";
import ResearchSingleSearch from "@/components/researchPortal/ResearchSingleSearch.vue";
//import uiUtils from "@/utils/uiUtils";
import { BootstrapVueIcons } from "bootstrap-vue";

Vue.use(BootstrapVueIcons);

export default Vue.component("cfde-eco-system", {
	props: ["sectionConfigs", "phenotypesInUse", "utilsBox", "keyParams"],
	data() {
		return {
			parsedData: null,
			currPov: null,
			currHoverGroup: null,
			freezeSelection: false
		};
	},
	mounted() {
		//console.log('this.sectionConfigs', this.sectionConfigs);
		this.loadScript('https://cdn.jsdelivr.net/npm/leader-line@1.0.7/leader-line.min.js', async () => {
			const data = await this.loadFile(this.sectionConfigs["content"]["custom"]["viz data"])
			this.parsedData = await this.parseEntities(data);
			setTimeout(function () {
				const params = new URLSearchParams(document.location.search);
				const ctxtParam = params.get('context');
				const ctxt = ctxtParam ? ctxtParam.replace('_', '-') : 'genetics';
				const targetElement = document.querySelector(`[data-value="${ctxt}"]`);
				//console.log(targetElement);
				if (targetElement) {
					targetElement.dispatchEvent(new Event('mouseover'));
					targetElement.dispatchEvent(new Event('click'));
					targetElement.dispatchEvent(new Event('mouseout'));
				}
			}, 100);
		});	
		
		
	},
	computed: {},
	watch: {
	},
	updated() {
	},
	methods: {
		loadScript(src, callback) {
			return new Promise((resolve, reject) => {
				const script = document.createElement('script');
				script.src = src;
				script.async = true;
				script.onload = () => {
					if (callback) callback();
					resolve();
				};
				script.onerror = reject;
				document.head.appendChild(script);
			});
		},
		async loadFile(src){
			//must use dataset endpoint to load csv file from hugeampkpncms (otherwise CORS error)
			const fetchUrl = "https://hugeampkpncms.org/servedata/dataset?dataset=" + src;
			//however, dataset enpoint parses the original csv and escapes special characters
			let data = await fetch(fetchUrl).then(resp => resp.text(fetchUrl));
			//we must undo all of that to get the original data
			data = data.replace(/\\u0022/g, '"'); 		//quotes
			data = data.replace(/\\\//g, '/');			//slashes
			data = data.replace(/\\n/g, '\n');			//line breaks
			data = data.replace(/\\r/g, '\r');			//carriage returns
			data = data.substring(1, data.length - 1);	//remove surrounding quotes
			return data;
		},
		async parseEntities(data){
			const lines = data.split('\n');
            const headers = lines[0].split(',');
            const result = {};

            for (let i = 1; i < lines.length; i++) {
                const values = lines[i].match(/(".*?"|[^",\r\n]+|(?<=,)(?=,))/g);//.split(',');
                const dcc = values[0];
                result[dcc] = { entities: {}, omics: {}, povs: {}, logo: '', info: {} };

                for (let j = 1; j < headers.length; j++) {
                const [prefix, key] = headers[j].split('_');
                if (prefix && key) {
                    let group;
                    if (prefix === 'a') {
                        group = 'entities';
                    } else if (prefix === 'b') {
                        group = 'omics';
                    } else if (prefix === 'c') {
                        group = 'povs';
                    } else if (prefix === 'd') {
                        group = 'logo';
                        result[dcc][group] = values[j];
                        continue;
                    }else if (prefix === 'r'){
                        group = 'info';
                        result[dcc][group][key.trim()] = values[j];
                        continue;
                    }
                    result[dcc][group][key.trim()] = values[j] ? parseInt(values[j]): null;
                }
                }
            }

			return result;
			//this.parsedData = result;
			//console.log(this.parsedData);
		},
		outHandler(e){
            if(this.freezeSelection){
                document.querySelectorAll(`[data-group]`).forEach(item => item.classList.remove('on', 'on2', 'on3'));
				const targetElement = document.querySelector(`[data-value="${this.currPov}"]`);
				if (targetElement) targetElement.dispatchEvent(new Event('mouseover'));
            }else{
                document.querySelectorAll(`[data-group]`).forEach(item => item.classList.remove('on', 'on2', 'on3', 'onA', 'on2A', 'on3A'));
                document.querySelectorAll('.leader-line').forEach(item => item.remove());
                document.querySelector('.dccs').classList.remove('active');
            }
            document.querySelector(`.omics`).classList.remove('active');
            this.currHoverGroup = null;
            document.querySelector('.line2info').innerHTML = '';
			document.querySelector('.line2note').classList.remove('hidden');
			document.querySelector('.map-pov-message').style.display = 'none';
        },
		hoverHandler(e){
            const val = e.target.dataset.value;

			document.querySelector('.line2note').classList.add('hidden');

            //console.log(parsedData[val]['info'][this.currPov]);
            if(this.currPov) document.querySelector('.line2info').innerHTML = `<div><span style="font-weight:bold">${val}</span> ${this.parsedData[val]['info'][this.currPov].replace(/['"]+/g, '')}</div>`;

            //highlight entities
            if(this.currHoverGroup!=='entities'){
                for (const [key, value] of Object.entries(this.parsedData[val]['entities'])) {
                    if(value){
                        document.querySelector(`[data-value="${key}"]`).classList.add('on');
                    }
                }
            }
            
            //highlight omics
            if(this.currHoverGroup!=='omics'){
                document.querySelector(`.omics`).classList.add('active');
                for (const [key, value] of Object.entries(this.parsedData[val]['omics'])) {
                    if(value){
                        document.querySelector(`[data-value="${key}"]`).classList.add('on');
                    }
                }
            }
            //highlight povs
            if(this.currHoverGroup!=='povs'){
                for (const [key, value] of Object.entries(this.parsedData[val]['povs'])) {
                    //console.log(value, key);
                    if(value){
                        const el = document.querySelector(`[data-value="${key}"]`);
                        if(value===1){
                            if(!this.freezeSelection){
                                const l = new LeaderLine(
                                    e.target,
                                    document.querySelector(`[data-value="${key}"]`),
                                    {startSocket: 'top', endSocket: 'bottom', startPlug: 'behind', endPlug: 'behind', endSocketGravity: 100, 
                                    path: 'fluid', gradient: {endColor: '#ff7f5090', startColor:'#ff7f5090'}, dash: {len: 2, gap: 1, animation: false},  size: 1}
                                );
                            }
                        }
                        if(value===2){
                            if(!this.freezeSelection){
                                const l = new LeaderLine(
                                    e.target,
                                    document.querySelector(`[data-value="${key}"]`),
                                    {startSocket: 'top', endSocket: 'bottom', startPlug: 'behind', endPlug: 'behind', endSocketGravity: 80, 
                                    path: 'fluid', gradient: {endColor: '#ff7f5090', startColor:'#ff7f5090'}, dash: {len: 4, gap: 2, animation: false},  size: 2}
                                );
                            }
                        }
                        if(value===3){
                            if(!this.freezeSelection){
                                const l = new LeaderLine(
                                    e.target,
                                    document.querySelector(`[data-value="${key}"]`),
                                    {startSocket: 'top', endSocket: 'bottom', startPlug: 'behind', endPlug: 'behind', endSocketGravity: 60, 
                                    path: 'fluid', gradient: {endColor: '#ff7f5090', startColor:'#ff7f5090'}, dash: {len: 6, gap: 3, animation: false},  size: 3}
                                );
                            }
                        }
                        //el.classList.add(value===2 && !el.classList.contains('on')?'on2':'on');
                    }
                }
            }
        },
		hoverHandler2(e){
            //console.log('this.currHoverGroup', this.currHoverGroup);
            //if(this.currHoverGroup) return;	
            const group = e.target.dataset.group;
            let val = e.target.dataset.value;
			//console.log('continuing', group, val);
            this.currHoverGroup = group;
            //console.log('---this.currHoverGroup', this.currHoverGroup);
            if(group==='povs') {
				document.querySelector('.dccs').classList.add('active');
				document.querySelector('.line2note').classList.add('hidden');
				if(this.freezeSelection) document.querySelectorAll('.leader-line').forEach(item => item.remove());
				if(!e.target.classList.contains('selected')){
					document.querySelector('.map-pov-message').style.display = 'block';
				}
			}
			
            //highlight dccs
            for (const [key, value] of Object.entries(this.parsedData)) {
                const el = document.querySelector(`[data-value="${key}"]`);
                if(value[group][val]===1){
                    //el.classList.add('on');
                    if (group==='povs'){
						//console.log(group, val, this.currPov)
                        //if(this.freezeSelection){
                            el.classList.add('onA');
                            const l = new LeaderLine(
                                document.querySelector(`[data-value="${key}"]`),
                                e.target,
                                {startSocket: 'top', endSocket: 'bottom', startPlug: 'behind', startPlugColor: '#34679a', endPlug: 'behind', endSocketGravity: 100, 
                                path: 'fluid', gradient: {startColor: '#ff7f5090', endColor:'#ff7f5090'}, dash: {len: 2, gap: 1, animation: false},  size: 1}
                            );
						//}
                    }else{
                        el.classList.add('on');
                    }
                    //el.classList.add('on');
                    //el.dispatchEvent(new Event('mouseover'));
                }
                if(value[group][val]===2){
                    //el.classList.add('on2');
                    if (group==='povs'){
                        //if(!this.freezeSelection){
                            el.classList.add('on2A');
                            const l = new LeaderLine(
                                document.querySelector(`[data-value="${key}"]`),
                                e.target,
                                {startSocket: 'top', endSocket: 'bottom', startPlug: 'behind', startPlugColor: '#34679a', endPlug: 'behind', endSocketGravity: 80, 
                                path: 'fluid', gradient: {startColor: '#ff7f5090', endColor:'#ff7f5090'}, dash: {len: 4, gap: 2, animation: false},  size: 2}
                            );
                        //}
                    }else{
                        el.classList.add('on2');
                    }
                }
                if(value[group][val]===3){
                    //el.classList.add('on3');
                    if (group==='povs'){
                        //if(!this.freezeSelection){
                            el.classList.add('on3A');
                            const l = new LeaderLine(
                                document.querySelector(`[data-value="${key}"]`),
                                e.target,
                                {startSocket: 'top', endSocket: 'bottom', startPlug: 'behind', startPlugColor: '#34679a', endPlug: 'behind', endSocketGravity: 60, 
                                path: 'fluid', gradient: {startColor: '#ff7f5090', endColor:'#ff7f5090'}, dash: {len: 6, gap: 3, animation: false},  size: 3}
                            );
                        //}
                    }else{
                        el.classList.add('on3');
                    }
                }
            }
        },
		povMoveHandler(e){
			const el = document.querySelector('.map-pov-message');
			el.style.top = (e.clientY-90)+'px';
			el.style.left = (e.clientX+10)+'px';
		},
		povHandler(e){
            const group = e.target.dataset.group;
            const val = e.target.dataset.value;
            if(!e.target.classList.contains('selected')){
				document.querySelectorAll('[data-group="povs"]').forEach(el => {el.classList.remove('selected')});
                e.target.classList.add('selected');
                document.querySelector('.line2').classList.add('open');
                document.querySelector('.dccs').classList.add('active');
				document.querySelectorAll('.search-context-label').forEach(el => el.innerHTML = val);
				//console.log('CONTEXT', this.utilsBox.userUtils.getContext('_context_cfde'), val);
				const userContext = this.utilsBox.userUtils.getContext('_context_cfde') ? this.utilsBox.userUtils.getContext('_context_cfde').replace('_', '-') : null;
				
				console.log('userContext', userContext);

				if(!userContext || (userContext !== val)){
					document.querySelector('.search-context.set').style.display = 'none';
					document.querySelector('.search-context.notset').style.display = 'block';
				}else{
					document.querySelector('.search-context.set').style.display = 'block';
					document.querySelector('.search-context.notset').style.display = 'none';
				}
				
				if(val==='computational-biology'){
					document.querySelector('.section-search-drc').style.display = 'flex';
					document.querySelector('.section-search-kc').style.display = 'none';
				}else{
					document.querySelector('.section-search-drc').style.display = 'none';
					document.querySelector('.section-search-kc').style.display = 'flex';
				}
                this.currPov = val;
                this.freezeSelection = true;
				
            }else{
				e.target.classList.remove('selected');
                document.querySelector('.line2').classList.remove('open');
                document.querySelector('.dccs').classList.remove('active');
				document.querySelectorAll('.search-context-label').forEach(el => el.innerHTML = 'none');
				if(val==='computational-biology'){
					document.querySelector('.section-search-drc').style.display = 'none';
					document.querySelector('.section-search-kc').style.display = 'flex';
				}
                this.currPov = null;
                this.freezeSelection = false;
				this.utilsBox.userUtils.clearContext('_context_cfde');
				this.utilsBox.keyParams.set({ "context": '' });
            }
        },

		setContext(e){
			if(this.currPov && this.currPov!=='computational-biology'){
				const valUnderscored = this.currPov.toLowerCase().replace("-", "_")
				this.utilsBox.userUtils.saveContext('_context_cfde',valUnderscored);
				this.utilsBox.keyParams.set({ "context": valUnderscored });
				document.querySelector('.search-context.set').style.display = 'block';
				document.querySelector('.search-context.notset').style.display = 'none';
			}
		},


		async loadCSV(url) {
			await fetch(url)
			.then(response => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				//console.log(response.text());
			})
			.catch(error => {
				console.error('Error loading CSV:', error);
			});
		},

		minVidEnd(e){
			e.target.dataset.loopCount++;
			if(e.target.dataset.loopCount < e.target.dataset.loopMax){
				e.target.play();
			}else{
				e.target.classList.add('paused');
			}
		},
		minVidHover(e){
			if(e.target.classList.contains('paused')){
				e.target.play();
			}
		},
		minVidHoverOut(e){
			if(e.target.classList.contains('paused')){
				e.target.pause();
			}
		},

		showInfoContent(e){
			const infoEl = document.querySelector(`.${e.target.dataset.infoEl}`);
			infoEl.style.top = (e.clientY-90)+'px';
			infoEl.style.left = (e.clientX+10)+'px';
			infoEl.style.display = 'block';
		},
		hideInfoContent(e){
			const infoEl = document.querySelector(`.${e.target.dataset.infoEl}`);
			infoEl.style.top = (e.clientY-90)+'px';
			infoEl.style.left = (e.clientX+10)+'px';
			infoEl.style.display = 'none';
		}
	}
});

$(function () {
});
</script>
<style>

</style> 

