var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    !!_vm.subectionConfig["visualizer"]
      ? _c(
          "div",
          { staticClass: "sub-plot-wrapper" },
          [
            _c("research-section-visualizers", {
              attrs: {
                plotConfig: _vm.subectionConfig["visualizer"],
                plotData: _vm.subsectionData,
                phenotypeMap: _vm.phenotypeMap,
                colors: _vm.colors,
                plotMargin: _vm.plotMargin,
                sectionId: _vm.rowId.replaceAll(",", ""),
                utils: _vm.utils,
                searchParameters: _vm.rowId,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _c(
      "table",
      {
        staticClass:
          "table table-sm table-striped research-data-table subsection-table",
      },
      [
        _c("thead", [
          _c(
            "tr",
            _vm._l(_vm.getTopRows(), function (head) {
              return _c("th", [_c("span", [_vm._v(_vm._s(head))])])
            }),
            0
          ),
        ]),
        _c(
          "tbody",
          [
            _vm._l(_vm.subPageData, function (row) {
              return _c(
                "tr",
                _vm._l(_vm.getTopRows(), function (head) {
                  return _c(
                    "td",
                    [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.formatValue(row[head], head)),
                        },
                      }),
                      _vm.tableFormat["column formatting"] &&
                      _vm.tableFormat["column formatting"][head] &&
                      _vm.tableFormat["column formatting"][head].type.includes(
                        "copy to clipboard"
                      )
                        ? _c(
                            "b-btn",
                            {
                              staticClass: "copy-to-clipboard",
                              on: {
                                click: function ($event) {
                                  return _vm.utils.uiUtils.copy2Clipboard(
                                    row[head]
                                  )
                                },
                              },
                            },
                            [_vm._v("Copy")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              )
            }),
            _c("tr", [
              _c(
                "td",
                { attrs: { colspan: _vm.getTopRows().length } },
                [
                  _c(
                    "b-container",
                    {
                      staticClass:
                        "egl-table-page-ui-wrapper subsection-page-ui-left",
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s("Total rows: " + _vm.subsectionData.length)
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "b-container",
                    {
                      staticClass:
                        "egl-table-page-ui-wrapper subsection-page-ui-center",
                    },
                    [
                      _c("b-pagination", {
                        staticClass: "pagination-sm justify-content-center",
                        attrs: {
                          "total-rows": _vm.subsectionData.length,
                          "per-page": _vm.numberOfRows,
                          phenotypeMap: _vm.phenotypeMap,
                        },
                        model: {
                          value: _vm.currentPage,
                          callback: function ($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-container",
                    {
                      staticClass:
                        "egl-table-page-ui-wrapper subsection-page-ui-right",
                    },
                    [
                      _c("div", [
                        _c("strong", [_vm._v("Save data in section: ")]),
                        _c(
                          "div",
                          {
                            staticClass: "convert-2-csv btn-sm",
                            on: {
                              click: function ($event) {
                                return _vm.convertJson2Csv(
                                  _vm.subsectionData,
                                  _vm.rowId + "_subsection"
                                )
                              },
                            },
                          },
                          [_vm._v(" CSV ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "convert-2-csv btn-sm",
                            on: {
                              click: function ($event) {
                                return _vm.saveJson(
                                  _vm.subsectionData,
                                  _vm.rowId + "_subsection"
                                )
                              },
                            },
                          },
                          [_vm._v(" JSON ")]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }