var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mbm-plot-content row", attrs: { id: "rp_region_plot" } },
    [
      _vm.plotsList.length > 1
        ? _c(
            "div",
            { staticClass: "show-hide-plots col-md-12" },
            [
              _c("strong", [_vm._v("Show/hide region plots")]),
              _vm._l(_vm.plotsList, function (item, itemIndex) {
                return [
                  item != "Combined"
                    ? _c("span", {
                        key: item,
                        staticClass: "group-bubble",
                        style:
                          "background-color:" +
                          _vm.compareGroupColors[itemIndex] +
                          ";",
                        domProps: { innerHTML: _vm._s(item) },
                        on: {
                          click: function ($event) {
                            _vm.utils.uiUtils.showHideElement(
                              "plotsWrapper" + item.replaceAll(" ", "_")
                            )
                          },
                        },
                      })
                    : _vm._e(),
                  item == "Combined"
                    ? _c(
                        "span",
                        {
                          staticClass: "group-bubble reference",
                          staticStyle: {
                            "background-color": "#ffffff",
                            border: "solid 1px #666666",
                          },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.utils.uiUtils.showHideElement(
                                "plotsWrapperCombined"
                              )
                            },
                          },
                        },
                        [_vm._v(" Combined ")]
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "fixed-info-box hidden", attrs: { id: "fixedInfoBox" } },
        [
          _c(
            "div",
            {
              staticClass: "fixed-info-box-close",
              on: {
                click: function ($event) {
                  return _vm.showHidePanel("#fixedInfoBox")
                },
              },
            },
            [_c("b-icon", { attrs: { icon: "x-circle-fill" } })],
            1
          ),
          _c(
            "div",
            { staticClass: "fixed-info-box-content" },
            _vm._l(_vm.dotsClicked, function (d, dIndex) {
              return _c(
                "div",
                [
                  _c(
                    "div",
                    [
                      _c("strong", { domProps: { innerHTML: _vm._s(d) } }),
                      !!_vm.renderConfig["star key"] &&
                      _vm.checkStared(d) == true
                        ? _c("b-icon", {
                            staticStyle: {
                              color: "#ffcc00",
                              cursor: "pointer",
                              "margin-left": "4px",
                            },
                            attrs: { icon: "star-fill" },
                            on: {
                              click: function ($event) {
                                return _vm.removeStarItem(d)
                              },
                            },
                          })
                        : _vm._e(),
                      !!_vm.renderConfig["star key"] &&
                      _vm.checkStared(d) == false
                        ? _c("b-icon", {
                            staticStyle: {
                              color: "#ffcc00",
                              cursor: "pointer",
                              "margin-left": "4px",
                            },
                            attrs: { icon: "star" },
                            on: {
                              click: function ($event) {
                                return _vm.addStarItem(d)
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("strong", {
                        domProps: {
                          innerHTML: _vm._s("Set this LD reference for: "),
                        },
                      }),
                      _vm._l(_vm.plotsList, function (i, iIndex) {
                        return [
                          i != "Combined" && !!_vm.assoData[i].data[d]
                            ? _c("strong", {
                                staticClass: "group-bubble",
                                style:
                                  "background-color:" +
                                  _vm.compareGroupColors[iIndex] +
                                  ";",
                                domProps: { innerHTML: _vm._s(i) },
                                on: {
                                  click: function ($event) {
                                    return _vm.resetLdReference(i, d)
                                  },
                                },
                              })
                            : _vm._e(),
                          i == "Combined"
                            ? _c("strong", {
                                staticClass: "group-bubble",
                                staticStyle: { "background-color": "#dddddd" },
                                domProps: { innerHTML: _vm._s("All") },
                                on: {
                                  click: function ($event) {
                                    return _vm.resetLdReference("All", d)
                                  },
                                },
                              })
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                  _vm._l(_vm.plotsList, function (g, gIndex) {
                    return g != "Combined" && !!_vm.assoData[g].data[d]
                      ? _c(
                          "div",
                          _vm._l(
                            _vm.renderConfig["hover content"],
                            function (h, hIndex) {
                              return _c("div", [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      h +
                                        "(" +
                                        g +
                                        "):" +
                                        _vm.assoData[g].data[d][h]
                                    ),
                                  },
                                }),
                              ])
                            }
                          ),
                          0
                        )
                      : _vm._e()
                  }),
                ],
                2
              )
            }),
            0
          ),
        ]
      ),
      !!_vm.renderConfig.legend
        ? _c("div", {
            staticClass: "mbm-plot-legend col-md-12",
            domProps: { innerHTML: _vm._s(_vm.renderConfig.legend) },
          })
        : _vm._e(),
      _vm._l(_vm.plotsList, function (item, itemIndex) {
        return _c(
          "div",
          {
            staticClass: "col-md-12 region-plots-wrapper",
            attrs: { id: "plotsWrapper" + item.replaceAll(" ", "_") },
          },
          [
            _c("div", { staticClass: "col-md-9 region-plot-default-legend" }, [
              item != "Combined"
                ? _c("div", [
                    _c("span", {
                      staticClass: "plot-legend-dot",
                      staticStyle: { "background-color": "#824099cc" },
                    }),
                    _c("span", [_vm._v("Reference variant")]),
                    _c("span", {
                      staticClass: "plot-legend-dot",
                      staticStyle: { "background-color": "#d0363360" },
                    }),
                    _c("span", [_vm._v("1 > r2 >= 0.8")]),
                    _c("span", {
                      staticClass: "plot-legend-dot",
                      staticStyle: { "background-color": "#ee982d50" },
                    }),
                    _c("span", [_vm._v("0.8 > r2 >= 0.6")]),
                    _c("span", {
                      staticClass: "plot-legend-dot",
                      staticStyle: { "background-color": "#4db05240" },
                    }),
                    _c("span", [_vm._v("0.6 > r2 >= 0.4")]),
                    _c("span", {
                      staticClass: "plot-legend-dot",
                      staticStyle: { "background-color": "#32afd530" },
                    }),
                    _c("span", [_vm._v("0.4 > r2 >= 0.2")]),
                    _c("span", {
                      staticClass: "plot-legend-dot",
                      staticStyle: { "background-color": "#2074b620" },
                    }),
                    _c("span", [_vm._v("0.2 > r2 > 0")]),
                    _c("span", {
                      staticClass: "plot-legend-dot",
                      staticStyle: { "background-color": "#33333320" },
                    }),
                    _c("span", [_vm._v("No data")]),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "col-md-9 asso-plots-wrapper" }, [
              _c(
                "div",
                {
                  attrs: {
                    id:
                      "assoPlotsWrapper" +
                      item.replaceAll(" ", "_") +
                      _vm.sectionId,
                  },
                },
                [
                  item != "default"
                    ? _c("h6", {
                        class: "text color-" + itemIndex,
                        domProps: { innerHTML: _vm._s(item) },
                      })
                    : _vm._e(),
                  _c("canvas", {
                    staticClass: "asso-plot",
                    attrs: {
                      id:
                        "asso_plot_" +
                        item.replaceAll(" ", "_") +
                        _vm.sectionId,
                      width: "",
                      height: "",
                    },
                    on: {
                      resize: _vm.onResize,
                      click: function ($event) {
                        return _vm.checkPosition($event, item, "asso", "click")
                      },
                      mousemove: function ($event) {
                        return _vm.checkPosition($event, item, "asso", "move")
                      },
                      mouseout: function ($event) {
                        return _vm.onMouseOut(
                          "assoInfoBox" + item + _vm.sectionId
                        )
                      },
                    },
                  }),
                  _c("div", {
                    staticClass: "asso-info-box hidden",
                    attrs: {
                      id:
                        "assoInfoBox" +
                        item.replaceAll(" ", "_") +
                        _vm.sectionId,
                    },
                  }),
                ]
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "col-md-3 ld-plots-wrapper reference-area",
                attrs: {
                  id:
                    "ldPlotsWrapper" +
                    item.replaceAll(" ", "_") +
                    _vm.sectionId,
                },
              },
              [
                _c("h6", {
                  class: "text color-" + itemIndex,
                  domProps: {
                    innerHTML: _vm._s(
                      item != "default"
                        ? item + " <small>*Showing only with LD</small>"
                        : " <small>*Showing only with LD</small>"
                    ),
                  },
                }),
                _c("canvas", {
                  staticClass: "ld-plot",
                  attrs: {
                    id: "ld_plot_" + item.replaceAll(" ", "_") + _vm.sectionId,
                    width: "",
                    height: "",
                  },
                  on: {
                    resize: _vm.onResize,
                    click: function ($event) {
                      return _vm.checkPosition($event, item, "LD", "click")
                    },
                    mousemove: function ($event) {
                      return _vm.checkPosition($event, item, "LD", "move")
                    },
                    mouseout: function ($event) {
                      return _vm.onMouseOut("ldInfoBox" + item + _vm.sectionId)
                    },
                  },
                }),
                _c("div", {
                  staticClass: "ld-info-box hidden",
                  attrs: {
                    id: "ldInfoBox" + item.replaceAll(" ", "_") + _vm.sectionId,
                  },
                }),
              ]
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }