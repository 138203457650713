var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "multi-page-search-wrapper in-section-ui",
      class: _vm.displyingSearchNum == 0 ? "hidden-search" : "",
    },
    [
      _vm.searchParameters != null
        ? _c(
            "div",
            {
              staticClass:
                "filtering-ui-wrapper search-criteria multi-page-search",
              attrs: { id: "searchCriteria" },
            },
            [
              _c("h4", { staticClass: "card-title" }, [
                _vm._v("Build search criteria"),
              ]),
              _c(
                "div",
                { staticClass: "filtering-ui-content row" },
                [
                  _vm._l(
                    _vm.searchParameters,
                    function (parameter, paramIndex) {
                      return _c(
                        "div",
                        { key: parameter.parameter, staticClass: "col" },
                        [
                          _c("div", { staticClass: "label" }, [
                            _c("span", {
                              domProps: { innerHTML: _vm._s(parameter.label) },
                            }),
                          ]),
                          parameter.type == "api list" &&
                          _vm.parameterOptions[paramIndex].length <= 10
                            ? _c(
                                "select",
                                {
                                  staticClass:
                                    "custom-select custom-select-search",
                                  attrs: {
                                    id:
                                      "section_search_param_" +
                                      parameter.parameter,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.updateSearchInputByEvent(
                                        $event,
                                        paramIndex,
                                        parameter.parameter
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v(
                                      _vm._s("Set " + parameter.parameter)
                                    ),
                                  ]),
                                  _vm._l(
                                    _vm.parameterOptions[paramIndex],
                                    function (param) {
                                      return _c("option", {
                                        key: param.value,
                                        domProps: {
                                          value: param.value,
                                          innerHTML: _vm._s(param.label.trim()),
                                        },
                                      })
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          parameter.type == "api list" &&
                          _vm.parameterOptions[paramIndex].length > 10
                            ? [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.paramSearch[paramIndex],
                                      expression: "paramSearch[paramIndex]",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    id:
                                      "section_search_param_" +
                                      parameter.parameter,
                                  },
                                  domProps: {
                                    value: _vm.paramSearch[paramIndex],
                                  },
                                  on: {
                                    keyup: function ($event) {
                                      return _vm.getListOptions(
                                        $event,
                                        parameter
                                      )
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.paramSearch,
                                        paramIndex,
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            : _vm._e(),
                          parameter.type == "list" &&
                          parameter.values.length <= 10
                            ? _c(
                                "select",
                                {
                                  staticClass:
                                    "custom-select custom-select-search",
                                  attrs: {
                                    id:
                                      "section_search_param_" +
                                      parameter.parameter,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.updateSearchInputByEvent(
                                        $event,
                                        paramIndex,
                                        parameter.parameter
                                      )
                                    },
                                  },
                                },
                                _vm._l(parameter.values, function (param) {
                                  return _c("option", {
                                    key: param.value,
                                    domProps: {
                                      value: param.value,
                                      innerHTML: _vm._s(param.label.trim()),
                                    },
                                  })
                                }),
                                0
                              )
                            : _vm._e(),
                          parameter.type == "list" &&
                          parameter.values.length > 10
                            ? [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.paramSearch[paramIndex],
                                      expression: "paramSearch[paramIndex]",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    id:
                                      "section_search_param_" +
                                      parameter.parameter,
                                  },
                                  domProps: {
                                    value: _vm.paramSearch[paramIndex],
                                  },
                                  on: {
                                    keyup: function ($event) {
                                      return _vm.getListOptions(
                                        $event,
                                        parameter
                                      )
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.paramSearch,
                                        paramIndex,
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "custom-select custom-select-search long-list",
                                    style:
                                      !_vm.listOptions[parameter.parameter] ||
                                      _vm.listOptions[parameter.parameter]
                                        .length == 0
                                        ? "display:none !important;"
                                        : "",
                                    attrs: {
                                      id: "listOptions" + parameter.parameter,
                                      size:
                                        !!_vm.listOptions[
                                          parameter.parameter
                                        ] &&
                                        _vm.listOptions[parameter.parameter]
                                          .length >= 5
                                          ? 5
                                          : "auto",
                                    },
                                  },
                                  [
                                    _vm._l(
                                      _vm.listOptions[parameter.parameter],
                                      function (option) {
                                        return [
                                          _c("a", {
                                            key: option.value,
                                            staticClass:
                                              "custom-select-a-option",
                                            attrs: { href: "javascript:;" },
                                            domProps: {
                                              innerHTML: _vm._s(option.label),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.setListValue(
                                                  option.value,
                                                  parameter.parameter,
                                                  paramIndex,
                                                  true
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            : _vm._e(),
                          _c("div", [
                            parameter.type == "input" &&
                            parameter.values == "kp genes"
                              ? _c(
                                  "div",
                                  {
                                    style: !!parameter["expand region"]
                                      ? "display: inline-block;"
                                      : "",
                                    attrs: {
                                      id: "kp_gene_search_wrapper" + paramIndex,
                                    },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.paramSearch[paramIndex],
                                          expression: "paramSearch[paramIndex]",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        id:
                                          "section_search_param_" +
                                          parameter.parameter,
                                      },
                                      domProps: {
                                        value: _vm.paramSearch[paramIndex],
                                      },
                                      on: {
                                        keyup: function ($event) {
                                          return _vm.getGenes($event)
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.paramSearch,
                                            paramIndex,
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "custom-select custom-select-search",
                                        style:
                                          _vm.kpGenes.length == 0
                                            ? "display:none !important;"
                                            : "",
                                        attrs: {
                                          size:
                                            _vm.kpGenes.length >= 5
                                              ? 5
                                              : "auto",
                                        },
                                      },
                                      [
                                        _vm._l(_vm.kpGenes, function (gene) {
                                          return [
                                            _c("a", {
                                              key: gene,
                                              staticClass:
                                                "custom-select-a-option",
                                              attrs: { href: "javascript:;" },
                                              domProps: {
                                                innerHTML: _vm._s(gene),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  parameter[
                                                    "convert to region"
                                                  ] &&
                                                  parameter[
                                                    "convert to region"
                                                  ] == "true"
                                                    ? _vm.setGene(
                                                        gene,
                                                        parameter.parameter,
                                                        paramIndex,
                                                        true,
                                                        parameter[
                                                          "expand region by"
                                                        ]
                                                      )
                                                    : _vm.setGene(
                                                        gene,
                                                        parameter.parameter,
                                                        paramIndex
                                                      )
                                                },
                                              },
                                            }),
                                          ]
                                        }),
                                      ],
                                      2
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            !!parameter["expand region"]
                              ? _c("div", { staticClass: "expand-region" }, [
                                  _c(
                                    "select",
                                    {
                                      staticClass: "expand-region-select-byor",
                                      attrs: { id: "region_expander" },
                                      on: {
                                        change: function ($event) {
                                          return _vm.expandRegion(
                                            $event,
                                            parameter.parameter,
                                            paramIndex
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "option",
                                        {
                                          attrs: {
                                            selected: "selected",
                                            value: "null",
                                          },
                                        },
                                        [_vm._v(" Expand region by: ")]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "50000" } },
                                        [_vm._v("± 50 kb")]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "100000" } },
                                        [_vm._v("± 100 kb")]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "150000" } },
                                        [_vm._v("± 150 kb")]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          parameter.type == "input" &&
                          parameter.values != "kp genes"
                            ? _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id:
                                    "section_search_param_" +
                                    parameter.parameter,
                                },
                              })
                            : _vm._e(),
                        ],
                        2
                      )
                    }
                  ),
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "div",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        on: {
                          click: function ($event) {
                            return _vm.updateSearch()
                          },
                        },
                      },
                      [_vm._v(" Search ")]
                    ),
                  ]),
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "div",
                      {
                        staticClass: "btn btn-sm btn-warning",
                        on: {
                          click: function ($event) {
                            return _vm.resetSearch()
                          },
                        },
                      },
                      [_vm._v(" Reset ")]
                    ),
                  ]),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }