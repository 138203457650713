var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "kc-hero" }, [
      _vm._m(0),
      _c("div", { staticClass: "overlay-window context-info" }, [
        _c(
          "div",
          {
            staticClass: "overlay-window-close",
            attrs: { "data-info-el": "context-info" },
            on: {
              click: function ($event) {
                return _vm.hideInfoContent($event)
              },
            },
          },
          [_vm._v("✕")]
        ),
        _vm._v(" We defined "),
        _c(
          "a",
          { attrs: { href: "/research.html?pageid=KC_research_contexts" } },
          [_vm._v("four research contexts")]
        ),
        _vm._v(
          " and then used a combination of Large Language Models (LLM) and manual curation to weight the relevance of the knowledge produced by each CFDE program to each context (for details, "
        ),
        _c("a", { attrs: { href: "/research.html?pageid=kc_documentation" } }, [
          _vm._v("see here"),
        ]),
        _vm._v("). "),
        _c("br"),
        _vm._v(
          "  Setting a research context will change the ordering of DCCs on each of the KC pages and also modify the text describing and contextualizing the results shown. "
        ),
        _c("br"),
        _vm._v("  You can change or remove a research context on any page. "),
      ]),
      _c("div", { staticClass: "map-bg" }, [
        _vm._m(1),
        _c("div", { staticClass: "map-title2" }, [
          _c("span", [
            _vm._v("SET RESEARCH CONTEXT "),
            _c(
              "span",
              {
                staticClass: "btn-icon",
                attrs: { "data-info-el": "context-info" },
                on: {
                  click: function ($event) {
                    return _vm.showInfoContent($event)
                  },
                },
              },
              [_vm._v(" ? ")]
            ),
          ]),
          _vm._v(
            " To tailor information shown throughout the KC to a selected research area. "
          ),
        ]),
        _c("div", { staticClass: "map-pov-message" }, [
          _vm._v("Click to set context"),
        ]),
        this.parsedData
          ? _c("div", { staticClass: "map" }, [
              _c(
                "div",
                { staticClass: "povs" },
                [
                  _vm._m(2),
                  _vm._l(
                    this.parsedData[Object.keys(this.parsedData)[0]]["povs"],
                    function (value, key) {
                      return _c(
                        "div",
                        {
                          staticClass: "pov",
                          attrs: { "data-group": "povs", "data-value": key },
                          on: {
                            mouseover: function ($event) {
                              return _vm.hoverHandler2($event)
                            },
                            mousemove: function ($event) {
                              return _vm.povMoveHandler($event)
                            },
                            mouseout: function ($event) {
                              return _vm.outHandler($event)
                            },
                            click: function ($event) {
                              return _vm.povHandler($event)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "img" }, [
                            _c("img", {
                              attrs: {
                                src: `https://hugeampkpncms.org/sites/default/files/users/user32/kc_icons/${key}.svg`,
                              },
                            }),
                          ]),
                          _c("span", [_vm._v(_vm._s(key.replace("-", " ")))]),
                        ]
                      )
                    }
                  ),
                ],
                2
              ),
              _vm._m(3),
              _c(
                "div",
                { staticClass: "dccs" },
                [
                  _vm._m(4),
                  _vm._l(this.parsedData, function (value, key, index) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "dcc",
                          attrs: { "data-group": "dccs", "data-value": key },
                          on: {
                            mouseover: function ($event) {
                              return _vm.hoverHandler($event)
                            },
                            mouseout: function ($event) {
                              return _vm.outHandler($event)
                            },
                          },
                        },
                        [_c("img", { attrs: { src: value["logo"] } })]
                      ),
                      _c("div", { staticClass: "dcc-separator" }),
                    ]
                  }),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "omics" },
                [
                  _vm._m(5),
                  _vm._l(
                    this.parsedData[Object.keys(this.parsedData)[0]]["omics"],
                    function (value, key) {
                      return _c(
                        "div",
                        {
                          staticClass: "omic",
                          attrs: { "data-group": "omics", "data-value": key },
                          on: {
                            mouseover: function ($event) {
                              return _vm.hoverHandler2($event)
                            },
                            mouseout: function ($event) {
                              return _vm.outHandler($event)
                            },
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(key))])]
                      )
                    }
                  ),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "entities" },
                [
                  _vm._m(6),
                  _vm._l(
                    this.parsedData[Object.keys(this.parsedData)[0]][
                      "entities"
                    ],
                    function (value, key) {
                      return _c(
                        "div",
                        {
                          staticClass: "entity",
                          attrs: {
                            "data-group": "entities",
                            "data-value": key,
                          },
                          on: {
                            mouseover: function ($event) {
                              return _vm.hoverHandler2($event)
                            },
                            mouseout: function ($event) {
                              return _vm.outHandler($event)
                            },
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(key))])]
                      )
                    }
                  ),
                ],
                2
              ),
            ])
          : _vm._e(),
      ]),
      _vm._m(7),
      _c(
        "div",
        { staticClass: "section section-search-kc" },
        [
          _c("div", { staticClass: "map-title2 v2" }, [
            _c("span", [_vm._v("SEARCH THE KC")]),
            _c("div", { staticClass: "search-context notset" }, [
              _vm._v("Set "),
              _c("span", { staticClass: "search-context-label" }, [
                _vm._v("genetics"),
              ]),
              _vm._v(" context?"),
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.setContext()
                    },
                  },
                },
                [_vm._v("yes")]
              ),
            ]),
            _vm._m(8),
          ]),
          _c("research-single-search", {
            attrs: {
              "single-search-config": _vm.sectionConfigs["content"],
              phenotypes: _vm.phenotypesInUse,
              utils: _vm.utilsBox,
            },
          }),
          _vm._m(9),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "section", attrs: { id: "kc-section-a" } }, [
      _c("div", { staticClass: "section-title" }, [
        _vm._v("See curated summaries of knowledge from CFDE programs"),
      ]),
      _c("div", { staticClass: "section-wrap" }, [
        _c("div", { staticClass: "section-col" }, [
          _c("div", { staticClass: "section-subtitle" }, [_vm._v("Genes")]),
          _c("div", { staticClass: "placeholder" }, [
            _c("div", { staticClass: "mini-card-video" }, [
              _c("video", {
                attrs: {
                  "data-loop-max": "1",
                  "data-loop-count": "0",
                  src: "https://hugeampkpncms.org/sites/default/files/users/user32/kc_tools/KC_gene_page_480.mp4",
                  poster:
                    "https://hugeampkpncms.org/sites/default/files/users/user32/kc_tools/KC_gene_page.jpg",
                  autoplay: "",
                  muted: "",
                  playsinline: "",
                },
                domProps: { muted: true },
                on: {
                  ended: function ($event) {
                    return _vm.minVidEnd($event)
                  },
                  mouseover: function ($event) {
                    return _vm.minVidHover($event)
                  },
                  mouseout: function ($event) {
                    return _vm.minVidHoverOut($event)
                  },
                },
              }),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "section-col" }, [
          _c("div", { staticClass: "section-subtitle" }, [_vm._v("Diseases")]),
          _c("div", { staticClass: "placeholder" }, [
            _c("div", { staticClass: "mini-card-video" }, [
              _c("video", {
                attrs: {
                  "data-loop-max": "1",
                  "data-loop-count": "0",
                  src: "https://hugeampkpncms.org/sites/default/files/users/user32/kc_tools/KC_disease_page_480.mp4",
                  poster:
                    "https://hugeampkpncms.org/sites/default/files/users/user32/kc_tools/KC_disease_page.jpg",
                  autoplay: "",
                  muted: "",
                  playsinline: "",
                },
                domProps: { muted: true },
                on: {
                  ended: function ($event) {
                    return _vm.minVidEnd($event)
                  },
                  mouseover: function ($event) {
                    return _vm.minVidHover($event)
                  },
                  mouseout: function ($event) {
                    return _vm.minVidHoverOut($event)
                  },
                },
              }),
            ]),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "section", attrs: { id: "kc-section-b" } }, [
      _c("div", { staticClass: "section-title" }, [
        _vm._v("Knowledge about CFDE programs and partnerships"),
      ]),
      _c("div", { staticClass: "section-wrap" }, [
        _c("div", { staticClass: "section-col" }, [
          _c("div", { staticClass: "section-subtitle" }, [
            _vm._v("CFDE programs"),
          ]),
          _c("div", { staticClass: "placeholder" }, [
            _c("div", { staticClass: "mini-card-video" }, [
              _c("a", { attrs: { href: "/research.html?pageid=kc_dccs" } }, [
                _c("video", {
                  attrs: {
                    "data-loop-max": "1",
                    "data-loop-count": "0",
                    src: "https://hugeampkpncms.org/sites/default/files/users/user32/kc_tools/Landing_DCCs_480.mp4",
                    poster:
                      "https://hugeampkpncms.org/sites/default/files/users/user32/kc_tools/Landing_DCCs.jpg",
                    autoplay: "",
                    muted: "",
                    playsinline: "",
                  },
                  domProps: { muted: true },
                  on: {
                    ended: function ($event) {
                      return _vm.minVidEnd($event)
                    },
                    mouseover: function ($event) {
                      return _vm.minVidHover($event)
                    },
                    mouseout: function ($event) {
                      return _vm.minVidHoverOut($event)
                    },
                  },
                }),
              ]),
            ]),
          ]),
        ]),
        _vm._m(10),
      ]),
    ]),
    _vm._m(11),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kc-wrap" }, [
      _c("div", { staticClass: "blurb" }, [
        _c("div", { staticClass: "logo" }, [
          _c("img", {
            attrs: {
              src: "https://hugeampkpncms.org/sites/default/files/users/user32/cfde_kc_logo_c.svg",
            },
          }),
        ]),
        _c("div", [
          _c("span", { staticClass: "highlight" }, [
            _vm._v("The Knowledge Center"),
          ]),
          _vm._v(
            " integrates data and knowledge generated by ground-breaking research programs in the NIH Common Fund Data Ecosystem. "
          ),
          _c("span", [
            _vm._v(
              "Use the KC to see curated and precomputed analyses of data within and across Common Fund projects."
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "map-title boxed", staticStyle: { display: "none" } },
      [
        _c("span", [_vm._v("> ")]),
        _vm._v(
          "Explore the diagram with your mouse to see connections between research programs, their omics data types and biological entities, with relevance to different research contexts. "
        ),
        _c("div", { staticClass: "how" }, [
          _vm._v("Source"),
          _c("span", { staticClass: "btn-icon" }, [_vm._v("?")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "map-label hideable" }, [
      _c("div", [_vm._v("Research Context")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "line2" }, [
      _c("div", { staticClass: "map-label hideable" }, [
        _c("div", [
          _c("div", [_vm._v("Relevance")]),
          _c("div", { staticClass: "relevance-legend" }, [
            _c("div", { staticClass: "relevance-hi" }, [_vm._v("high")]),
            _c("div", { staticClass: "relevance-md" }, [_vm._v("med")]),
            _c("div", { staticClass: "relevance-lo" }, [_vm._v("low")]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "line2info" }),
      _c("div", { staticClass: "line2note" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "map-label hideable" }, [
      _c("div", [_vm._v("Common Fund Programs")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "map-label hideable" }, [
      _c("div", [_vm._v("Omics Data")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "map-label hideable" }, [
      _c("div", [_vm._v("Searchable Entities")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "section section-search-drc" }, [
      _c("div", { staticClass: "section-title" }, [
        _c("div", { staticClass: "drc-logo" }, [
          _c("img", {
            attrs: {
              src: "https://hugeampkpncms.org/sites/default/files/users/user32/kc_icons/DRC_logo.png",
            },
          }),
        ]),
        _vm._v("CFDE WORKBENCH"),
      ]),
      _c("div", { staticClass: "section-wrap" }, [
        _c("div", { staticClass: "section-col" }, [
          _c("div", { staticClass: "section-subtitle" }, [
            _vm._v(
              "Visit our sister resource to query, access, and compute Common Fund datasets."
            ),
          ]),
          _c(
            "a",
            { attrs: { href: "https://data.cfde.cloud/", target: "_blank" } },
            [_vm._v("Visit CFDE WORKBENCH")]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "search-context set" }, [
      _vm._v("Help text and results order will be tailored to a "),
      _c("span", { staticClass: "search-context-label" }, [_vm._v("genetics")]),
      _vm._v(" use case."),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "search-extras" }, [
      _c("div", { staticClass: "search-try" }, [
        _c("span", [_vm._v("Try")]),
        _c(
          "a",
          {
            attrs: {
              href: "/research.html?entity=gene&gene=BDH2&pageid=kc_entity&tissue=blood",
            },
          },
          [_vm._v("BDH2")]
        ),
        _c(
          "a",
          {
            attrs: {
              href: "/research.html?disease=MONDO%3A0004985&entity=disease&pageid=kc_entity&tissue=blood",
            },
          },
          [_vm._v("Bipolar disorder")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "section-col" }, [
      _c("div", { staticClass: "section-subtitle" }, [_vm._v("Partnerships")]),
      _c("div", { staticClass: "placeholder" }, [_vm._v("(coming soon)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "section", attrs: { id: "kc-section-c" } },
      [
        _c("div", { staticClass: "section-title" }, [
          _c("div", { staticClass: "drc-logo" }, [
            _c("img", {
              attrs: {
                src: "https://hugeampkpncms.org/sites/default/files/users/user32/kc_icons/DRC_logo.png",
              },
            }),
          ]),
          _vm._v("CFDE WORKBENCH"),
        ]),
        _c("div", { staticClass: "section-wrap" }, [
          _c("div", { staticClass: "section-col" }, [
            _c("div", { staticClass: "section-subtitle" }, [
              _vm._v(
                "Visit our sister resource to query, access, and compute Common Fund datasets."
              ),
            ]),
            _c("ul", [
              _c("li", [
                _vm._v(
                  "Search across all Common Fund metadata and processed data."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Cloud tools to interrogate data sets from various Common Fund programs."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Training and outreach to highlight Common Fund data and how to use it effectively."
                ),
              ]),
            ]),
            _c(
              "a",
              { attrs: { href: "https://data.cfde.cloud/", target: "_blank" } },
              [_vm._v("Visit CFDE WORKBENCH")]
            ),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }