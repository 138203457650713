var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.renderData.length > 0
    ? _c(
        "div",
        {
          staticClass: "scatter-plot-content row",
          staticStyle: {
            display: "flex",
            "flex-direction": "row",
            "justify-content": "center",
          },
          attrs: { id: "rp_scatter_plot" },
        },
        [
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c("div", {}, [
                _c(
                  "div",
                  {
                    staticClass: "scatter-dot-value hidden",
                    attrs: { id: "scatter_dot_value" + _vm.sectionId },
                    on: {
                      mouseenter: function ($event) {
                        return _vm.checkPosition($event, "", "enter popup")
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "fixed-info-box-close",
                        style: !!_vm.isDotPanelClick
                          ? "display:block"
                          : "display:none",
                        on: {
                          click: function ($event) {
                            return _vm.checkPosition($event, "", "click")
                          },
                        },
                      },
                      [_c("b-icon", { attrs: { icon: "x-circle-fill" } })],
                      1
                    ),
                    _c("div", {
                      staticClass: "scatter-dot-value-content",
                      attrs: {
                        id: "scatter_dot_value_content" + _vm.sectionId,
                      },
                    }),
                  ]
                ),
                !!_vm.renderConfig && !_vm.groupsList && !_vm.multiList
                  ? _c("canvas", {
                      staticClass: "scatter-plot",
                      style:
                        "width:" +
                        _vm.plotDimension.width / 2 +
                        "px;height:" +
                        _vm.plotDimension.height / 2 +
                        "px;",
                      attrs: {
                        id: "scatterPlot" + _vm.sectionId,
                        width: _vm.plotDimension.width,
                        height: _vm.plotDimension.height,
                      },
                      on: {
                        mousemove: function ($event) {
                          return _vm.checkPosition($event, "", "move")
                        },
                        click: function ($event) {
                          return _vm.checkPosition($event, "", "click")
                        },
                        mouseleave: function ($event) {
                          return _vm.checkPosition($event, "", "leave")
                        },
                      },
                    })
                  : _vm._e(),
              ]),
              !!_vm.renderConfig && !!_vm.multiList
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "scatter-plot-groups",
                        staticStyle: { display: "flex", "flex-wrap": "wrap" },
                      },
                      _vm._l(_vm.multiList, function (fieldpair, index) {
                        return _c(
                          "div",
                          { staticClass: "scatter-plot-group" },
                          [
                            _c(
                              "div",
                              { staticClass: "colors-list" },
                              _vm._l(
                                _vm.colorsList,
                                function (anno, annoIndex) {
                                  return _c(
                                    "div",
                                    { staticClass: "anno-bubble-wrapper" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "anno-bubble",
                                          style:
                                            "background-color:" +
                                            _vm.compareGroupColors[
                                              annoIndex % 16
                                            ],
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _c("span", [_vm._v(_vm._s(anno))]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                            _c("canvas", {
                              key: _vm.sectionId + "multi" + index,
                              staticClass: "scatter-plot",
                              style:
                                "width:" +
                                _vm.plotDimension.width / 2 +
                                "px;height:" +
                                _vm.plotDimension.height / 2 +
                                "px;",
                              attrs: {
                                id:
                                  "scatterPlot" +
                                  _vm.sectionId +
                                  "multi" +
                                  index,
                                width: _vm.plotDimension.width,
                                height: _vm.plotDimension.height,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.checkPosition(
                                    $event,
                                    "multi" + index,
                                    "click"
                                  )
                                },
                                mousemove: function ($event) {
                                  return _vm.checkPosition(
                                    $event,
                                    "multi" + index,
                                    "move"
                                  )
                                },
                                mouseleave: function ($event) {
                                  return _vm.checkPosition($event, "", "leave")
                                },
                              },
                            }),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                : _vm._e(),
              !_vm.multiList && !_vm.groupsList
                ? _c(
                    "div",
                    {
                      staticClass: "plot-extras",
                      staticStyle: { "padding-top": "35px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "column",
                              },
                            },
                            [
                              !!_vm.renderConfig &&
                              _vm.renderConfig["y axis fields"].length > 1
                                ? _c("label", [_vm._v(" Y Axis ")])
                                : _vm._e(),
                              _c(
                                "select",
                                {
                                  staticClass: "y-axis-select",
                                  on: {
                                    change: function ($event) {
                                      return _vm.setPlotAxisIndex($event, "y")
                                    },
                                  },
                                },
                                _vm._l(
                                  _vm.renderConfig["y axis fields"],
                                  function (option, index) {
                                    return _c(
                                      "option",
                                      {
                                        domProps: {
                                          value: option,
                                          selected:
                                            _vm.renderConfig["y axis index"] ===
                                            option,
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(option) + " ")]
                                    )
                                  }
                                ),
                                0
                              ),
                              !!_vm.renderConfig &&
                              _vm.renderConfig["x axis fields"].length > 1
                                ? _c("label", [_vm._v(" X Axis ")])
                                : _vm._e(),
                              _c(
                                "select",
                                {
                                  staticClass: "x-axis-select",
                                  on: {
                                    change: function ($event) {
                                      return _vm.setPlotAxisIndex($event, "x")
                                    },
                                  },
                                },
                                _vm._l(
                                  _vm.renderConfig["x axis fields"],
                                  function (option, index) {
                                    return _c(
                                      "option",
                                      {
                                        domProps: {
                                          value: option,
                                          selected:
                                            _vm.renderConfig["x axis field"] ===
                                            option,
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(option) + " ")]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "color-key-options",
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "column",
                              },
                            },
                            [
                              !!_vm.renderConfig &&
                              _vm.renderConfig["color by"].length > 1
                                ? _c("label", [_vm._v(" Color by ")])
                                : _vm._e(),
                              _c(
                                "select",
                                {
                                  staticClass: "color-by-select",
                                  on: {
                                    change: function ($event) {
                                      return _vm.setColorField($event)
                                    },
                                  },
                                },
                                _vm._l(
                                  _vm.renderConfig["color by"],
                                  function (option, index) {
                                    return _c(
                                      "option",
                                      {
                                        domProps: {
                                          value:
                                            typeof option === "object"
                                              ? option["field"]
                                              : option,
                                          selected:
                                            _vm.renderConfig["color by"] ===
                                            index,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              typeof option === "object"
                                                ? option["field"]
                                                : option
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                              !!_vm.renderConfig &&
                              !!_vm.colorByList &&
                              !_vm.colorByGradient
                                ? _c(
                                    "div",
                                    { staticClass: "color-key" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                            "font-size": "10px",
                                            "font-style": "italic",
                                          },
                                        },
                                        [_vm._v("click key to highlight")]
                                      ),
                                      _vm._l(
                                        _vm.colorByList[_vm.colorByField],
                                        function (anno, annoIndex) {
                                          return _c(
                                            "div",
                                            {
                                              staticClass:
                                                "anno-bubble-wrapper",
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.setHighlightField(
                                                    $event,
                                                    anno
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "anno-bubble",
                                                  style:
                                                    "background-color:" +
                                                    _vm.compareGroupColors[
                                                      annoIndex % 16
                                                    ],
                                                },
                                                [_vm._v(" ")]
                                              ),
                                              _c("span", [
                                                _vm._v(_vm._s(anno)),
                                              ]),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              !!_vm.renderConfig &&
                              !!_vm.colorByList &&
                              !!_vm.colorByGradient
                                ? _c("div", { staticClass: "color-key" }, [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "text-align": "right",
                                          "font-size": "10px",
                                          "font-style": "italic",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "drag sliders to highlight in range"
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "color-gradient-wrapper",
                                        on: {
                                          mousedown: function ($event) {
                                            return _vm.gradientHandleDown(
                                              $event
                                            )
                                          },
                                          mousemove: function ($event) {
                                            return _vm.gradientHandleMove(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "color-gradient",
                                          style: _vm.colorGradient(),
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "color-gradient-handle color-gradient-max",
                                            attrs: { "data-val": "" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.gradientMinMax.currMax
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "color-gradient-handle color-gradient-min",
                                            attrs: { "data-val": "" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.gradientMinMax.currMin
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
          !!_vm.renderConfig && !!_vm.groupsList
            ? [
                _c(
                  "div",
                  { staticClass: "colors-list" },
                  _vm._l(_vm.colorsList, function (anno, annoIndex) {
                    return _c("div", { staticClass: "anno-bubble-wrapper" }, [
                      _c(
                        "span",
                        {
                          staticClass: "anno-bubble",
                          style:
                            "background-color:" +
                            _vm.compareGroupColors[annoIndex % 16],
                        },
                        [_vm._v(" ")]
                      ),
                      _c("span", [_vm._v(_vm._s(anno))]),
                    ])
                  }),
                  0
                ),
                _vm._l(_vm.groupsList, function (group) {
                  return _c("canvas", {
                    key: group,
                    staticClass: "scatter-plot",
                    style:
                      "width:" +
                      _vm.plotDimension.width / 2 +
                      "px;height:" +
                      _vm.plotDimension.height / 2 +
                      "px;",
                    attrs: {
                      id: "scatterPlot" + _vm.sectionId + group,
                      width: _vm.plotDimension.width,
                      height: _vm.plotDimension.height,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.checkPosition($event, group, "click")
                      },
                      mousemove: function ($event) {
                        return _vm.checkPosition($event, group, "move")
                      },
                      mouseleave: function ($event) {
                        return _vm.checkPosition($event, "", "leave")
                      },
                    },
                  })
                }),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }