var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "research-header-menu-wrapper container-fluid" },
    [
      _vm.headerLogo != null
        ? _c("div", {
            staticClass: "rp-header-logo",
            domProps: { innerHTML: _vm._s(_vm.headerLogo) },
          })
        : _vm._e(),
      !!_vm.sectionConfig && !!_vm.sectionConfig["header logo"]
        ? _c("div", { staticClass: "rp-header-logo" }, [
            _c("p", { staticClass: "kp-logo-wrapper" }, [
              _c("img", {
                staticClass: "kp-logo",
                attrs: { src: _vm.getLogo(_vm.sectionConfig["header logo"]) },
              }),
            ]),
          ])
        : _vm._e(),
      !!_vm.researchMenu
        ? _c(
            "ul",
            [
              _vm.addSearch
                ? [
                    _c("li", { staticClass: "menu menu-search" }, [
                      _c("a", { on: { click: _vm.activateSearch } }, [
                        _c(
                          "svg",
                          {
                            attrs: {
                              viewBox: "0 0 24 24",
                              fill: "none",
                              stroke: "#1c5084",
                              "stroke-width": ".5",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                fill: "#1c5084",
                                "fill-rule": "evenodd",
                                "clip-rule": "evenodd",
                                d: "M15 10.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm-.82 4.74a6 6 0 1 1 1.06-1.06l4.79 4.79-1.06 1.06-4.79-4.79Z",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" Search "),
                      ]),
                    ]),
                  ]
                : _vm._e(),
              _vm._l(
                _vm.researchMenu.length
                  ? _vm.researchMenu
                  : _vm.researchMenu.menu,
                function (menu) {
                  return _c("li", { key: menu.label, staticClass: "menu" }, [
                    _c("a", { attrs: { href: menu.link } }, [
                      _vm._v(_vm._s(menu.label)),
                    ]),
                    !!menu.subMenu
                      ? _c(
                          "ul",
                          { staticClass: "sub-menu-wrapper" },
                          _vm._l(menu.subMenu, function (subMenu) {
                            return _c(
                              "li",
                              { key: subMenu.label, staticClass: "sub-menu" },
                              [
                                _c("a", { attrs: { href: subMenu.link } }, [
                                  _vm._v(_vm._s(subMenu.label)),
                                ]),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ])
                }
              ),
            ],
            2
          )
        : _vm._e(),
      !!_vm.sectionConfig
        ? _c(
            "ul",
            _vm._l(_vm.sectionConfig.menu.items, function (menu) {
              return _c("li", { key: menu.label, staticClass: "menu" }, [
                _c("a", { attrs: { href: _vm.getLink(menu.link) } }, [
                  _vm._v(_vm._s(menu.label)),
                ]),
                !!menu.subMenu
                  ? _c(
                      "ul",
                      { staticClass: "sub-menu-wrapper" },
                      _vm._l(menu.subMenu, function (subMenu) {
                        return _c(
                          "li",
                          { key: subMenu.label, staticClass: "sub-menu" },
                          [
                            _c(
                              "a",
                              { attrs: { href: _vm.getLink(subMenu.link) } },
                              [_vm._v(_vm._s(subMenu.label))]
                            ),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ])
            }),
            0
          )
        : _vm._e(),
      _c("div", {
        ref: "searchContainer",
        staticClass: "menu-search-container",
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }