var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      (!!this.dataset && !!this.filters && this.filters.length > 0) ||
      (!!this.dataFiles && this.dataFiles.length > 1)
        ? _c("div", { staticClass: "filtering-ui-wrapper vertical" }, [
            _c("h4", { staticClass: "card-title filter" }, [
              _vm._v("Filter data"),
            ]),
            _c(
              "div",
              { staticClass: "filtering-ui-content row" },
              _vm._l(this.filters, function (filter) {
                return _c(
                  "div",
                  { key: filter.field, class: _vm.getFilterWidthClasses() },
                  [
                    filter.type != "checkbox"
                      ? _c("div", {
                          staticClass: "label",
                          domProps: { innerHTML: _vm._s(filter.label) },
                        })
                      : _vm._e(),
                    filter.type == "search"
                      ? [
                          !!filter.features &&
                          !!filter.features.includes("autocomplete")
                            ? _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id:
                                    "filter_" +
                                    _vm.sectionId +
                                    _vm.getColumnId(filter.field),
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.buildSuggestions(
                                      $event,
                                      filter.field
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                          !filter.features ||
                          (!!filter.features &&
                            !filter.features.includes("autocomplete"))
                            ? _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id:
                                    "filter_" +
                                    _vm.sectionId +
                                    _vm.getColumnId(filter.field),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.filterData(
                                      $event,
                                      filter.field,
                                      filter.type
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                          !!filter.features &&
                          !!filter.features.includes("autocomplete")
                            ? _c(
                                "div",
                                { staticClass: "autocomplete-options" },
                                [
                                  _c(
                                    "ul",
                                    [
                                      _vm._l(
                                        _vm.suggestions.suggestions,
                                        function (suggestion) {
                                          return _vm.suggestions.field ==
                                            filter.field &&
                                            _vm.suggestions.suggestions.length >
                                              0
                                            ? [
                                                _c("li", {
                                                  attrs: { title: suggestion },
                                                  domProps: {
                                                    innerHTML:
                                                      _vm._s(suggestion),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.filterData(
                                                        $event,
                                                        filter.field,
                                                        filter.type,
                                                        "",
                                                        suggestion
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            : _vm._e()
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    filter.type == "search exact" ||
                    filter.type == "search greater than" ||
                    filter.type == "search lower than" ||
                    filter.type == "search or" ||
                    filter.type == "search and"
                      ? [
                          _c("input", {
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id:
                                "filter_" +
                                _vm.sectionId +
                                _vm.getColumnId(filter.field),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.filterData(
                                  $event,
                                  filter.field,
                                  filter.type
                                )
                              },
                            },
                          }),
                        ]
                      : _vm._e(),
                    filter.type == "slider"
                      ? [
                          filter["slider type"] == "single"
                            ? _c("div")
                            : _vm._e(),
                          filter["slider type"] == "dual"
                            ? _c("div", [
                                _c("div", { staticClass: "slide-container" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "sliders-control",
                                      attrs: {
                                        id:
                                          "filter_" +
                                          _vm.sectionId +
                                          _vm.getColumnId(filter.field),
                                      },
                                    },
                                    [
                                      _c("input", {
                                        staticClass: "slider from-slider",
                                        staticStyle: { padding: "0" },
                                        attrs: {
                                          type: "range",
                                          id:
                                            "filter_" +
                                            _vm.sectionId +
                                            _vm.getColumnId(filter.field) +
                                            "_from_slider",
                                          min: _vm.sliderRange[filter.field]
                                            .min,
                                          max: _vm.sliderRange[filter.field]
                                            .max,
                                          step: _vm.sliderRange[filter.field]
                                            .step,
                                        },
                                        domProps: {
                                          value:
                                            _vm.sliderRange[filter.field].from,
                                        },
                                        on: {
                                          input: function ($event) {
                                            _vm.setSliderTip(
                                              $event,
                                              "filter_" +
                                                _vm.sectionId +
                                                _vm.getColumnId(filter.field) +
                                                "_from"
                                            )
                                          },
                                          change: function ($event) {
                                            return _vm.filterDataSlider(
                                              $event,
                                              filter.field,
                                              "dual"
                                            )
                                          },
                                        },
                                      }),
                                      _c("input", {
                                        staticClass: "slider to-slider",
                                        staticStyle: { padding: "0" },
                                        attrs: {
                                          type: "range",
                                          id:
                                            "filter_" +
                                            _vm.sectionId +
                                            _vm.getColumnId(filter.field) +
                                            "_to_slider",
                                          min: _vm.sliderRange[filter.field]
                                            .min,
                                          max: _vm.sliderRange[filter.field]
                                            .max,
                                          step: _vm.sliderRange[filter.field]
                                            .step,
                                        },
                                        domProps: {
                                          value:
                                            _vm.sliderRange[filter.field].to,
                                        },
                                        on: {
                                          input: function ($event) {
                                            _vm.setSliderTip(
                                              $event,
                                              "filter_" +
                                                _vm.sectionId +
                                                _vm.getColumnId(filter.field) +
                                                "_to"
                                            )
                                          },
                                          change: function ($event) {
                                            return _vm.filterDataSlider(
                                              $event,
                                              filter.field,
                                              "dual"
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "output",
                                        {
                                          staticClass:
                                            "range-slider-tip range-from-value",
                                          attrs: {
                                            id:
                                              "filter_" +
                                              _vm.sectionId +
                                              _vm.getColumnId(filter.field) +
                                              "_from",
                                            name: "rangeFromValue",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              Math.round(
                                                _vm.sliderRange[filter.field]
                                                  .from * 10000
                                              ) / 10000
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "output",
                                        {
                                          staticClass:
                                            "range-slider-tip range-to-value",
                                          attrs: {
                                            id:
                                              "filter_" +
                                              _vm.sectionId +
                                              _vm.getColumnId(filter.field) +
                                              "_to",
                                            name: "rangeToValue",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              Math.round(
                                                _vm.sliderRange[filter.field]
                                                  .to * 10000
                                              ) / 10000
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    filter.type == "search change direction"
                      ? [
                          _c(
                            "select",
                            {
                              staticClass: "egl-filter-direction",
                              attrs: {
                                id:
                                  "filter_" +
                                  _vm.sectionId +
                                  _vm.getColumnId(filter.field) +
                                  "_direction",
                              },
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: { value: "lt", selected: "selected" },
                                },
                                [_vm._v(" <= ")]
                              ),
                              _c("option", { attrs: { value: "gt" } }, [
                                _vm._v(">="),
                              ]),
                            ]
                          ),
                          _c("input", {
                            staticClass: "form-control egl-filter-cd-input",
                            attrs: {
                              type: "text",
                              id:
                                "filter_" +
                                _vm.sectionId +
                                _vm.getColumnId(filter.field),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.filterData(
                                  $event,
                                  filter.field,
                                  filter.type
                                )
                              },
                            },
                          }),
                        ]
                      : filter.type == "dropdown"
                      ? [
                          _c(
                            "select",
                            {
                              staticClass: "custom-select",
                              attrs: {
                                id:
                                  "filter_" +
                                  _vm.sectionId +
                                  _vm.getColumnId(filter.field),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filterData(
                                    $event,
                                    filter.field,
                                    filter.type,
                                    filter.dataType
                                  )
                                },
                              },
                            },
                            [
                              _c("option"),
                              _vm._l(
                                _vm.buildOptions(filter.field),
                                function (value) {
                                  return _c(
                                    "option",
                                    { key: value, domProps: { value: value } },
                                    [_vm._v(" " + _vm._s(value) + " ")]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]
                      : filter.type == "sort"
                      ? [
                          _c(
                            "select",
                            {
                              staticClass: "custom-select",
                              attrs: {
                                id:
                                  "filter_" +
                                  _vm.sectionId +
                                  _vm.getColumnId(filter.id),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.callSort($event, filter.direction)
                                },
                              },
                            },
                            [
                              _c("option"),
                              _vm._l(filter.fields, function (value) {
                                return _c(
                                  "option",
                                  { key: value, domProps: { value: value } },
                                  [_vm._v(" " + _vm._s(value) + " ")]
                                )
                              }),
                            ],
                            2
                          ),
                        ]
                      : filter.type == "sort-radio"
                      ? [
                          _c(
                            "div",
                            { staticClass: "custom-select-radios" },
                            _vm._l(filter.fields, function (value) {
                              return _c(
                                "div",
                                {
                                  key: value,
                                  staticClass: "custom-select-radio",
                                },
                                [
                                  _c("input", {
                                    staticClass: "custom-radio",
                                    attrs: {
                                      type: "radio",
                                      id:
                                        "filter_" +
                                        _vm.sectionId +
                                        _vm.getColumnId(filter.id) +
                                        "_" +
                                        value,
                                      name: "filter",
                                    },
                                    domProps: { value: value },
                                    on: {
                                      change: function ($event) {
                                        return _vm.callSort(
                                          $event,
                                          filter.direction
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      attrs: {
                                        for:
                                          "filter_" +
                                          _vm.sectionId +
                                          _vm.getColumnId(filter.id) +
                                          "_" +
                                          value,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(value) + " ")]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      : filter.type == "checkbox"
                      ? [
                          _c("div", { staticClass: "chkbox-combo" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "title btn btn-sm btn-light form-control chk-box-btn",
                              },
                              [
                                _vm._v(_vm._s(filter.label) + " "),
                                _c("span", [_vm._v("▼")]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "options" },
                              [
                                _c("span", [
                                  _c("input", {
                                    staticClass: "chkbox",
                                    attrs: {
                                      type: "checkbox",
                                      id:
                                        "filter_" +
                                        _vm.sectionId +
                                        _vm.getColumnId(filter.field) +
                                        "all",
                                      checked: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.filterDataChkbox(
                                          $event,
                                          filter.field,
                                          filter.type,
                                          "all"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      attrs: {
                                        for:
                                          "filter_" +
                                          _vm.sectionId +
                                          _vm.getColumnId(filter.field) +
                                          "all",
                                      },
                                    },
                                    [_vm._v("Check / Uncheck all")]
                                  ),
                                ]),
                                _vm._l(
                                  _vm.buildOptions(filter.field, "chkbox"),
                                  function (value, vIndex) {
                                    return [
                                      !!value
                                        ? _c(
                                            "span",
                                            {
                                              key: value,
                                              class:
                                                filter.field.toLowerCase() ===
                                                "omics"
                                                  ? [value, "do-color"]
                                                  : "",
                                            },
                                            [
                                              _c("input", {
                                                staticClass: "chkbox",
                                                class: [
                                                  "filter-" +
                                                    _vm.sectionId +
                                                    _vm.getColumnId(
                                                      filter.field
                                                    ),
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  id:
                                                    "filter_" +
                                                    _vm.sectionId +
                                                    _vm.getColumnId(
                                                      filter.field
                                                    ) +
                                                    vIndex,
                                                  checked: "",
                                                },
                                                domProps: { value: value },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.filterDataChkbox(
                                                      $event,
                                                      filter.field,
                                                      filter.type,
                                                      vIndex
                                                    )
                                                  },
                                                },
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  attrs: {
                                                    for:
                                                      "filter_" +
                                                      _vm.sectionId +
                                                      _vm.getColumnId(
                                                        filter.field
                                                      ) +
                                                      vIndex,
                                                  },
                                                },
                                                [_vm._v(_vm._s(value))]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      this.dataset != null
        ? _c(
            "b-container",
            { staticClass: "search-fields-wrapper" },
            [
              _vm._l(this.filtersIndex, function (value, name, index) {
                return _c(
                  "div",
                  { key: name, class: "search-field f-" + index },
                  _vm._l(
                    value.search.filter((v, i, arr) => arr.indexOf(v) == i),
                    function (v, i) {
                      return value.type != "checkbox" && value.search.length > 0
                        ? _c("b-badge", {
                            key: v,
                            class: "btn search-bubble " + i,
                            attrs: { pill: "" },
                            domProps: {
                              innerHTML: _vm._s(
                                value["label in bubble"] == true
                                  ? value.field +
                                      ": " +
                                      v +
                                      "&nbsp;<span class='remove'>X</span>"
                                  : v + "&nbsp;<span class='remove'>X</span>"
                              ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.removeFilter(value.field, i)
                              },
                            },
                          })
                        : _vm._e()
                    }
                  ),
                  1
                )
              }),
              this.numberOfSearches() > 1
                ? _c(
                    "b-badge",
                    {
                      staticClass:
                        "badge badge-secondary badge-pill btn search-bubble clear-all-filters-bubble",
                      on: {
                        click: function ($event) {
                          return _vm.removeAllFilters()
                        },
                      },
                    },
                    [_vm._v(" Clear all search ")]
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }