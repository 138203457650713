var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "region-track-wrapper",
        attrs: { id: "region_track_wrapper" + _vm.sectionId },
      },
      [
        _c(
          "div",
          {
            staticClass: "block-data hidden",
            attrs: { id: "block_data_" + _vm.sectionId },
          },
          [
            _c(
              "div",
              {
                staticClass: "fixed-info-box-close",
                on: {
                  click: function ($event) {
                    _vm.infoBoxFrozen = false
                    _vm.hidePanel("block_data_" + _vm.sectionId)
                  },
                },
              },
              [_c("b-icon", { attrs: { icon: "x-circle-fill" } })],
              1
            ),
            _c("div", {
              staticClass: "block-data-content",
              attrs: { id: "block_data_content_" + _vm.sectionId },
            }),
          ]
        ),
        _c(
          "div",
          _vm._l(_vm.colorGroups, function (cKey, index) {
            return _c(
              "span",
              {
                key: cKey,
                staticClass: "color-groups",
                on: {
                  mouseover: function ($event) {
                    return _vm.renderPlot(cKey)
                  },
                  mouseleave: function ($event) {
                    return _vm.renderPlot()
                  },
                },
              },
              [
                _c("span", {
                  staticClass: "box",
                  style: "background-color:" + _vm.colors.bold[index % 16],
                }),
                _c("span", {
                  staticClass: "label",
                  domProps: { innerHTML: _vm._s(cKey) },
                }),
              ]
            )
          }),
          0
        ),
        !!_vm.plotConfig
          ? _c("canvas", {
              staticClass: "region-track",
              attrs: { id: "track_" + _vm.sectionId, width: "", height: "" },
              on: {
                mouseleave: function ($event) {
                  return _vm.hidePanel("block_data_" + _vm.sectionId)
                },
                mousemove: function ($event) {
                  return _vm.checkPosition($event, "hover")
                },
                click: function ($event) {
                  return _vm.checkPosition($event, "click")
                },
                resize: _vm.onResize,
              },
            })
          : _vm._e(),
        _c("div", { staticClass: "download-images-setting" }, [
          _c(
            "span",
            { staticClass: "btn btn-default options-gear" },
            [
              _vm._v("Download "),
              _c("b-icon", { attrs: { icon: "download" } }),
            ],
            1
          ),
          _c("ul", { staticClass: "options" }, [
            _c("li", [
              _c(
                "a",
                {
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadImage(
                        "vector_wrapper_" + _vm.sectionId,
                        _vm.sectionId + "_regionDots",
                        "svg"
                      )
                    },
                  },
                },
                [_vm._v("Download SVG")]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadImage(
                        "track_" + _vm.sectionId,
                        _vm.sectionId + "_regionDots",
                        "png"
                      )
                    },
                  },
                },
                [_vm._v("Download PNG")]
              ),
            ]),
          ]),
        ]),
        !!_vm.plotData
          ? _c("research-region-dots-vector", {
              ref: _vm.sectionId + "_regionDots",
              attrs: {
                renderData: _vm.renderData,
                renderConfig: _vm.plotConfig,
                colorGroups: _vm.colorGroups,
                colors: _vm.colors,
                margin: _vm.adjPlotMargin,
                region: _vm.viewingRegion,
                sectionId: _vm.sectionId,
                utils: _vm.utils,
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }